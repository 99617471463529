/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { UserContext } from '../../../../App'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Rating } from '@mui/material'
// import { IoLocationSharp } from "react-icons/io5";
// import { FaPhone } from "react-icons/fa6";
import { MdOutlineEmail, MdOutlineLocalPhone, MdOutlineMessage } from "react-icons/md";
// import { FaRegMessage } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { IoCallSharp } from "react-icons/io5";
// import freeMonth from './../../../TeamAdmin/MapPage/Images/payItForward.png'
import QRCode from 'qrcode.react'
import Swal from 'sweetalert2'
import ContactButtons from '../../../../Services/ContactButtons'
import { useNavigate, useParams } from 'react-router-dom';
// import ReviewContainer from './ReviewContainer';
import { GiWireframeGlobe } from 'react-icons/gi';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import SearchFiberPlanReq from '../../../../Requests/FiberPlan/SearchFiberPlansReq';
import FiberPlanApi from '../../../../API/FiberPlanApi';
import LightCustomerModal from './LightCustomerModal';
import FeedbackForm from '../../../Customer/AddFeedback/FeedbackForm';
import { SwiperSlide, Swiper } from 'swiper/react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { LuFileVideo } from 'react-icons/lu';
import StringIntReq from '../../../../Requests/StringIntReq';
import UsersApi from '../../../../API/UsersApi';
import { v4 as uuidv4 } from 'uuid';
import { uploadFileToFirebase } from '../../../../Services/DisplayFile';
import ReviewContainer from './ReviewContainer';
import CustomerFeedbackApi from '../../../../API/CustomerFeedbackApi';
import SearchCustomerFeedbackReq from '../../../../Requests/CustomerFeedback/SearchCustomerFeedbackReq';
import FiberCompanySelector from '../../../../components/FiberCompanySelector';
const MobileVersionBusinessCard = ({ salesRepIsUser, salesRep, fiberCompany}) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const { id } = useParams();
    // const [screenHeight, setScreenHeight] = useState(window.innerHeight)
    const [avgRating, setAvgRating] = useState(5);
    const [openReviewModal, setOpenReviewModal] = useState(false);
    const [reviewCount, setReviewCount] = useState(0);
    const user = useContext(UserContext)
    const contactButtons = new ContactButtons(user)
    const [selectedPlan, setSelectedPlan] = useState({})
    const [openLightModal, setOpenLightModal] = useState(false)
    const [plans, setPlans] = useState([])
    const [userVideos, setUserVideos] = useState([])
    const navigate = useNavigate();
    const videoRef = useRef(null);
    const [openFeedbackForm, setOpenFeedbackForm] = useState(false)
    const [fiberCompanyId, setFiberCompanyId] = useState(fiberCompany ? fiberCompany : 2);
    const [bulletPoints, setBulletPoints] = useState(null)

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load()
        }
    }, [videoRef.current])


    useEffect(() => {
        console.log(openReviewModal)
    }, [openReviewModal])

    useEffect(() => {
        console.log(fiberCompanyId)
        window.addEventListener('resize', () => {
            setScreenWidth(window.innerWidth)
            // setScreenHeight(window.innerHeight)
        })

        return () => {
            window.removeEventListener('resize', () => {
                setScreenWidth(window.innerWidth)
                // setScreenHeight(window.innerHeight)
            })
        }
    }, [])

    const handleOpenLightModal = () => {
        if(fiberCompanyId === null){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a Fiber Company',
                showCloseButton: true,
            })
            return
        }
        setOpenLightModal(true)
    }

    var plansdata =
        {
        fiberPlans: [
            {
                id: 1,
                speed: 500,
                price: 100,
            },
            {
                id: 2,
                speed: 1,
                price: 200
            },
        ],
        bulletPoints: "Point1\nPoint2\nPoint3" 
        }   
        
    
        const getPlans = async () => {
            const req = new StringIntReq();
            req.int = fiberCompanyId;
            const res = await FiberPlanApi.GetFiberPlanOffers(req);
            
            if (res.status.success) {


                const fiberPlans = res.data.fiberPlans;
                const bulletPoints = res.data.bulletPoints;
        

                setPlans(fiberPlans);
                setSelectedPlan(fiberPlans[0]);
                setBulletPoints(bulletPoints);
            }
        };

    const getAvgRating = async () => {
        const req = new SearchCustomerFeedbackReq();
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 1000;
        req.feedbackType = null;
        const res = await CustomerFeedbackApi.SearchCustomerFeedbacks(req);
        if (res?.status?.success) {
            let totalRating = 0;
            res.data.list.forEach(review => {
                totalRating += review.rating;
            });
            setAvgRating(totalRating / res.data.totalItems);
            setReviewCount(res.data.totalItems);
        }
    };

    useEffect(() => {
        getAvgRating();
    }, [])
    useEffect(() => {
        if (fiberCompany) {
            setFiberCompanyId(fiberCompany);
        } else
            setFiberCompanyId(2);

    }, [fiberCompany]);
    

    useEffect(() => {
        if(fiberCompanyId != null)
        {
            getPlans()
            console.log(fiberCompanyId)
        }
    }, [fiberCompanyId])


    const copyUrlToClipboard = () => {
        if (salesRepIsUser) {
            if (salesRepIsUser) {
                navigator.clipboard.writeText(window.location.origin + "/digital-business-center/" + user.id)
            } else {
                navigator.clipboard.writeText(window.location.origin + "/digital-business-center/" + salesRep.id)
            }
            Swal.fire({
                title: "Link Copied",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                position: "bottom-right",
                timerProgressBar: true,
            })
        }
    }

    const buttonClicked = async (text) => {
        if (text === "email") {
            contactButtons.email(salesRep.email)
        } else if (text === "message") {
            contactButtons.sms(salesRep.phoneNumber)
        } else if (text === "Wa") {
            contactButtons.whatsapp(salesRep.phoneNumber)
        } else if (text === "Call") {
            contactButtons.call(salesRep.phoneNumber)
        }
    }

    function getQrSize() {
        if (screenWidth > 1400) {
            return 300
        } else if (screenWidth >= 1000) {
            return 250
        } else if (screenWidth >= 490) {
            return 180
        } else {
            return 200
        }
    }

    const getAddress = () => {
        const { city, state, country } = salesRep;
        let addressParts = [];


        if (city) addressParts.push(city);
        if (state) addressParts.push(state);
        if (country) addressParts.push(country);


        return addressParts.join(', ');
    };

    function getPlanSpeed(plan) {
        if (plan.speed < 1) {
            return plan.speed * 1000 + " Mbps"
        } else {
            return plan.speed + " Gig"
        }
    }

    const uploadFile = async (e) => {
        Swal.fire({
            title: "Uploading Video",
            icon: "info",
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        const uniqueId = uuidv4()
        const cloudFile = await uploadFileToFirebase(e.target.files[0], `profileVideos/${user.id}/${uniqueId}`)
        cloudFile.videoUserId = user.id
        const res = await UsersApi.AddUserVideoForBizCard(cloudFile)
        if (res?.status?.success) {
            Swal.fire({
                title: "Video Uploaded",
                icon: "success",
                showConfirmButton: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            searchUserVideos();
        }
        else {
            Swal.fire({
                title: "Error Uploading Video",
                icon: "error",
                showConfirmButton: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
    }

    const searchUserVideos = async () => {
        const req = new StringIntReq()
        req.int = salesRep.id
        const res = await UsersApi.SearchUserVideosForBizCard(req)
        if (res.status.success) {
            setUserVideos(res.data)
        }
        else {
            console.log(res.status.message)
        }
    }

    useEffect(() => {
        searchUserVideos()
    }, [])

    return (
        <>
            <LightCustomerModal lightOpenModal={openLightModal} setLightOpenModal={setOpenLightModal} salesRepId={salesRep?.id} fiberCompanyId={fiberCompanyId} fiberPLanId={selectedPlan?.id} salesOrgId={salesRep?.salesOrgId} />
            <Modal open={openReviewModal} setOpen={setOpenReviewModal} onClose={() => setOpenReviewModal(false)}>
                <div className='review_mobile_modal'>
                <button className="close-button" onClick={() => setOpenReviewModal(false)}>✖</button>
                    <ReviewContainer writtenOnly={true} />
                </div>
            </Modal>
            <div className='header'>
                <div className='info'>
                    <h3>{salesRep?.firstName + " " + salesRep?.lastName}</h3>
                    <p>{salesRep.userType === "SalesRep" ? "Fiber Specialist" :
                        salesRep.userType === "SalesOrgAdmin" ? "Area Manager" :
                            salesRep.userType === "SuperAdmin" ? "Super Admin" : ""
                    }</p>
                    <div className="rating-section">
                        {/* Rating and Average Rating in one row */}
                        <div className="rating-row">
                            <Rating name="read-only" size='medium' precision={0.5} value={avgRating} readOnly />
                            <span className="avg-rating">
                                {avgRating.toFixed(1)}
                            </span>
                        </div>
                        {/* Reviews count below the rating */}
                        {/* <span className="reviews-count" onClick={() => setOpenReviewModal(true)}>
                            {reviewCount} reviews
                        </span> */}
                    </div>
                    <div className='logo'>
                        <img src='/images/card/nav-logo.png' alt='logo' />
                    </div>

                </div>
                <div style={{
                    border: "1px solid black",
                }} className='image'>
                    <img src={salesRep?.profileImage ? salesRep.profileImage.url : '/images/Avatar/profile.jpg'} style={{
                        objectFit: salesRep?.profileImage ? 'cover' : 'cover'
                    }} alt="Profile" />
                </div>
            </div>

            {
                (salesRep?.profileVideo || userVideos?.length > 0 || user.id === salesRep.id) &&
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={{ clickable: true }}
                    navigation={true}
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className='video'
                    style={{
                        width: "100%",
                    }}
                >
                    {
                        salesRep?.profileVideo &&
                        <SwiperSlide>
                            <video ref={videoRef} controls src={salesRep?.profileVideo ? salesRep.profileVideo.url : "https://placehold.co/1920x1080.mp4"} alt="video" />
                        </SwiperSlide>
                    }
                    {
                        userVideos.map((video, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <video controls src={video.url} alt="video" />
                                </SwiperSlide>
                            )
                        })
                    }
                    {
                        user.id === salesRep.id &&
                        <SwiperSlide>
                            <div style={{
                                backgroundColor: "white"
                            }} className='add_video_bizcard'>
                                <label htmlFor='biz_card_video'>UPLOAD <LuFileVideo size={30} color='#4FAFCB' /></label>
                                <input onChange={(event) => {
                                    uploadFile(event)
                                }} type="file" id='biz_card_video' />
                            </div>
                        </SwiperSlide>
                    }
                </Swiper>
            }
            <div className='infoAndQrContainer'>
                <div className='user-contact'>
                    <div className='contact-item'>
                        <GiWireframeGlobe color='black' size={screenWidth > 1400 ? 50 : screenWidth > 1000 ? 40 : 30} />
                        <p>{getAddress()}</p>
                    </div>
                    <div style={{
                        cursor: 'pointer'
                    }} onClick={() => { contactButtons.call(salesRep?.phoneNumber) }} className='contact-item'>
                        <MdOutlineLocalPhone color='black' size={screenWidth > 1400 ? 50 : screenWidth > 1000 ? 40 : 30} />
                        <p>{salesRep?.phoneNumber}</p>
                    </div>
                    <div style={{
                        cursor: 'pointer'
                    }} onClick={() => { contactButtons.email(salesRep?.email) }} className='contact-item'>
                        <MdOutlineEmail color='black' size={screenWidth > 1400 ? 50 : screenWidth > 1000 ? 40 : 30} />
                        <p>{salesRep?.email}</p>
                    </div>
                </div>


            </div>
            <div className='offerAndQr'>
                {(user?.userType !== "SalesRep" && user?.userType !== "SuperAdmin" && user?.userType !== "SalesOrgAdmin" && fiberCompanyId != null)  &&
                
                <div className='offer_con'>


                            {!fiberCompany &&
                                <div style={{zIndex: 1000, width: "100%"}}>

                            <FiberCompanySelector className="fiber_company_selector" placeholder='Select Fiber Company' value={{id: 2, label: "LightCurve"}}  setUser={setFiberCompanyId} />
                                </div>
                                }
                            <h1 style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold"
                            }}>Limited Time Offer</h1>
                            {plans.length > 0  ? 
                            <div className='banner_top'>
                                {
                                   plans?.map((plan, index) => {
                                            return (
                                                <div key={index} onClick={() => {
                                                    setSelectedPlan(plan)
                                                }} className={`banner_card ${selectedPlan?.id === plan?.id && "active"}`}>
                                                    {selectedPlan?.id === plan?.id && <IoIosCheckmarkCircle style={{
                                                        position: 'absolute',
                                                        top: '-5%',
                                                        right: '-5%'
                                                    }} size={40} color='#2761D0' />}
                                                    <img style={{
                                                        height: "30px",
                                                        marginBottom: "0.2rem"
                                                    }} src={'data:image/png;base64,' + plan.fiberCompany?.logo} alt='fiber company logo' />
                                                    <h2 style={{
                                                        fontSize: "1.2rem",
                                                        marginBottom: "0.5rem"

                                                    }}>
                                                        {
                                                            // plan.speed < 1 ?
                                                            // plan.speed * 1000 + " Mbps"
                                                            getPlanSpeed(plan)
                                                            // :
                                                            // plan.speed + " Gig"
                                                        }
                                                    </h2>
                                                    {/* {
                                                // plan.name === "HFC" && <p>Hybrid Fiber Coaxial</p>
                                                plan?.name?.includes("HFC") && <p>Hybrid Fiber Coaxial</p>
                                            } */}
                                                    <h4>${plan.price}/mo*</h4>
                                                    {/* <p style={{
                                                        textAlign: "left",
                                                        fontSize: "0.8rem",
                                                    }}>No Data Caps</p>
                                                    <p style={{
                                                        textAlign: "left",
                                                        fontSize: "0.8rem",
                                                    }}>1st Month Free</p>
                                                    <p style={{
                                                        textAlign: "left",
                                                        fontSize: "0.8rem",
                                                    }}>No Contracts</p>
                                                    <p style={{
                                                        fontSize: "0.8rem",
                                                        textAlign: "left",
                                                    }}>Free Modem</p> */}
                                                    {/* <p>Lifetime Rate Lock</p> */}
                                                </div>
                                            )
                                    })
                                }

                            </div>
                            : "No Plans Available"}
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.5rem",
                                backgroundColor: "#fff",
                                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                                padding: "1rem",
                                borderRadius: "10px"
                            }}>
                                {bulletPoints?.split('\n').map((point, index) => {
                                    return (
                                        <div key={index} className='flex gap-4'>
                                            <IoIosCheckmarkCircle color='#2761D0' size={20} />
                                            <p>{point}</p>
                                        </div>
                                    );
                                })}
                            </div>

                            <button style={{
                                backgroundColor: "#2761D0",
                                color: "white",
                                padding: "0.5rem 1rem",
                                borderRadius: "10px",
                            }}
                            disabled={plans.length === 0} 
                            onClick={() => {
                                if (user?.id !== salesRep?.id) {
                                    handleOpenLightModal()
                                }
                            }} >Accept Offer</button>
                    
                </div>
                }
                {<div className='payitForwardContainer'>
                    <div>
                        <h3>Connect/Share</h3>
                        <QRCode value={window.location.origin + "/digital-business-center/" + salesRep.id} size={getQrSize()} />
                        <p style={{
                            cursor: 'pointer'
                        }} onClick={() => { copyUrlToClipboard() }}>Copy Link</p>
                    </div>
                </div>
                }
            </div>

            <div style={{
                width:"80vw",
                margin:"auto"
            }}>
                <ReviewContainer salesRepId={salesRep.id} />
            </div>

            <div className='contact_buttons'>
                <div onClick={() => { buttonClicked('email') }} className="contact_button">
                    <MdOutlineEmail color='#84848c' className='icon' />
                    <p>Email</p>
                </div>
                <div onClick={() => { buttonClicked('message') }} className="contact_button">
                    <MdOutlineMessage color='#84848c' className='icon' />
                    <p>SMS</p>
                </div>
                <div onClick={() => { buttonClicked('Wa') }} className="contact_button">
                    <FaWhatsapp color='#84848c' className='icon' />
                    <p>WA</p>
                </div>
                <div onClick={() => { buttonClicked('Call') }} className="contact_button">
                    <IoCallSharp color='#84848c' className='icon' />
                    <p>Call</p>
                </div>
            </div>
            {salesRepIsUser && <>
                <button onClick={() => { navigate('/edit-account-details') }} className="edit_card_details_button">Edit Card Details</button>
            </>}
            {!salesRepIsUser && <>
                <button onClick={() => setOpenFeedbackForm(true)} className="edit_card_details_button">Give A Review</button>
            </>}
            <Modal open={openFeedbackForm} onClose={() => setOpenFeedbackForm(false)}>
                <div className='review_mobile_modal'>
                    <FeedbackForm feedbackType="written" setOpen={setOpenFeedbackForm} isReview={true} salesRepId={id ? id : null}/>
                </div>

            </Modal>

                   


        </>
    )

}

export default MobileVersionBusinessCard
