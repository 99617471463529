/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { MapContext as MP } from '..'
import { DrawButton, MapContext } from '@terrestris/react-geo'
import { useState } from 'react'
import { toLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector';
import { MdOutlineDraw } from "react-icons/md";
import { IoCheckmark, IoClose } from "react-icons/io5";
import './SelectArea.scss'
import MapHelper from '../MapHelper'
import Swal from 'sweetalert2'
// import WKTReader from 'jsts/org/locationtech/jts/io/WKTReader'
// import OL3Parser from 'jsts/org/locationtech/jts/io/OL3Parser'
// import jsts from 'jsts'
// import Union from 'jsts/org/locationtech/jts/operation/union/OverlapUnion'
const SelectArea = ({ screenWidth, active, setActive }) => {

    const { setFiberHouses, setSearchAreaFiberHouses, setUsingLight, mapObject, setSearchAreaFeature, searchAreaFeature, setSearchArea, searchArea, setIndex, fiberHouses, setFiberHouseIds, fiberHouseIds } = useContext(MP)

    const helper = new MapHelper(mapObject)
    const [featureTodelete, setFeatureToDelete] = useState(null)


    // function mergeTwoPolygons (polygon1, polygon2) {

    //     let ol3Parser = new OL3Parser();
    //     ol3Parser.inject(Point, LineString, LinearRing,Polygon, MultiPoint, MultiLineString, MultiPolygon, GeometryCollection);
    //     let jstsPolygon1 = ol3Parser.read(polygon1.getGeometry());
    //     let jstsPolygon2 = ol3Parser.read(polygon2.getGeometry());
    //     console.log( jstsPolygon1)
    //     console.log( jstsPolygon2)
    //     let unionR = new Union(jstsPolygon1, jstsPolygon2)
    //     let unionResult = unionR.union();
    //     console.log(unionResult)
    //     let merged_polygon = new Feature({    
    //         geometry: new Polygon(ol3Parser.write(unionResult).getCoordinates())
    //     });      

    //     return merged_polygon;
    // }

    const onDrawEnd = (e) => {
        console.log("draw end")
        const feature = e.feature;
        const geometry = feature.getGeometry();
        const coordinates = geometry.getCoordinates();
        setSearchAreaFeature([feature])

        setSearchArea(coordinates[0].map(coord => {
            const [lng, lat] = toLonLat(coord)
            return { latitude: lat, longitude: lng }
        }
        ))
        setActive(false)
    }

    // const onDrawEnd2 = (e) => {
    //     const newFeature = e.feature;
    //     console.log(newFeature)
    //     const geometry = newFeature.getGeometry();
    //     const coordinates = geometry.getCoordinates();
    //     const newFeatureId = newFeature.id_; // If you're using OpenLayers' default ID property, though it's not recommended to rely on internal properties
    //     let intersect = false;

    //     for (let i = 0; i < searchAreaFeature.length; i++) {
    //         const searchAreaFeatureExtent = searchAreaFeature[i].getGeometry().getExtent();
    //         const newFeatureExtent = newFeature.getGeometry().getExtent();
    //         intersect = intersects(searchAreaFeatureExtent, newFeatureExtent)
    //         if (intersect) {
    //             break;
    //         }
    //     }
    //     if (intersect) {

    //         let mergedPolygon = null;
    //         // if(searchAreaFeature.length>1){
    //         //     mergedPolygon = mergeTwoPolygons(searchAreaFeature[0], searchAreaFeature[1]);
    //         //     for (let i = 2; i < searchAreaFeature.length; i++) {
    //         //         mergedPolygon = mergeTwoPolygons(mergedPolygon, searchAreaFeature[i]);
    //         //     }
    //         //     mergedPolygon = mergeTwoPolygons(mergedPolygon, newFeature);
    //         // }
    //         // else{
    //         //     mergedPolygon = mergeTwoPolygons(searchAreaFeature[0], newFeature);
    //         // }

    //         setSearchAreaFeature(
    //             (prev) => [...prev, newFeature]
    //         )

    //         // setSearchArea(
    //         //     (prev) => mergedPolygon.getGeometry().getCoordinates()[0].map(coord => {
    //         //         const [lng, lat] = toLonLat(coord)
    //         //         return { latitude: lat, longitude: lng }
    //         //     }
    //         // )
    //         // )
    //         setSearchArea(
    //             (prev) => [...prev, ...coordinates[0].map(coord => {
    //                 const [lng, lat] = toLonLat(coord)
    //                 return { latitude: lat, longitude: lng }
    //             }
    //             )]
    //         )
    //     }
    //     else {
    //         // mapObject.getLayers().getArray().forEach(layer => {
    //         //     const source = layer.getSource();
    //         //     if (source instanceof VectorSource) {
    //         //         source.forEachFeature(function (feature) {
    //         //             // Compare feature IDs instead of geometry objects
    //         //             if (feature.id_ === newFeatureId) {
    //         //                 source.removeFeature(feature);
    //         //             }
    //         //         });
    //         //     }
    //         // })
    //         setFeatureToDelete(newFeature)
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Please draw connected areas',

    //         })
    //         // remove the last feature from the searchAreaFeature and map



    //     }
    // }


    useEffect(() => {
        if (featureTodelete) {
            mapObject.getLayers().getArray().forEach(layer => {
                const source = layer.getSource();
                if (source instanceof VectorSource) {
                    source.forEachFeature(function (feature) {
                        // Compare feature IDs instead of geometry objects
                        if (feature === featureTodelete) {
                            source.removeFeature(feature);
                        }
                    });
                }
            })
            setFeatureToDelete(null)
        }
    }, [featureTodelete])

    useEffect(() => {
        if (searchArea && searchArea.length > 0 && screenWidth < 1000) {
            handleClick(0)
        }
    }, [searchArea])



    const handleClick = async (index) => {
        const list = [];
        fiberHouses.forEach(fiberHouse => {
            if (helper.checkIfFiberHouseIsInsideServicableArea(fiberHouse, searchArea)) {
                list.push(fiberHouse);
        
            }
        
        });
        if (list.length > 5000 && screenWidth<1000) {
            Swal.fire({
                icon: 'error',
                title: 'Too Many Leads',
                text: 'Please mark less than 5,000 Leads, or use your PC to mark more',
                customClass: {
                    container: 'custom-swal'
                },
            })
            deletePolygon()
            return
        }

        if(screenWidth>1000 && list.length > 20000)
        {
            Swal.fire({
                icon: 'error',
                title: 'Too Many Leads',
                text: 'Please mark less than 20,000 Leads',
                customClass: {
                    container: 'custom-swal'
                },
            })
            deletePolygon()
            return
        }
        

        if (list.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'No Leads Found',
                text: 'Please mark another area',
                customClass: {
                    container: 'custom-swal'
                },
            })
            deletePolygon()
            return
        }

        console.log(list)
        setSearchAreaFiberHouses(list);
        setFiberHouseIds(list.map(fiberHouse => fiberHouse.id))

    }

    useEffect(() => {
        if (searchAreaFeature.length > 0) {
            setIndex(8)
        }
    }, [fiberHouseIds])
    const deletePolygon = () => {
        if (searchAreaFeature.length > 0) {


            searchAreaFeature.forEach(function (feature) {
                var selectedGeometry = feature.getGeometry(); // Assuming only one feature is selected
                mapObject.getLayers().getArray().forEach(layer => {
                    const source = layer.getSource()
                    if (source instanceof VectorSource)
                        source.forEachFeature(function (feature) {
                            var featureGeometry = feature.getGeometry();
                            if (featureGeometry === selectedGeometry) {
                                layer.getSource().removeFeature(feature);
                                setSearchAreaFeature([])
                                setSearchArea(null)
                                setSearchAreaFiberHouses([])
                                setFiberHouseIds([])
                            }
                        });
                });
            })

            setUsingLight(true);
            setIndex(-1);
            // setFiberHouses(lightHouses.current)
            setFiberHouses(
                (prev) => {
                    return prev.map(fiberHouse => {
                        return { ...fiberHouse, assigned: null,selected:false,remove:false }
                    })
                }

            )
        }
        else {


        }
    }

    // useEffect(() => {
    //     if(!searchAreaFeature){
    //         searchFiberHouses()
    //     }
    // }, [searchArea]) 

    useEffect(() => {
        if (searchAreaFeature?.length > 0) {
            // Get the map object
            const map = mapObject;

            let featureLayer;
            map.getLayers().forEach(layer => {
                const source = layer.getSource();
                if (source instanceof VectorSource) {
                    const features = source.getFeatures();
                    searchAreaFeature.forEach(feature => {
                        if (features.includes(feature)) {
                            featureLayer = layer;
                        }
                    })
                }
            });

            // Set the zIndex of the layer
            if (featureLayer) {
                featureLayer.setZIndex(1000);
            }
        }
    }, [searchAreaFeature]);

    return (
        <>
            <MapContext.Provider value={mapObject}>

                {!searchAreaFeature.length > 0 ? <DrawButton
                    className={` ${active ? "bg-[#4DD4FF]" : "bg-[#fff]"} `}
                    name="drawPolygon"
                    drawType="Polygon"
                    drawInteractionConfig={{
                        freehand: screenWidth > 1000 ? false : true,
                        geometryName:"stat",
                        
                    }}
                    
                    zIndex = {1000}
                    size='small'
                    onToggle={(pressed) => {
                        if (active !== pressed) {
                            console.log("toggle", pressed)
                            setActive(pressed)
                            // setFiberHouseIds([])
                        }
                    }}
                    // onClick={
                    //     () => {
                    //         setActive((prev) => !prev)
                    //     }
                    // }
                    // onClick={() => { 
                    onDrawEnd={onDrawEnd}
                    style={{
                        borderRadius: '50%',
                        padding: "0.5rem",
                        marginTop: "1em",
                        color: "white",
                        boxShadow: '0px 0px 10px 0px #2B92D5'
                    }}
                >
                    <MdOutlineDraw color={active ? `#fff` : `#181E4B`} size={screenWidth > 1000 ? 30 : 20} />
                </DrawButton>
                    :
                    <>
                        {/* <DrawButton
                            className={` ${active ? "bg-[#4DD4FF]" : "bg-[#fff]"} `}
                            name="drawPolygon"
                            drawType="Polygon"
                            drawInteractionConfig={{
                                freehand: screenWidth>1000?false:true,
                            }}
                            size='small'
                            onToggle={(pressed) => {
                                console.log("toggle")
                                setActive(pressed)
                            }}
                            // onClick={() => { 
                            onDrawEnd={onDrawEnd2}
                            style={{
                                borderRadius: '50%',
                                padding: "0.5rem",
                                marginTop: "1em",
                                color: "white",
                                boxShadow: '0px 0px 10px 0px #2B92D5'
                            }}
                        >
                            <MdOutlineDraw color={active ? `#fff` : `#181E4B`} size={screenWidth > 1000 ? 30 : 20} />
                        </DrawButton> */}
                        {
                            screenWidth > 1000 &&
                            <button style={{
                                borderRadius: '50%',
                                padding: "0.5rem"
                            }} onClick={() => {
                                handleClick(0)
                            }} className='TrackLocationButton'>
                                <IoCheckmark color='#181E4B' size={screenWidth > 1000 ? 30 : 20} />
                            </button>
                        }
                        <button style={{
                            borderRadius: '50%',
                            padding: "0.5rem"
                        }} onClick={() => deletePolygon()} className='TrackLocationButton'>
                            <IoClose color='#181E4B' size={screenWidth > 1000 ? 30 : 20} />
                        </button>

                    </>
                }
            </MapContext.Provider>
        </>
    )
}

export default SelectArea