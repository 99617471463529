/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useContext, useEffect, useState } from "react";
import { Menu, MenuItem, Sidebar as ProSidebar, menuClasses } from "react-pro-sidebar";

import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { IoIosNotifications, IoIosWarning } from "react-icons/io";
import SettingsIcon from '@mui/icons-material/Settings';
import { getAuth } from "firebase/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UsersApi from "../API/UsersApi";
import { ConnectionContext, MenuItemsContext, NotificationsContext, UserContext } from "../App";
import Breadcrumbs from "./examples/Breadcrumbs";

import anonUser from "../assets/images/userGrey.png";
import StringIntReq from "../Requests/StringIntReq";
import { MdCancel, MdCelebration, MdSchedule } from "react-icons/md";
import { BiHide } from "react-icons/bi";
import { ClickAwayListener } from "@mui/material";
import BulkMarkNotificationsAsSeenReq from "../Requests/Notifications/BulkMarkNotificationsAsSeenReq";


const Item = ({ title, to, icon, selected, setSelected, isLast, active }) => {
  return (
    <MenuItem
      rootStyles={{
        ['.' + menuClasses.button]: {
          color: '#71839B',
          fontSize: '0.9rem',
          '&:hover': {
            backgroundColor: 'var(--color-icon-hover) !important',
          },
          '&:active': {
            backgroundColor: 'var(--color-icon-hover) !important',
          },
        },
      }}
      active={active}
      component={<Link to={to} />}
      icon={icon(active ? 'white' : '#71839B')}
      onClick={() => setSelected(title)}
    >
      <div style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
        {title}
      </div>

    </MenuItem>
  );
};

const Sidebar = ({ children }) => {
  const menuItems = useContext(MenuItemsContext);
  const connectionService = useContext(ConnectionContext);
  const { notifications, setNotifications } = useContext(NotificationsContext)
  const user = useContext(UserContext);
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const savedState = localStorage.getItem('sidebar-collapsed');
    return savedState === null ? true : JSON.parse(savedState);
  });
  const [selected, setSelected] = useState("Dashboard");
  const [toggled, setToggled] = useState(false);
  const [breakPoint, setBreakPoint] = useState(false);
  const navigate = useNavigate();
  const route = useLocation().pathname.split("/").slice(1);
  // const [user, setUser] = useState(null);
  const activeMenuItem = menuItems.find((item, index) => item.route === `/${route[0]}`);
  const [notificationPanel, setNotificationPanel] = useState(false);
  // const [notifications, setNotifications] = useState([]);

  const handleArrowClick = () => {
    const newCollapsedState = !isCollapsed;
    setIsCollapsed(newCollapsedState);
    localStorage.setItem('sidebar-collapsed', JSON.stringify(newCollapsedState));
    if (breakPoint) {
      setToggled(!toggled);
      setIsCollapsed(false);
    }
  };

  // const searchNotifications = async () => {
  //   const req = new StringIntReq();
  //   req.int = user.id;
  //   const res = await UsersApi.SearchNotifications(req);
  //   if (res?.status?.success) {
  //     setNotifications(res.data.filter(notification => !notification.seen));
  //   }
  // }


  //   useEffect(() => {
  //     if (user && connectionService) {
  //         connectionService.newNotification = (notify) => {
  //             // searchNotifications();
  //             connectionService.newNotification = (notify) => {
  //               // searchNotifications();
  //               setNotifications((prev) => [notify,...prev]);
  //           }
  //         }
  //     }
  // }, [user,connectionService]);

  const markNotificationAsSeen = async (notificationId) => {
    const req = new StringIntReq();
    setNotifications(
      (prev) => prev.filter((notification) => notification.id !== notificationId)
    )
    req.int = notificationId;
    const res = await UsersApi.MarkNotificationAsSeen(req);
    if (res?.status?.success) {
      // searchNotifications();
      console.log("Notification marked as seen");
    }
  }

  const bulkMarkNotificationsAsSeen = async () => {
    const req = new BulkMarkNotificationsAsSeenReq();
    req.notificationIds = notifications.filter(notification => !notification.seen).map(notification => notification.id);
    setNotifications(
      (prev) => prev.map((notification) => {
        if (req.notificationIds.includes(notification.id)) {
          return { ...notification, seen: true }
        }
        return notification;
      })
    )
    const res = await UsersApi.BulkMarkNotificationsAsSeen(req);
    if (res?.status?.success) {
      // searchNotifications();
      console.log("All notifications marked as seen");

    }
  }




  // useEffect(() => {
  //   async function getUser() {
  //     const res = await UsersApi.GetCurrentUser();
  //     if (res?.status?.success) setUser(res.data);
  //   }
  //   getUser();
  // }, []);

  // useEffect(() => {
  //   if (user && connectionService) {
  //     connectionService.newNotification = (notify) => {
  //       // searchNotifications();
  //       setNotifications([notify, ...notifications]);
  //     }
  //     searchNotifications();
  //   }
  // }, [user,connectionService]);


  const openMap = async (notification) => {
    if (notification.fiberHouse) {
      navigate('/map', {
        state: { fiberHouse: notification.fiberHouse }
      })
    }

  }

  return (
    <>
      <div style={{ borderColor: "var(--color-icon)", height: "56px" }} className="flex justify-between items-center pr-8 border-b-4 NabvarDiv">
        <div className="w-16 h-16" onClick={() => { navigate('/') }} style={{ cursor: "pointer" }}>
          <img className="w-full h-full" alt="Logo" src={document.body.classList.contains('dark') ? "/images/nav/dark-logo.png" : "/images/nav/logo.png"} />
        </div>
        {menuItems.length > 0 ? (

          <div className="flex gap-4 items-center">
            <div className="iconButton notification_icon">
              <IoIosNotifications onClick={() => {
                setNotificationPanel(!notificationPanel);
                if (notificationPanel) {
                  bulkMarkNotificationsAsSeen();
                }
              }} style={{ color: "var(--color-icon)" }} fontSize="35" />
              {
                notifications.filter(n => !n.seen).length > 0 &&
                <p className="number">{notifications.filter(n => !n.seen).length<=9?notifications.filter(n => !n.seen).length:'9+'}</p>
              }
              {
                notificationPanel &&
                <ClickAwayListener onClickAway={() => setNotificationPanel(false)}>
                  <div className="notification_panel">
                    <div className="notification_panel_inner">
                      {
                        notifications?.length > 0 ?
                          notifications.map((notification, index) => {
                            return (
                              <div key={index} className="notification">
                                {
                                  (notification.type === "CustomerCreated" || notification.type === "OfferAccepted") &&
                                  <MdCelebration style={{ color: "gold" }} size={35} />
                                }
                                {
                                  notification.type === "CancelPending" &&
                                  <IoIosWarning style={{ color: "red" }} size={35} />
                                }
                                {
                                  notification.type === "CancelConfirmed" &&
                                  <MdCancel style={{ color: "red" }} size={35} />
                                }
                                {
                                  notification.type === "CustomerScheduled" &&
                                  <MdSchedule style={{ color: "green" }} size={35} />
                                }
                                {
                                  notification.type === "CustomerRescheduled" &&
                                  <MdSchedule style={{ color: "#ff8a22" }} size={35} />
                                }
                                <p style={{
                                  color: "rgb(113, 131, 155)",
                                  fontSize: "0.8rem"
                                }}>{notification.message} {notification.fiberHouse && route[0]!=="map" && <span onClick={() => { openMap(notification) }} style={{
                                  color: "#2761D0",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  fontWeight: "bold"
                                }}>Open on Map</span>}</p>
                                <BiHide onClick={() => {
                                  markNotificationAsSeen(notification.id);
                                }} size={30} color="black" />
                                <p className="date">
                                  {
                                    // if timestamp is today, show time only else show date
                                    new Date(notification.timeStamp).toDateString() === new Date().toDateString() ?
                                      new Date(notification.timeStamp).toLocaleTimeString() :
                                      new Date(notification.timeStamp).toDateString()
                                  }
                                </p>
                              </div>
                            )
                          })
                          :
                          <div className="notification">
                            <p>No Notifications</p>
                          </div>
                      }
                    </div>
                  </div>
                </ClickAwayListener>
              }
            </div>
            <div onClick={() => navigate('/edit-account-details')} className='iconButton'>
              <SettingsIcon sx={{ color: "var(--color-icon)" }} fontSize="large" />
            </div>
            <div>
              <img style={{ objectFit: "cover" }} alt="Avatar" src={user?.profileImage ? user?.profileImage?.url : anonUser} className="w-12 h-12 rounded-full" />
            </div>
            {user?.firstName && <h1>{user?.firstName + " " + user?.lastName}</h1>}
            <button onClick={() => {
              localStorage.removeItem("searchFilters");
              localStorage.removeItem("contactCenterLeadsFilter")
              getAuth().signOut();
              navigate("/");
            }} style={{ color: "var(--color-icon)" }} className="bg-transparent text-base font-bold">
              SIGN OUT
            </button>
          </div>
        ) : (
          <button onClick={() => {
            navigate("/");
          }} style={{ color: "var(--color-icon)" }} className="bg-transparent text-base font-bold">
            SIGN IN
          </button>
        )}
      </div>
      <div style={{ display: 'flex', backgroundImage: `url("/images/banner/background.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
        {menuItems.length > 0 && (
          <div className="flex relative justify-center items-center h-full">
            <ProSidebar rootStyles={{
              height: "92vh",
              backgroundColor: "var(--color-sidebar)",
              zIndex: "2",
            }} onBackdropClick={() => setToggled(false)} onBreakPoint={(e) => { setBreakPoint(e); }} toggled={toggled} breakPoint="md" collapsed={isCollapsed}>
              <Menu
                menuItemStyles={{
                  button: ({ level, active, disabled }) => {
                    return {
                      backgroundColor: active ? 'var(--color-icon-hover) !important' : undefined,
                      color: active ? '#FFFFFF' : '#71839B',
                      borderTopRightRadius: "25px",
                      borderBottomRightRadius: "25px",
                      '&:hover': {
                        backgroundColor: '#181E4B',
                        color: '#FFFFFF'
                      }
                    };
                  },
                }}
              >
                {menuItems.map((item, index) => {
                  if (!item.disabled)
                    return (
                      <Item
                        title={item.name}
                        to={item.route}
                        icon={item.icon}
                        selected={selected}
                        setSelected={setSelected}
                        active={activeMenuItem?.name === item.name}
                        isLast={index === menuItems.length - 2}
                        key={index}
                      />
                    );
                })}
              </Menu>
            </ProSidebar>
            <div onClick={handleArrowClick} style={{ right: "-20px", zIndex: "0", borderColor: "var(--color-icon)" }} className="border-2 absolute top-1/2 rounded-r-xl">
              {isCollapsed ? <ArrowRight fontSize="medium" sx={{ color: "var(--color-icon)" }} /> : <ArrowLeft sx={{ color: "var(--color-icon)" }} fontSize="medium" />}
            </div>
          </div>
        )}
        <main className="w-full MainDiv" style={{ padding: 10, paddingLeft: "2rem", minHeight: "92vh", height: "92vh", overflow: "auto" }}>
          <Breadcrumbs light={document.body.classList.contains('dark')} icon={<HomeOutlinedIcon />} title={route[route.length - 1]} route={route} />
          <div className="SideBarMain py-4">
            {children}
          </div>
        </main>
      </div>
    </>
  );
};

export default Sidebar;
