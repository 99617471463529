import React, { useContext, useEffect, useState } from 'react'
import { MdOutlineLocationSearching } from "react-icons/md";
import { MapContext } from '..';
import { fromLonLat } from 'ol/proj';
import MapIconApi from '../../../../API/MapIconApi';
import SearchLeadsForContactCenterReq from '../../../../Requests/Customer/SearchLeadsForContactCenterReq';


const getSuggestionDetails = async (id) => {
    const detailsPlaceUrl =  await MapIconApi.GetMapIdDetails(id);
    console.log(detailsPlaceUrl)
    return detailsPlaceUrl;
}
const searchLocation = async (address, houses) => {

    try {
        const req = new SearchLeadsForContactCenterReq();
        req.SearchString = address;
        req.PagingParams.PageNumber = 1;
        req.PagingParams.PageSize = 10;
        const locationSuggestions  = await MapIconApi.SearchMapPlacesUrl(address);
        
        let formattedLocationSuggestions = [];
        if (locationSuggestions && locationSuggestions.status === "OK") {
            formattedLocationSuggestions = locationSuggestions.predictions.map((suggestion) => ({
                formatted_address: suggestion.description,
                place_id: suggestion.place_id,
                types: suggestion.types,
                isCustomer: false, // Mark this as a location result
            }));
        }


        const formattedCustomerSuggestions = houses
        .filter((house) =>
            (house.name && house.name.toLowerCase().includes(address.toLowerCase())) ||
            (house.email && house.email.toLowerCase().includes(address.toLowerCase())) ||
            (house.phoneNumber && house.phoneNumber.includes(address))
        )
        .slice(0, 10)
        .map((customer) => ({
            name: customer.name || 'No Name',
            email: customer.email || 'No Email',
            phoneNumber: customer.phoneNumber || 'No Phone',
            longitude: customer.coordinates.longitude,
            latitude: customer.coordinates.latitude,
            isCustomer: true, 
            fiberHouse: customer, 
        }));


        // Combine the two sets of suggestions
        const combinedSuggestions = [...formattedLocationSuggestions, ...formattedCustomerSuggestions];
        return { results: combinedSuggestions, status: "OK" };

    } catch (error) {
        console.error(error);
        return null;
    }
};



const SearchLocation = ({setIndex, setSelectedFiberHouse, fiberHouses})  => {

    const [search, setSearch] = useState("")
    const [locations, setLocations] = useState(null);
    const [loading, setLoading] = useState(false)

    const {mapObject} = useContext(MapContext);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const locationTypeZoomLevels = {
        "continent": 3,
        "country": 6,
        "administrative_area_level_1": 8,
        "administrative_area_level_2": 9,
        "locality": 10,
        "sublocality": 14,
        "neighborhood": 15,
        "route": 16,
        "street_address": 17,
        "premise": 20,
        "establishment": 18,
        "postal_code": 12,
        "natural_feature": 12,
        "point_of_interest": 16
    };
    
    // Define the priority order of location types
    const priorityOrder = [
        "premise",
        "establishment",
        "street_address",
        "route",
        "neighborhood",
        "sublocality",
        "locality",
        "administrative_area_level_2",
        "administrative_area_level_1",
        "postal_code",
        "natural_feature",
        "point_of_interest",
        "country",
        "continent"
    ];
    
    let selectedZoomLevel = null;
    let selectedType = null;

    const handleSelect = async (result) => {
        setLocations(null);
        setShowSuggestions(false);
    
        if (result.isCustomer) {
            // Handle the customer selection
            mapObject.getView().setCenter(fromLonLat([result.longitude, result.latitude]));
            mapObject.getView().setZoom(18);
            setSelectedFiberHouse(result.fiberHouse);
            setIndex(10);
            console.log('Customer selected:', result);
            // You can navigate to their details or move the map to their location if needed
        } else {
            // Handle the location suggestion
            const res = await getSuggestionDetails(result.place_id);
            mapObject.getView().setCenter(fromLonLat([res.result.geometry.location.lng, res.result.geometry.location.lat]));
    
            // Adjust the zoom level based on the result type
            for (const type of priorityOrder) {
                if (result.types.includes(type)) {
                    selectedZoomLevel = locationTypeZoomLevels[type];
                    selectedType = type;
                    break;
                }
            }
    
            if (selectedZoomLevel !== null) {
                mapObject.getView().setZoom(selectedZoomLevel);
            }
        }
        
    }

    useEffect(
        () => {
            async function getLocation() {
                if (search === "") {
                    return
                }
                setLoading(true)
                const data = await searchLocation(search, fiberHouses)
                setLocations(data)
                setShowSuggestions(true); 
                setLoading(false)
            }

            const timer = setTimeout(
                ()=>{
                    getLocation()
                },500
            )
            
            return () => {
                clearTimeout(timer)
            }

        }, [search]
    )

    const handleBlur = () => {
        console.log("Blurred")
     setShowSuggestions(false); // Delay to allow click event to register before hiding suggestions
    }
    
    const handleFocus = () => {
        if (search !== "") {
            setShowSuggestions(true); // Show suggestions again if input is focused and has value
        }
    }


    return (
        <>
            <div className='SearchLocation'>
                <input value={search} onChange={(e) => { setSearch(e.target.value) }} onFocus={handleFocus} onBlur={handleBlur} type="text" placeholder="Search Location" />
                <MdOutlineLocationSearching className={loading?'rotating':""} color='#2761D0' size={30} />
            </div>
            {showSuggestions && (
                
                <div className='options'>
                    {
                        locations?.results?.map(
                            (result,index) => {
                                return (
                                    <div onMouseDown={()=>{handleSelect(result)}} key={index}>
                                        <p>
                                            {!result.isCustomer && result.formatted_address}
                                            {result.isCustomer && <span style={{ color: "blue" }}>{result.name}</span>}
                                        </p>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            )}
        </>
    )
}

export default SearchLocation