import { Modal } from '@mui/material'
import React, { useEffect } from 'react'
import FreeInstallForm from './components/FreeInstallForm'
import './DigitalToolBelt.scss'
import RescheduleForm from './components/RescheduleForm'
import CancelForm from './components/CancelForm'
import IssueTicket from './components/IssueTicket'
import ReviewForm from './components/ReviewForm'
import ServiceUpDown from './components/ServiceUpDown'
import { IoMdCloseCircle } from 'react-icons/io'
import ShceduleForm from './components/ShceduleForm'
import { IoCloseCircle } from 'react-icons/io5'
import Refer from './components/Refer'
import SearchCustomerReq from '../../Requests/Customer/SearchCustomerReq'
import CustomerApi from '../../API/CustomerApi'
const Index = ({ open, setOpen, lead }) => {
    const [selectctedTab, setSelectedTab] = React.useState(!lead?.fidiumCustomer ? "Install" : "Reschedule")
    console.log(lead)
    const [fidiumCustomer, setFidiumCustomer] = React.useState(null)





    useEffect(() => {
        if (!lead?.fidiumCustomer) {
            setSelectedTab("Install")
        }
        else if (lead?.fidiumCustomer) {
            setSelectedTab("Reschedule")
        }
    }, [lead])
    if (!lead) {
        return null
    }
    return (
        <Modal sx={{
            outline: 'none',
        }} open={open} onClose={() => setOpen(false)}>
            <div className='digital_tool_belt_container'>

                {/* <p style={{
                    cursor: 'pointer',
                    textAlign: 'right',
                    // color: '#2761D0',
                    // fontSize: '14px',
                    // fontWeight: 'bold',
                    marginLeft: 'auto'
                }} >
                </p> */}
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '100%',
                }}>
                    <IoMdCloseCircle
                        style={{
                            cursor: 'pointer',
                            textAlign: 'right',
                            marginLeft: "auto"
                        }}
                        onClick={() => setOpen(false)} color='#2761D0' size={30} />
                </div>
                <h1>Digital Tool Belt</h1>
                <div style={{
                    justifyContent: lead.fidiumCustomer ? 'space-between' : 'center'
                }} className='icons_list'>
                    {
                        !lead.fidiumCustomer &&
                        <div className='icon' onClick={() => setSelectedTab('Install')}>
                            <img src={selectctedTab === "Install" ? '/images/digital_tool_belt/light_b.svg' : '/images/digital_tool_belt/light.svg'} alt='light' />
                        </div>
                    }

                    {
                        lead.fidiumCustomer &&
                        <div className='icon' onClick={() => setSelectedTab('Reschedule')}>
                            <img src={selectctedTab === "Reschedule" ? '/images/digital_tool_belt/reschedule_b.svg' : '/images/digital_tool_belt/reschedule.svg'} alt='medium' />
                        </div>
                    }
                    {
                        lead.fidiumCustomer &&
                        <div className='icon' onClick={() => setSelectedTab('Cancel')}>
                            <img src={selectctedTab === "Cancel" ? '/images/digital_tool_belt/cancel_b.svg' : '/images/digital_tool_belt/cancel.svg'} alt='medium' />
                        </div>
                    }
                    {
                        lead.fidiumCustomer &&
                        <div className='icon' onClick={() => setSelectedTab('Ticket')}>
                            <img src={selectctedTab === "Ticket" ? '/images/digital_tool_belt/ticket_b.svg' : '/images/digital_tool_belt/ticket.svg'} alt='medium' />
                        </div>
                    }
                    {
                        lead.fidiumCustomer &&
                        <div className='icon' onClick={() => setSelectedTab('Refer')}>
                            <img src={selectctedTab === "Refer" ? '/images/digital_tool_belt/refer_b.svg' : '/images/digital_tool_belt/refer.svg'} alt='medium' />
                        </div>
                    }
                    {
                        lead.fidiumCustomer && lead.fidiumCustomer.accountStatus === "Completed" &&
                        <div className='icon' onClick={() => setSelectedTab('Review')}>
                            <img src={selectctedTab === "Review" ? '/images/digital_tool_belt/review_b.svg' : '/images/digital_tool_belt/review.svg'} alt='medium' />
                        </div>
                    }
                    {/* {
                        lead.fidiumCustomer &&
                        <div className='icon' onClick={() => setSelectedTab('Arrow')}>
                            <img src={selectctedTab === "Arrow" ? '/images/digital_tool_belt/arrow_b.svg' : '/images/digital_tool_belt/blue_arrow.svg'} alt='medium' />
                        </div>
                    } */}
                </div>
                {
                    selectctedTab === 'Install' && <FreeInstallForm lead={lead} />
                }
                {
                    selectctedTab === 'Reschedule' && lead.fidiumCustomer.installDateTime && <RescheduleForm lead={lead} />
                }
                {
                    selectctedTab === 'Reschedule' && !lead.fidiumCustomer.installDateTime && <ShceduleForm lead={lead} />
                }
                {
                    selectctedTab === 'Cancel' && <CancelForm lead={lead} />
                }
                {
                    selectctedTab === "Ticket" && <IssueTicket lead={lead} />
                }
                {
                    selectctedTab === "Refer" && <Refer lead={lead} />
                }
                {
                    selectctedTab === "Review" && <ReviewForm disabled={true} lead={lead} />
                }
                {
                    selectctedTab === "Arrow" && <ServiceUpDown lead={lead} />
                }
            </div>
        </Modal>
    )
}

export default Index