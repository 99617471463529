import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import UsersApi from '../../../API/UsersApi';
import { UserContext } from '../../../App';
import Layout from '../../../Layouts/Layout'; // Import Layout component


// Set up moment localizer
const localizer = momentLocalizer(moment);

// Functional component with Layout and Calendar
const MyCalendar = () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const LoggedInUser = useContext(UserContext)
    useEffect(() => {
        const fetchAppointments = async () => {

            // Replace with the actual request object if needed
            const request = { Int: LoggedInUser.id }; // Example request object
            const response = await UsersApi.SearchCalenderAppointments(request);

            if (response) {
                console.log(response.data);
                setEvents(response.data); // Assuming the API response contains a list of events
            } else {
                setError('Failed to load events');
            }

            setLoading(false);

        };

        fetchAppointments();
    }, []); // Empty dependency array ensures this runs only once on component mount

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Layout>
            <div style={{ height: '100vh', padding: '20px' }}>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 'calc(100vh - 40px)' }}
                />
            </div>
        </Layout>
    );
};

export default MyCalendar;
