/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from 'react'
import { MapContext } from '../..'

// import Modal from './../../../../../components/Modal'
import { Modal } from '@mui/material'
import CreateCustomer from './../CreateCustomer'
import { UserContext } from '../../../../../App'
import { FaFire, FaRegCopy } from "react-icons/fa";
import { IoCloseCircle, IoQrCode } from "react-icons/io5";
import { MdBolt, MdDoorbell, MdRoute } from "react-icons/md";
import Swal from 'sweetalert2'
// import DeleteFiberHouseReq from './../../../../../Requests/FiberHouse/DeleteFiberHouseReq'
import FiberHouseApi from '../../../../../API/FiberHouseApi'
// import DeleteCustomerReq from '../../../../../Requests/Customer/DeleteCustomerReq'
import CustomerApi from '../../../../../API/CustomerApi'
// import { MdDeleteOutline } from "react-icons/md";
import { FaAddressCard } from "react-icons/fa6";
import EditLeadReq from '../../../../../Requests/FiberHouse/EditLeadReq'
import EditFiberHouseReq from '../../../../../Requests/FiberHouse/EditFiberHouseReq'
// import { TbFilePhone } from 'react-icons/tb'
import UpdateLeadAppointmentReq from '../../../../../Requests/Customer/UpdateLeadAppointmentReq'
import bot from "./../../Images/bot.png"
import authorized from './Images/authorized.png'
import logo from './Images/logo.png'
import UpdateCustomerInstallDateReq from '../../../../../Requests/Customer/UpdateCustomerInstallDateReq'
import CreateLightCustomerReq from '../../../../../Requests/Customer/CreateLightCustomerReq'
import PhoneInput from 'react-phone-input-2'
import ContextMenu from '../../../../../components/ContextMenu'
import FiberCompanySelector from '../../../../../components/FiberCompanySelector'
import FiberPlanSelector from '../../../../../components/FiberPlanSelector'
import CreateRescheduleFormReq from '../../../../../Requests/Customer/CreateRescheduleFormReq'
import { RxReset } from "react-icons/rx"
import MapHelper from '../../MapHelper'
import { fromLonLat } from 'ol/proj'
import { GiTv } from 'react-icons/gi'
import MarkLeadAsBundledReq from '../../../../../Requests/FiberHouse/MarkLeadAsBundledReq'
import { SiSpeedtest } from 'react-icons/si'
import SpeedTestModal from './SpeedTestModal'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
const LeadData = ({ mapIcon, setReload, fidiumCustomer, lead, setEdit, fiberHouse, searchFiberHouses, setIndex, setFiberHouse }) => {
    const user = useContext(UserContext)
    let { mapIcons, mapObject, salesReps, setFiberHouses, setSelectedFiberHouse, selectedFiberHouseFeature } = useContext(MapContext)
    console.log(lead)
    // console.log(fiberHouse)
    // console.log(mapIcons)
    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState(() => {

        if (lead.fidiumCustomer != null) {
            return lead.fidiumCustomer.firstName + " " + lead.fidiumCustomer.lastName;
        }
        else if (lead.name != null) {
            return lead.name;
        }
        else {
            return "";
        }

    })
    const [email, setEmail] = useState(lead?.email)
    const [phone, setPhone] = useState(lead?.phone)
    const [openModal, setOpenModal] = useState(false)
    const [openInstallTimeModal, setOpenInstallTimeModal] = useState(false)
    const [address, setAddress] = useState(lead?.fiberHouse?.mapString);
    const [appointmentDateTime, setAppointmentDateTime] = useState(lead?.appointmentDateTime)
    const [installDateTime, setInstallDateTime] = useState(lead?.fidiumCustomer?.installDateTime)
    const [installDateTimeTo, setInstallDateTimeTo] = useState(null)
    const initialRender = useRef(true)
    const shouldSaveAddress = useRef(false)
    const [openContextMenu, setOpenContextMenu] = useState(false)

    // light customer inputs
    const [lightFirstName, setLightFirstName] = useState(lead?.name?.split(' ')[0])
    const [lightLastName, setLightLastName] = useState(lead?.name?.split(' ').length > 1 ? lead?.name?.split(' ')[1] : '')
    const [lightEmail, setLightEmail] = useState(lead?.email)
    const [lightPhone, setLightPhone] = useState(lead?.phone)
    // const [lightSalesRep, setLightSalesRep] = useState(null)
    const [lightOpenModal, setLightOpenModal] = useState(false)
    const [speedTestOpenModal, setSpeedTestOpenModal] = useState(false)
    const [beforeImageUrl, setBeforeImageUrl] = useState('');
    const [afterImageUrl, setAfterImageUrl] = useState('');
    const [lightConsent, setLightConsent] = useState(false)
    const [installCompany, setInstallCompany] = useState(null)
    const [installPlan, setInstalltPlan] = useState(null)
    const [rescheduleFrom, setRescheduleFrom] = useState(null)
    const [rescheduleTo, setRescheduleTo] = useState(null)
    const [isBundled, setIsBundled] = useState(lead?.isBundled);
    const [hasVideoDoorBell, setHasVideoDoorBell] = useState(lead?.hasVideoDoorBell);
    const [selectedTimeRange, setSelectedTimeRange] = useState("")
    const [installTimeFrom, setInstallTimeFrom] = useState("")
    const [installTimeTo, setInstallTimeTo] = useState("")
    // const deleteFiberHouse = async () => {
    //     const result = await Swal.fire({
    //         icon: "question",
    //         title: "Are you sure?",
    //         text: "You want to delete this Fiber House",
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //         allowEnterKey: false,
    //         showConfirmButton: true,
    //         showCancelButton: true,
    //         confirmButtonText: "Yes, I'm Sure!",

    //     })

    //     if (result.isConfirmed) {
    //         Swal.fire({
    //             icon: "info",
    //             title: "Please Wait",
    //             text: "We are deleting the Fiber House",
    //             allowOutsideClick: false,
    //             allowEscapeKey: false,
    //             allowEnterKey: false,
    //             showConfirmButton: false,
    //             showCancelButton: false,
    //             willOpen: () => {
    //                 Swal.showLoading();
    //             },
    //         })
    //         if (lead.fidiumCustomerId) {
    //             const deleteCustomerReq = new DeleteCustomerReq()
    //             deleteCustomerReq.int = lead.fidiumCustomerId
    //             deleteCustomerReq.pagingParams.pageNumber = 1
    //             deleteCustomerReq.pagingParams.pageSize = 10
    //             const res1 = await CustomerApi.DeleteFidiumCustomer(deleteCustomerReq);

    //         }
    //         const deleteFiberHouseReq = new DeleteFiberHouseReq()
    //         deleteFiberHouseReq.int = fiberHouse.id
    //         deleteFiberHouseReq.pagingParams.pageNumber = 1
    //         deleteFiberHouseReq.pagingParams.pageSize = 10
    //         const res = await FiberHouseApi.DeleteFiberHouse(deleteFiberHouseReq)
    //         if (res?.status?.success) {
    //             Swal.fire({
    //                 icon: "success",
    //                 title: "Success",
    //                 text: "Fiber House Deleted Successfully",
    //                 showConfirmButton: false,
    //                 showCancelButton: true,
    //                 cancelButtonText: "Close",
    //                 allowOutsideClick: false,
    //                 allowEscapeKey: false,
    //                 allowEnterKey: false,
    //                 willClose: () => {
    //                     Swal.hideLoading()
    //                 }
    //             })
    //             setFiberHouses(
    //                 (prev) => {
    //                     return prev.filter((fh) => fh.id !== fiberHouse.id)
    //                 }
    //             )
    //             setSelectedFiberHouse(null)
    //             setSelectedLead(null)
    //             setIndex(-1)
    //         }
    //         else {
    //             Swal.fire({
    //                 icon: "error",
    //                 title: "Error",
    //                 text: res?.status?.message
    //             })

    //         }
    //     }
    // }
    const formatDateToISOString = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

        // Concatenate without timezone offset
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };

    function copyAddress() {
        navigator.clipboard.writeText(address);
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: 'Address Copied to Clipboard',
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 1500,
            toast: true
        })
    }


    function copyPhone() {
        navigator.clipboard.writeText(phone.slice(2));
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: 'Phone Number Copied to Clipboard',
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 1500,
            toast: true
        })
    }

    function copyEmail() {
        navigator.clipboard.writeText(email);
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: 'Email Copied to Clipboard',
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 1500,
            toast: true
        })
    }


    const updateLead = async (type) => {

        let req = new EditLeadReq();
        req.id = lead.id;

        switch (type) {
            case 'name':
                req.name = name;
                break;
            case 'email':
                req.email = email;
                break;
            case 'phone':
                req.phone = phone;
                break;
            default:
                req.name = name;
                req.email = email;
                req.phone = phone;
        }
        req.salesRepId = user.id;
        let res = await FiberHouseApi.EditLead(req);
        if (res?.status?.success) {
            searchFiberHouses();
            setEdit(false);
        }
    }

    const updateFiberHouse = async () => {
        let req = new EditFiberHouseReq();
        req.id = lead?.fiberHouse?.id;
        req.mapString = address;
        req.coordinate = null
        req.salesRepId = user?.id;
        const res = await FiberHouseApi.EditFiberHouse(req);
        if (res?.status?.success) {
            searchFiberHouses();
        }
    }

    useEffect(
        () => {
            if (name !== lead?.name && name !== "") {
                const timer = setTimeout(() => {
                    updateLead('name')
                }, 1000);
                return () => clearTimeout(timer);
            }
        }, [name]
    )

    useEffect(
        () => {
            if (email !== lead?.email && email !== "") {
                const timer = setTimeout(() => {
                    updateLead('email')
                }, 1000);
                return () => clearTimeout(timer);
            }
        }, [email]
    )



    useEffect(
        () => {
            if (phone !== lead?.phone) {
                const timer = setTimeout(() => {
                    updateLead('phone')
                }, 1000);
                return () => clearTimeout(timer);
            }
        }, [phone]
    )

    useEffect(
        () => {
            if (initialRender.current) {
                initialRender.current = false;
                return;
            }
            else if (shouldSaveAddress.current && address !== lead?.fiberHouse?.mapString) {
                const timer = setTimeout(() => {
                    updateFiberHouse()
                }, 1000);
                return () => clearTimeout(timer);
            }
        }, [address]
    )


    const updateAppointmentDate = async () => {
        setOpenModal(false)
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Updating Appointment Date",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            },
        })
        const req = new UpdateLeadAppointmentReq();
        req.leadId = lead.id;
        req.appointmentDateTime = appointmentDateTime;
        req.appointmentType = null;
        const res = await FiberHouseApi.UpdateLeadAppointment(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Appointment Date Updated Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            setReload(
                (prev) => {
                    return !prev
                }
            )
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message
            })
        }
    }

    const updateInstallDate = async () => {

        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Updating Install Date",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: 'custom-swal'
            },
            willOpen: () => {
                Swal.showLoading();
            },
        })

        // the day should be the same in the install date and install date to and the time in the install date should be less than the time in the install date to
        if (new Date(installDateTime).toLocaleDateString() !== new Date(installDateTimeTo).toLocaleDateString()) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "The Install Date and Install Date To should be on the same day",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                customClass: {
                    container: 'custom-swal'
                },
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            return
        }
        if (new Date(installDateTime).getTime() > new Date(installDateTimeTo).getTime()) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "The Install Time should be less than the Install Time To",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                customClass: {
                    container: 'custom-swal'
                },
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!installPlan) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select a plan",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }


        const req = new UpdateCustomerInstallDateReq();
        req.customerId = fidiumCustomer.id;
        req.installDateTime = installDateTime;
        req.installDateTimeTo = installDateTimeTo;
        req.fiberCompanyId = installCompany;
        req.fiberPlanId = installPlan?.id;
        const res = await CustomerApi.EditCustomerInstallDate(req);
        if (res?.status?.success) {
            setOpenInstallTimeModal(false)
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Install Date Updated Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            setReload(
                (prev) => {
                    return !prev
                }
            )
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message
            })
        }
    }

    const submitReschedule = async () => {

        setOpenInstallTimeModal(false)
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Submitting Reschedule Request",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            willOpen: () => {
                Swal.showLoading();
            },
        })

        if (!rescheduleFrom) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select Install Date",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!installTimeFrom || !installTimeTo) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select Install Time",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lead?.fidiumCustomer) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "There is no customer associated with this lead",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        const req = new CreateRescheduleFormReq();
        req.FidiumCustomerId = fidiumCustomer.id;
        console.log(rescheduleFrom)
        let installDate = new Date().setMonth(rescheduleFrom.split('-')[1] - 1)
        installDate = new Date(installDate).setDate(rescheduleFrom.split('-')[2])
        installDate = new Date(new Date(installDate).setFullYear(rescheduleFrom.split('-')[0]))


        let from = formatDateToISOString(new Date(installDate.setHours(installTimeFrom.split(':')[0], installTimeFrom.split(':')[1])))
        let to = formatDateToISOString(new Date(installDate.setHours(installTimeTo.split(':')[0], installTimeTo.split(':')[1])))

        req.NewInstallDate = from;
        req.NewInstallDateTo = to;
        const res = await CustomerApi.RescheduleForm(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Reschedule Request Submitted Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            setReload(
                (prev) => {
                    return !prev
                }
            )
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message
            })
        }
    }


    const auth = getAuth();
    const createLightCustomer = async () => {
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Creating Light Customer",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: 'custom-swal'
            },
            willOpen: () => {
                Swal.showLoading();
            },
        })

        if (!lightFirstName) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "First Name is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lightEmail) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Email is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        var pattern = new RegExp(/^\S+@\S+\.\S+$/);
        if (!pattern.test(lightEmail)) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Email',
                text: 'Please enter a valid email address',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        if (!lightPhone) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Phone is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }



        if (!lightConsent) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Consent is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }


        const req = new CreateLightCustomerReq()
        req.leadId = lead.id
        req.firstName = lightFirstName
        req.lastName = lightLastName
        req.email = lightEmail
        req.phoneNumber = lightPhone
        req.salesRepId = user.id
        req.signedUpBy = "SalesRep"
        req.salesOrgId = user?.salesOrgId
        const res = await CustomerApi.CreateLightCustomer(req)
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Light Customer Created Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            sendPasswordResetEmail(auth, lightEmail)
                .then((value) => {
                    // Password reset email sent!
                    // ..

                    //   Swal.fire({
                    //     icon: 'success',
                    //     title: 'Password reset email sent',
                    //     text: 'Please check your email',
                    //   });
                })
                .catch((error) => {
                    // const errorCode = error.code;
                    const errorMessage = error.message;

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: errorMessage,
                    });
                });
            setSelectedFiberHouse(
                (prev) => {
                    return {
                        ...prev,
                        mapIconId: 9
                    }
                }
            )
            setFiberHouses(
                (prev) => {
                    return prev.map((fh) => {
                        if (fh.id === fiberHouse.id) {
                            return {
                                ...fh,
                                mapIconId: 9
                            }
                        }
                        return fh
                    })
                }
            )
            setReload(
                (prev) => {
                    return !prev
                }
            )

            setLightOpenModal(false)
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                customClass: {
                    container: 'custom-swal'
                },
                text: res?.status?.message
            })
        }
    }

    const menuItemsForConnector = [
        {
            title: `Go`,
            onClick: () => {
                window.open('https://rainierconnect.smarthub.coop/Shop.html#SelectAddressAndCity:', '_blank')
            }
        },
        {
            title: `Copy Link`,
            onClick: () => {
                navigator.clipboard.writeText('https://rainierconnect.smarthub.coop/Shop.html#SelectAddressAndCity:')
                Swal.fire({
                    icon: 'success',
                    title: 'Copied',
                    text: 'Link Copied to Clipboard',
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                })
            }
        }
    ]

    async function updateCoordinates(fh) {
        console.log(fh)
        // return
        const result = await Swal.fire({
            title: 'Update Coordinates',
            text: 'Are you sure you want to Reset the Position of the Lead (this can not be reversed)? ',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: 'green',
            cancelButtonColor: 'red',
        });

        if (result.isConfirmed) {
            // let coordinatesFailed = 0
            // const list = []
            Swal.fire({
                icon: "info",
                title: "Please Wait",
                text: "We are updating the coordinates",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: false,
                willOpen: () => {
                    Swal.showLoading();
                }
            })
            const helper = new MapHelper();
            const mapstring = fh.mapString

            const coordinates = await helper.searchLocation(mapstring);
            if (selectedFiberHouseFeature && selectedFiberHouseFeature.get('id') === fh.id) {
                selectedFiberHouseFeature.getGeometry().setCoordinates(fromLonLat(coordinates))
                mapObject.getView().setCenter(fromLonLat(coordinates))
            }

            const req = new EditFiberHouseReq()
            req.coordinate.latitude = coordinates[1];
            req.coordinate.longitude = coordinates[0];
            req.id = fh.id;

            // update the coordiantes of the feature


            const res = await FiberHouseApi.EditFiberHouse(req);
            if (res?.status?.success) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Coordinates Updated Successfully",
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: "Close",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    willClose: () => {
                        Swal.hideLoading()
                    }
                })
            }
            else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: res?.status?.message,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: "Close",
                    willClose: () => {
                        Swal.hideLoading()
                    }
                })
            }
        }


    }

    const markLeadAsBundled = () => {
        const req = new MarkLeadAsBundledReq();
        req.id = lead.id;
        req.isBundled = !isBundled;
        setIsBundled(!isBundled)
        const res = FiberHouseApi.MarkLeadAsBundled(req);
        if (res?.status?.success) {
            console.log(res);
        }
    }

    const markLeadHasVideoDoorBell = () => {
        const req = new MarkLeadAsBundledReq();
        req.id = lead.id;
        req.hasVideoDoorBell = !hasVideoDoorBell;
        setHasVideoDoorBell(!hasVideoDoorBell)
        const res = FiberHouseApi.MarkLeadHasVideoDoorBell(req);
        if (res?.status?.success) {
            console.log(res);
        }
    }


    const handleImageUpload = (e, setImage) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result); // Set the image as a base64 string
            };
            reader.readAsDataURL(file);
        }
    };


    return (
        <>
            <Modal setOpen={setOpenModal} open={openModal} onClose={() => {
                setOpenModal(false)

            }}>
                <div className='custom_modal'>
                    <IoCloseCircle onClick={() => {
                        setOpenModal(false)
                    }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                    <img alt="Logo" src={'/images/nav/nav-logo.png'} className='logo' />
                    <h1 >Appointment Date</h1>
                    <input type="datetime-local" value={appointmentDateTime} onChange={(e) => {
                        setAppointmentDateTime(e.target.value)
                    }} />
                    <button
                        onClick={() => {
                            updateAppointmentDate()
                        }
                        }>Update</button>
                </div>
            </Modal>
            <Modal setOpen={setOpenInstallTimeModal} open={openInstallTimeModal} onClose={() => {
                setOpenInstallTimeModal(false)
            }} >
                {
                    lead?.fidiumCustomer?.installDateTime ?
                        <div className='custom_modal'>
                            <IoCloseCircle onClick={() => {
                                setOpenInstallTimeModal(false)
                            }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                            <img alt="Logo" src={'/images/nav/nav-logo.png'} className='logo' />
                            {/* <h1 >Install Date Time</h1> */}
                            <label>Reschedule Date</label>
                            <input type="date" value={rescheduleFrom} onChange={(e) => {
                                setRescheduleFrom(e.target.value)
                            }
                            } />
                            <label>Intall Time Slot:</label>
                            <div className='time_slot'>
                                <input type='time' value={installTimeFrom} onChange={(e) => {
                                    setInstallTimeFrom(e.target.value)
                                    setSelectedTimeRange("")
                                }} />
                                <p>To</p>
                                <input type='time' value={installTimeTo} onChange={(e) => {
                                    setInstallTimeTo(e.target.value)
                                    setSelectedTimeRange("")
                                }} />
                            </div>
                            <p className='or'>OR</p>
                            <div className='am_pm'>
                                <p onClick={
                                    () => {
                                        setSelectedTimeRange("AM");
                                        setInstallTimeFrom("08:00")
                                        setInstallTimeTo("12:00")
                                    }
                                } className={`${selectedTimeRange === "AM" ? 'active' : ''}`} >AM</p>
                                <p onClick={
                                    () => {
                                        setSelectedTimeRange("PM");
                                        setInstallTimeFrom("12:00")
                                        setInstallTimeTo("18:00")
                                    }
                                } className={`${selectedTimeRange === "PM" ? 'active' : ''}`}>PM</p>
                            </div>
                            <button
                                onClick={() => {
                                    submitReschedule()
                                }
                                }>SUBMIT RESCHEDULE REQUEST</button>
                        </div>
                        :
                        <div className='custom_modal'>
                            <IoCloseCircle onClick={() => {
                                setOpenInstallTimeModal(false)
                            }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                            <img alt="Logo" src={'/images/nav/nav-logo.png'} className='logo' />
                            {/* <h1 >Install Date Time</h1> */}
                            <label>Install Date</label>
                            <input step={"3600"} type="datetime-local" value={installDateTime} onChange={(e) => {
                                setInstallDateTime(e.target.value)
                            }
                            } />
                            <label>Intall Date to:</label>
                            <input type='datetime-local' value={installDateTimeTo} onChange={(e) => {
                                setInstallDateTimeTo(e.target.value)
                            }} />
                            <FiberCompanySelector setUser={setInstallCompany} disabled={false} placeholder="Select a Company" className="fiber_company_selector" />
                            <FiberPlanSelector setUser={setInstalltPlan} disabled={installCompany ? false : true} placeholder="Select a Plan" className="fiber_company_selector" fiberCompany={installCompany} />
                            <button
                                onClick={() => {
                                    updateInstallDate()
                                }
                                }>SCHEDULE INSTALL</button>
                        </div>
                }
            </Modal>
            <Modal setOpen={setLightOpenModal} minHeight={'80vh'} open={lightOpenModal} onClose={() => {
                setLightOpenModal(false)
            }} >
                <div className='custom_modal'>


                    <img alt="Logo" src={authorized} className='logo' />
                    {/* <h1 >Customer Info</h1> */}
                    <div className='inputs'>
                        <input placeholder='*First Name' value={lightFirstName} onChange={(e) => {
                            setLightFirstName(e.target.value)
                        }} />
                        <input placeholder='Last Name' value={lightLastName} onChange={(e) => {
                            setLightLastName(e.target.value)
                        }} />
                        <input placeholder='Email' value={lightEmail} onChange={(e) => {
                            setLightEmail(e.target.value)
                        }} />
                        {/* <FiberCompanySelector setUser={setLightCompany} disabled={false} placeholder="Select a Company" className="fiber_company_selector" /> */}
                        {/* <FiberPlanSelector setUser={setLightPlan} disabled={lightCompany ? false : true}
                            placeholder="Select a Plan" className="fiber_company_selector" fiberCompany={lightCompany} /> */}
                        <PhoneInput placeholder='* Cellphone' country={'us'} containerStyle={{
                            width: "100%",
                            // marginBottom: "1rem",
                            borderRadius: "10px"
                        }} inputClass='custom_input' inputStyle={{
                            // width: "100%",
                            // height: "auto",
                            // padding: "0.5rem",
                            // fontSize: "1.2rem",
                            // borderRadius: "10px"
                        }} value={lightPhone} onChange={(e) => {
                            setLightPhone(e)
                        }} />
                        <div className='consent'>
                            <input type="checkbox"
                                checked={lightConsent}
                                onChange={(e) => {
                                    setLightConsent(e.target.checked)
                                }}
                            />
                            <label>I agree to receive email and/or sms messages from Lightning OS LLC.</label>
                        </div>
                        <button onClick={() => {
                            createLightCustomer()
                        }
                        }>Reserve Free Install</button>
                        <img alt='Logo' src={logo} style={{
                            width: "100%",
                            // marginTop: "1rem"
                        }} className='logo' />
                    </div>
                </div>
            </Modal>
            <SpeedTestModal
                user={user}
                lead={lead}
                open={speedTestOpenModal}
                setOpen={setSpeedTestOpenModal}
                beforeImageUrl={beforeImageUrl}
                setBeforeImageUrl={setBeforeImageUrl}
                afterImageUrl={afterImageUrl}
                setAfterImageUrl={setAfterImageUrl}
                handleImageUpload={handleImageUpload}
            />
            <ContextMenu
                anchorEl={openContextMenu}
                setAnchorEl={setOpenContextMenu}
                menuItems={menuItemsForConnector}
                handleClose={() => {
                    setOpenContextMenu(null)
                }}
            />
            <div className="LeadData">
                <span className='see_activity' style={{
                    marginLeft: "auto",
                    marginBottom: "1rem",
                }} onClick={() => setIndex(13)}>See Activity</span>
                <div className="DataDiv">
                    {/* Lead Icon */}

                    {
                        <div className='owner_name'>
                            {/* <p>Owned By</p> */}
                            {
                                // (user.userType === "SuperAdmin" || user.userType === "SalesOrgAdmin") &&
                                salesReps?.filter(rep => lead?.fiberHouse?.salesRepFiberHouses.some(salesRep => salesRep.salesRepId === rep.id)).map(rep => {
                                    return (

                                        <div>
                                            <MdBolt style={{ color: "#a0a4ac" }} size={30} />
                                            <p>{rep.firstName + " " + rep.lastName}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    {/* See Activity + Edit Contact Info */}

                    <div className='ButtonsDivTop'>
                        {/* <span onClick={() => setEdit(true)}>Edit Contact Info</span> */}

                        {lead?.fidiumCustomer &&
                            <div style={{
                                width: "fit-content",
                                cursor: "pointer",
                            }} onClick={(e) => {
                                setSpeedTestOpenModal(e.currentTarget)
                            }} >
                                <SiSpeedtest className='icon' size={25} color='#2761D0' />
                            </div>
                        }
                        {
                            lead?.fidiumCustomer == null &&
                            <div className='bot' onClick={() => {
                                setLightOpenModal(true)
                            }} style={{
                                // padding: "0.2rem",
                                borderRadius: "20px",
                                border: 'none',
                                cursor: "pointer",
                                // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)"
                            }}>
                                <img src={bot} width={30} height={30} alt="bot" style={{

                                }} />
                            </div>
                        }

                        <div style={{
                            width: "fit-content",
                            cursor: "pointer",
                        }} onClick={(e) => {
                            setOpenContextMenu(e.currentTarget)
                        }} >
                            <MdRoute className='icon' size={25} color='#2761D0' />
                        </div>
                        <div style={{
                            width: "fit-content",
                            cursor: "pointer",
                        }} onClick={() => {
                            markLeadAsBundled()
                        }}>
                            <GiTv color={isBundled ? '#2761D0' : 'grey'} size={26} />
                        </div>
                        <div style={{
                            width: "fit-content",
                            cursor: "pointer",
                        }} onClick={() => {
                            markLeadHasVideoDoorBell()
                        }}>
                            <MdDoorbell color={hasVideoDoorBell ? '#2761D0' : 'grey'} size={26} />
                        </div>
                        {/* <div style={{
                            width: "fit-content",
                            cursor: "pointer",
                        }} onClick={() => deleteFiberHouse()}>
                            <MdDeleteOutline className='icon' size={25} color='red' />
                        </div> */}

                    </div>

                    <hr style={{
                        gridColumn: "span 3/span 3"
                    }} />
                    <div className="DataDiv_MapIcon">
                        <img src={"data:image/png;base64," + mapIcons.find(assignedMapIcons => assignedMapIcons.id === mapIcon)?.image} alt="Map Icon" />
                        <input placeholder='No Name' value={name} onChange={(event) => {
                            setName(event.target.value)
                        }} className='LeadName' />
                        <div>
                        </div>
                        <span>
                            {mapIcons.find(assignedMapIcons => assignedMapIcons.id === mapIcon)?.name + " "}
                            {mapIcons.find(assignedMapIcons => assignedMapIcons.id === mapIcon)?.abbreviation && "(" + mapIcons.find(assignedMapIcons => assignedMapIcons.id === mapIcon)?.abbreviation + ")"}
                        </span>
                    </div>
                    <hr style={{
                        gridColumn: "span 3/span 3"
                    }} />
                    {
                        <div style={{
                            gridColumn: "span 2/span 2",
                            display: "flex"
                        }}>
                            <textarea rows={3} style={{
                                width: "100%",
                                outline: "none",
                            }} placeholder='Address' className='' value={address} onChange={(e) => {
                                setAddress(e.target.value)
                                shouldSaveAddress.current = true
                            }} />
                            <FaRegCopy style={{
                                cursor: "pointer",
                                marginLeft: "0.5rem",
                            }} size={20} color='#84A1C8' onClick={() => copyAddress()} />
                        </div>

                    }
                    <div className='ButtonsDivBottom'>
                        <div >
                            <RxReset className='icon' size={25} color='#2761D0' onClick={() => { updateCoordinates(lead?.fiberHouse) }} />
                        </div>
                        <div style={{
                            borderBottom: lead?.isHotLead ? '6px solid red' : 'none', borderRadius: '5px'
                        }} className='HotLeadDiv'>


                            <FaFire className='icon' size={25} color='#2761D0' onClick={() => setIndex(11)} />
                        </div>
                        <div style={{ borderRadius: '5px' }} className='QRCodeDiv'>

                            <IoQrCode className='icon' size={25} color='#2761D0' onClick={() => setIndex(14)} />
                        </div>
                        {/* <div style={{ borderBottom: lead?.assignedConvertedToCustomer ? '6px solid green' : "none", borderRadius: '5px' }} className='FreeInstallFormDiv'>

                            <MdEditDocument className='icon' size={25} color='#2761D0' onClick={() => setModalOpen(true)} />
                        </div> */}
                        <div>
                            <FaAddressCard className='icon' size={25} color='#2761D0' onClick={() => setIndex(17)} />
                        </div>
                    </div>

                    <hr style={{
                        gridColumn: "span 3/span 3"
                    }} />
                    <div style={{
                        gridColumn: "span 3/ span 3",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        // gap: "1rem" // Add gap between grid items
                    }}>


                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input
                                placeholder='No Email'
                                className='email_phone_input'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{
                                    fontSize: "1rem"
                                }}

                            // style={{ flex: 1 }}
                            />
                            {email &&
                                <FaRegCopy
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    size={20}
                                    color='#84A1C8'
                                    onClick={copyEmail}
                                />
                            }
                        </div>

                        <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            <input
                                placeholder='No Phone'
                                className='email_phone_input'
                                value={phone}
                                style={{
                                    fontSize: "1rem"
                                }}
                                onChange={(e) => setPhone(e.target.value)}
                            // style={{ flex: 1 }}
                            />
                            {phone &&
                                <FaRegCopy
                                    style={{
                                        cursor: "pointer"
                                    }}
                                    size={20}
                                    color='#84A1C8'
                                    onClick={copyPhone}
                                />
                            }
                        </div>
                    </div>
                    <hr style={{
                        gridColumn: "span 3/span 3"
                    }} />
                    <div style={{
                        gridColumn: "span 3/span 3",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr"
                    }}>
                        <div>
                            <p onClick={() => {
                                setOpenModal(true)
                            }}
                                className="date_label"
                            >Appointment Date</p>
                        </div>
                        {appointmentDateTime ?
                            <p>{new Date(appointmentDateTime).toLocaleDateString() + " " + new Date(appointmentDateTime).toLocaleTimeString()}</p>
                            :
                            <p>No Appointment Date</p>
                        }
                        {
                            fidiumCustomer &&
                            <>
                                <div>
                                    <p
                                        onClick={() => {
                                            setOpenInstallTimeModal(true)
                                        }}
                                        className="date_label"
                                    >
                                        {
                                            lead?.fidiumCustomer?.installDateTime ? "Request Install Reschedule" : "Schedule Install"
                                        }
                                    </p>
                                </div>
                                {lead?.fidiumCustomer?.installDateTime && <p>Current Install Date: {new Date(lead?.fidiumCustomer?.installDateTime).toLocaleDateString() + " " + new Date(lead?.fidiumCustomer?.installDateTime).toLocaleTimeString()}</p>}
                            </>
                        }
                    </div>
                </div>
                <Modal height={"80vh"} minHeight={"80vh"} open={modalOpen} setOpen={setModalOpen} >
                    <CreateCustomer fiberHouse={fiberHouse} lead={lead} setOpen={setModalOpen} setIndex={setIndex}
                        searchFiberHouses={searchFiberHouses} user={user} HotLeadForm={false} />
                </Modal>
            </div>
        </>
    )
}

export default LeadData