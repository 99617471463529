import React, { useEffect, useState } from 'react';
import Layout from '../../../Layouts/Layout';
import './index.scss';
import Datatable from '../../../components/Datatable';
import CircularProgress from '@mui/material/CircularProgress';
import { PaginationItem } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi';
import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { ref, getDownloadURL } from 'firebase/storage';
import { getStorage } from 'firebase/storage';
import BugTicketReq from '../../../Requests/Users/BugTicketReq';
import UsersApi from '../../../API/UsersApi';
import StringIntReq from '../../../Requests/StringIntReq';

const ManageBugTickets = () => {
  const [bugTickets, setBugTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isResolved, setIsResolved] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userName, setUserName] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const storage = getStorage();

  const getBugTickets = async () => {
    setLoading(true);
    const req = new BugTicketReq();
    req.title = title;
    req.description = description;
    req.userType = userType;
    req.userName = userName;
    if (isResolved !== "") {
      req.isResolved = isResolved;
    }
    req.pagingParams = {
      pageNumber: currentPage,
      pageSize: 10,
    };

    const res = await UsersApi.SearchBugTickets(req);
    if (res?.status?.success) {
      setTotalPages(res.data.totalPages);

      const list = res?.data?.list.map((ticket) => ({
        ...ticket,
        userType: ticket?.user?.userType,
        reporter: ticket.user ? ticket.user.firstName + ' ' + ticket.user.lastName : null,
        viewImage: ticket.image ? (
          <button
            style={{
              color: 'white',
              backgroundColor: '#41B6E6',
              padding: '0.5rem 1rem',
              borderRadius: '10px',
            }}
            onClick={() => viewImage(ticket.image)}
          >
            View Image
          </button>
        ) : (
          <span>No Image</span>
        ),
        isResolvedIcon: ticket.isResolved ? (
          <AiOutlineCheck style={{ color: 'green', fontSize: '1.5rem' }} />
        ) : (
          <AiOutlineClose style={{ color: 'red', fontSize: '1.5rem' }} />
        ),
        resolve: !ticket.isResolved ? (
          <button
            style={{
              backgroundColor: 'green',
              color: 'white',
              padding: '0.5rem 1rem',
              borderRadius: '10px',
            }}
            onClick={() => resolveBugTicket(ticket.id)}
          >
            Resolve
          </button>
        ) : null,
      }));

      setBugTickets(list);
      console.log(list)
      setLoading(false);
    } else {
      console.log(res?.status?.message);
    }
  };

  const viewImage = (image) => {
    if (image?.path) {
      const fileRef = ref(storage, image.path);
      getDownloadURL(fileRef)
        .then((url) => {
          Swal.fire({
            title: image.caption || 'Bug Image',
            imageUrl: url,
            imageWidth: 400,
            imageHeight: 300,
            imageAlt: 'Bug Image',
          });
        })
        .catch((error) => {
          console.error('Error fetching image URL:', error);
        });
    }
  };

  useEffect(() => {
    getBugTickets();
  }, [currentPage]);

  async function resolveBugTicket(id) {
    const selected = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: 'Yes, resolve it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });

    if (selected.isConfirmed) {
      Swal.fire({
        icon: 'info',
        title: 'Resolving...',
        text: 'Please wait',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let req = new StringIntReq();
      req.int = id;

      const res = await UsersApi.ResolveBugTicket(req);
      if (res?.status?.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Bug ticket resolved successfully',
          showConfirmButton: false,
          timer: 1500,
        });
        getBugTickets();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: res?.status?.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      getBugTickets();
    }
};
  const headers = [
    { key: 'id', name: 'ID' },
    { key: 'title', name: 'Title' },
    { key: 'description', name: 'Description' },
    { key: 'userType', name: 'User' },
    { key: 'reporter', name: 'Reported By' },
    { key: 'viewImage', name: 'View Image' },
    { key: 'isResolvedIcon', name: 'Is Resolved' },
    { key: 'resolve', name: 'Action' },
  ];

  return (
    <Layout>
      <div className="manageLeadsContainer">
        <div className="manageLeadsHeader">
          <h1 className="manageLeadsTitle">Manage Bug Tickets</h1>
          <div className="searchFiltersInput">
            <input
              type="text"
              placeholder="Title"
              value={title}
              onKeyDown={handleKeyPress}
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              type="text"
              placeholder="Description"
              value={description}
              onKeyDown={handleKeyPress}
              onChange={(e) => setDescription(e.target.value)}
            />
            <input
              type="text"
              placeholder="User Name"
              value={userName}
              onKeyDown={handleKeyPress}
              onChange={(e) => setUserName(e.target.value)}
            />
            <select
              value={isResolved === null ? "" : isResolved.toString()}
              onChange={(e) => {
                const value = e.target.value;
                setIsResolved(value === "" ? null : value === "true");
              }}
            >
              <option value="" disabled>Status</option>
              <option value="">All</option>
              <option value="true">Resolved</option>
              <option value="false">Unresolved</option>
            </select>
            <select
              value={userType === null ? "" : userType.toString()}
              onChange={(e) => {
                const value = e.target.value;
                setUserType(value === "" ? null : value);
              }}
            >
              <option value="" disabled>UserType</option>
              <option value="">All</option>
              <option value="Customer">Customer</option>
              <option value="SuperAdmin">SuperAdmin</option>
              <option value="Admin">Admin</option>
              <option value="SalesRep">Sales Rep</option>
              <option value="SalesOrgAdmin">Sales Org Admin</option>
            </select>
          </div>
          <button
            onClick={() => {
              setCurrentPage(1);
              getBugTickets();
            }}
          >
            Search
          </button>
        </div>

        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '4rem',
            }}
          >
            <CircularProgress sx={{ color: 'var(--color-icon)' }} size={50} />
          </div>
        ) : (
          <Datatable headers={headers} list={bugTickets} />
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1rem ',
          }}
        >
          <Pagination
            color="light"
            renderItem={(item) => (
              <PaginationItem
                components={{
                  next: (props) => (
                    <button {...props}>
                      <BiRightArrow color="var(--color-icon)" />
                    </button>
                  ),
                  first: (props) => <button {...props}>First</button>,
                  previous: (props) => (
                    <button {...props}>
                      <BiLeftArrow color="var(--color-icon)" />
                    </button>
                  ),
                }}
                {...item}
                sx={{ color: 'white' }}
              />
            )}
            count={totalPages}
            onChange={(e, pageNo) => {
              setCurrentPage(pageNo);
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ManageBugTickets;
