/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../../Layouts/Layout'
import { useLocation, useNavigate } from 'react-router-dom'
import StringIntReq from '../../../Requests/StringIntReq';
import ComissionApi from '../../../API/ComissionApi';
import './UserCommission.scss'
import { Accordion, AccordionDetails, CircularProgress } from '@mui/material';
import { AccordionSummary as MuiAccordionSummary } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { IoMdArrowDropup } from 'react-icons/io';
import Swal from 'sweetalert2';
import BulkPayCommissionTrackersReq from '../../../Requests/ComissionPlan/BulkPayCommissionTrackersReq';
import { UserContext } from '../../../App';
import { IoArrowBack } from 'react-icons/io5';


const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<IoMdArrowDropup color='black' size={20} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, 0)',
    border: "none",
    borderBottom: '1px solid black',
    boxShadow: 'none',
    flexDirection: 'row-reverse',
    padding: "0px",
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

const Index = () => {

    const { state } = useLocation();
    const [commissionTrackers, setCommissionTrackers] = useState([]);
    const [deductions, setDeductions] = useState([]);
    // const [commissionPlan, setCommissionPlan] = useState([])
    // const [sales, setSales] = useState([])
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [totalCustomers, setTotalCustomers] = useState(0)
    const [fiberCompanies, setFiberCompanies] = useState([]);
    const user = useContext(UserContext)
    const fetchData = async () => {
        setLoading(true)
        console.log(state)
        const req = new StringIntReq()
        if (state) {
            req.int = state.userId
        }
        else {
            req.int = user.id
        }
        req.pagingParams.pageNumber = 1
        req.pagingParams.pageSize = 20
        const res = await ComissionApi.GetUserCommissionTrackers(req);
        if (res?.status?.success) {
            // setCommissionTrackers(res.data)
            console.log(res.data)
            // setTotalCustomers(res.data.length)
            // const commissionPlan = [...new Set(res.data.map(item => item.commissionPlanId))]
            // setCommissionPlan(commissionPlan)
            // const sales = []
            // res.data.forEach(item => {
            //     if (!sales.find(item2 => item2.saleId === item.saleId))
            //         sales.push({ saleId: item.saleId, commissionPlanId: item.commissionPlanId })
            // })
            // setSales(sales)
            // console.log(sales)
            console.log(res.data)
            // const groupedData = res.data.reduce((acc, item) => {
            //     const commissionKey = item.commissionPlanId;

            //     if (!acc[commissionKey]) {
            //         acc[commissionKey] = {};
            //     }

            //     const saleKey = item.saleId;

            //     if (!acc[commissionKey][saleKey]) {
            //         acc[commissionKey][saleKey] = [];
            //     }

            //     acc[commissionKey][saleKey].push(item);

            //     return acc;
            // }, {});
            let fiberCompany = [];
            const groupedData = res.data.reduce((acc, item) => {
                const commissionKey = item.commissionPlan.name;
                const saleKey = item.saleId;
                const weekRangeKey = item.weekRange;

                if (!acc[commissionKey]) {
                    acc[commissionKey] = {};
                    fiberCompany.push(item.sale.fiberPlanString?.split(' ')[0])
                }


                if (!acc[commissionKey][weekRangeKey]) {
                    acc[commissionKey][weekRangeKey] = {};
                }


                if (!acc[commissionKey][weekRangeKey][saleKey]) {
                    acc[commissionKey][weekRangeKey][saleKey] = [];
                }
                if (item.type !== "Deduction") {
                    acc[commissionKey][weekRangeKey][saleKey].push(item);
                }

                return acc;
            }, {});
            console.log(groupedData);
            setFiberCompanies(fiberCompany)
            const reductions = res?.data?.filter(item => item.type === "Deductions")
            setDeductions(reductions);

            setCommissionTrackers(groupedData)
            let totalSales = Object.keys(groupedData).reduce((acc, commissionPlanId) => {
                return acc + Object.keys(groupedData[commissionPlanId]).reduce((acc2, weekRangeKey) => {
                    return acc2 + Object.keys(groupedData[commissionPlanId][weekRangeKey]).length
                }, 0)
            }, 0)
            setTotalCustomers(totalSales)
            setLoading(false)
        }
    }

    // useEffect(
    //     ()=>{
    //         groupUniqueCommissionPlans(commissionTrackers)
    //         groupUniqueSales(commissionTrackers)
    //     },[commissionTrackers]
    // )

    // const groupUniqueCommissionPlans = (commissionTrackers) => {
    //     const commissionPlanId = commissionTrackers
    //     console.log(commissionPlanId)
    //     setCommissionPlan(commissionPlanId)

    // }

    // const groupUniqueSales = (commissionTrackers) => {
    //     const sales = Object.groupBy(commissionTrackers, ({ saleId, commissionPlanId }) => { return { saleId: saleId, commissionPlanId: commissionPlanId } })
    //     setSales(sales)
    // }

    useEffect(
        () => {
            fetchData()
        }, []
    )

    const payBulkCommissionTracker = async (ids,dict) => {
        const selected = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: "You want to pay all commission trackers",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        })
        if (selected.isConfirmed) {
            Swal.fire({
                icon: 'info',
                title: 'Please wait',
                text: 'Processing your request',
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: false,
                didOpen: async () => {
                    Swal.showLoading()
                }
            })
            const req = new BulkPayCommissionTrackersReq();
            req.commissionTrackerIds = ids;
            req.amounts = dict;
            const res = await ComissionApi.BulkPayCommissionTrackers(req)
            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Commission trackers paid successfully',
                    showConfirmButton: false,
                    showCloseButton: true,
                    showCancelButton: false,
                    didOpen: () => {
                        Swal.hideLoading()
                    },
                    didClose: () => {
                        fetchData()
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res?.status?.message,
                    showConfirmButton: true,
                    showCloseButton: true,
                    showCancelButton: false,
                })
            }
        }
    }

    const payCommissionTracker = async (id) => {
        const selected = await Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: "You want to pay this commission tracker",
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        })

        if (selected.isConfirmed) {
            Swal.fire({
                icon: 'info',
                title: 'Please wait',
                text: 'Processing your request',
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: false,
                didOpen: async () => {
                    Swal.showLoading()
                }
            })
            const req = new StringIntReq()
            req.int = id;
            const res = await ComissionApi.PayCommissionTracker(req)
            if (res?.status?.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Commission tracker paid successfully',
                    showConfirmButton: false,
                    showCloseButton: true,
                    showCancelButton: false,
                    didOpen: () => {
                        Swal.hideLoading()
                    },
                    didClose: () => {
                        fetchData()
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res?.status?.message,
                    showConfirmButton: true,
                    showCloseButton: true,
                    showCancelButton: false,
                })
            }
        }

    }


    return (
        <Layout>
            <IoArrowBack size={30} color='black' style={{ cursor: 'pointer',marginLeft:"1rem" }} onClick={() => {navigate(-1)}} />
            <div className='user_copmmission'>
                {/* <div className='search_filter'>

                </div> */}
                {
                    state ?
                        <h1>{state.name} Commissions</h1>
                        :
                        <h1>Your Commissions</h1>
                }

                <div className='commission_stats' >
                    <div className='total'>
                        <h3>Total Commission</h3>
                        <p>
                            $
                            {
                                Object.keys(commissionTrackers).reduce((acc, commissionPlanId) => {
                                    return acc + Object.keys(commissionTrackers[commissionPlanId]).reduce((acc2, weekRangeKey) => {
                                        return acc2 + Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).reduce((acc3, saleId) => {
                                            return acc3 + commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc4, item) => {
                                                return acc4 + item.amount
                                            }, 0)
                                        }, 0)
                                    }, 0)
                                }, 0)

                            }
                        </p>
                    </div>
                    <div className='total'>
                        <h3>Paid</h3>
                        <p>
                            $
                            {
                                Object.keys(commissionTrackers).reduce((acc, commissionPlanId) => {
                                    return acc + Object.keys(commissionTrackers[commissionPlanId]).reduce((acc2, weekRangeKey) => {
                                        return acc2 + Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).reduce((acc3, saleId) => {
                                            return acc3 + commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc4, item) => {
                                                return acc4 + (item.paid ? item.amount
                                                    - item.deductionTrackers.reduce((acc5, item) => {
                                                        return acc5 + item.amount
                                                    }, 0)
                                                    - item.accountingEntries.reduce((acc6, item) => {
                                                        return acc6 + item.amount
                                                    }, 0) : 0)
                                            }, 0)
                                        }, 0)
                                    }, 0)
                                }, 0)
                            }

                        </p>
                    </div>
                    <div className='total'>
                        <h3>Pending</h3>
                        <p>
                            $
                            {
                                Object.keys(commissionTrackers).reduce((acc, commissionPlanId) => {
                                    return acc + Object.keys(commissionTrackers[commissionPlanId]).reduce((acc2, weekRangeKey) => {
                                        return acc2 + Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).reduce((acc3, saleId) => {
                                            return acc3 + commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc4, item) => {
                                                return acc4 + (item.paid ? 0 : item.amount- item.deductionTrackers.reduce((acc5, item) => {
                                                    return acc5 + item.amount
                                                }
                                                , 0)
                                                - item.accountingEntries.reduce((acc6, item) => {
                                                    return acc6 + item.amount
                                                },0))
                                            }, 0)
                                        }, 0)
                                    }, 0)
                                }, 0)
                            }

                        </p>
                    </div>
                    <div className='total'>
                        <h3>Deducted</h3>
                        <p>
                            $
                            {
                                Object.keys(commissionTrackers).reduce((acc, commissionPlanId) => {
                                    return acc + Object.keys(commissionTrackers[commissionPlanId]).reduce((acc2, weekRangeKey) => {
                                        return acc2 + Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).reduce((acc3, saleId) => {
                                            return acc3 + commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc4, item) => {
                                                return acc4 + (item.paid ? 0 : item.deductionTrackers.reduce((acc5, item) => {
                                                    return acc5 + item.amount
                                                }
                                                , 0)
                                                )
                                            }, 0)
                                        }, 0)
                                    }, 0)
                                }, 0)
                            }

                        </p>
                    </div>
                    {user.userType === "SuperAdmin" && <button onClick={() => {
                        // payBulkCommissionTracker(commissionTrackers.filter(item => !item.paid).map(item => item.id))\
                        const ids = Object.keys(commissionTrackers).reduce((acc, commissionPlanId) => {
                            return acc.concat(Object.keys(commissionTrackers[commissionPlanId]).reduce((acc2, weekRangeKey) => {
                                return acc2.concat(Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).reduce((acc3, saleId) => {
                                    return acc3.concat(commissionTrackers[commissionPlanId][weekRangeKey][saleId].filter(item => !item.paid).map(item => item.id))
                                }, []))
                            }, []))
                        }, [])
                        
                        let dict = {}
                        Object.keys(commissionTrackers).map((commissionPlanId, index) => {
                            Object.keys(commissionTrackers[commissionPlanId]).map((weekRangeKey, index) => {
                                Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).map((saleId, index) => {
                                    commissionTrackers[commissionPlanId][weekRangeKey][saleId].map((item, index) => {
                                        if (!item.paid) {
                                            dict[item.id] = item.amount - item.deductionTrackers.reduce((acc, item) => {
                                                return acc + item.amount
                                            }, 0) - item.accountingEntries.reduce((acc, item) => {
                                                return acc + item.amount
                                            }, 0)
                                        }
                                    })
                                })
                            })
                        })

                        // payBulkCommissionTracker(ids,dict);
                        console.log(ids,dict)

                    }}>Pay All</button>}
                </div>
                {
                    loading ?
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",
                            marginTop: '4rem'
                        }}>
                            <CircularProgress
                                sx={{
                                    color: "var(--color-primary)",
                                    margin: "auto"
                                }}
                            />
                        </div>
                        :
                        Object.keys(commissionTrackers).length === 0 ?
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                width: "100%",
                                marginTop: '4rem'
                            }}>
                                <h1>No Commissions To Show</h1>
                            </div>
                            :
                            <div className='commission_plan'>
                                <h1>Total Customers: {totalCustomers}</h1>
                                {
                                    Object.keys(commissionTrackers).map((commissionPlanId, index) => {
                                        const commissionPayAmount = Object.keys(commissionTrackers[commissionPlanId]).reduce((acc, weekRangeKey) => {
                                            return acc + Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).reduce((acc2, saleId) => {
                                                return acc2 + commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc3, item) => {
                                                    return acc3 + (item.paid ? 0 : item.amount - item.deductionTrackers.reduce((acc4, item) => {
                                                        return acc4 + item.amount
                                                    }, 0)
                                                        - item.accountingEntries.reduce((acc5, item) => {
                                                            return acc5 + item.amount
                                                        }, 0)
                                                    )
                                                }, 0)
                                            }, 0)
                                        }, 0)


                                        let idsInCommission = [];
                                        let commissionObject = {};
                                        // const companyName = commissionTrackers[commissionPlanId][Object.keys(commissionTrackers[commissionPlanId])[0]][Object.keys(Object.keys(commissionTrackers[commissionPlanId])[0])[0]][0].fiberCompany.name
                                        return (
                                            <Accordion key={index}>
                                                <AccordionSummary expandIcon={<IoMdArrowDropup color='black' size={30} />} >
                                                    <div>
                                                        <h3>Commission Plan: {commissionPlanId}</h3>
                                                        <p>Fiber Company: {fiberCompanies[index]}</p>
                                                    </div>
                                                    {user.userType === "SuperAdmin" && (commissionPayAmount > 0 ?
                                                        <button onClick={() => {
                                                            console.log(idsInCommission, commissionObject)
                                                            payBulkCommissionTracker(idsInCommission, commissionObject)
                                                            // payBulkCommissionTracker(ids)
                                                        }}>Pay ${commissionPayAmount}</button> : <p className='paid'>Paid</p>)}
                                                </AccordionSummary>
                                                <AccordionDetails sx={{
                                                    marginLeft: "20px"
                                                }}>
                                                    <div>
                                                        {
                                                            Object.keys(commissionTrackers[commissionPlanId]).map((weekRangeKey, index) => {


                                                                const salesPayAmount = Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).reduce((acc, saleId) => {
                                                                    return acc + commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc2, item) => {
                                                                        return acc2 + (item.paid ? 0 : item.amount - item.deductionTrackers.reduce((acc3, item) => {
                                                                            return acc3 + item.amount
                                                                        }, 0)
                                                                            - item.accountingEntries.reduce((acc4, item) => {
                                                                                return acc4 + item.amount
                                                                            }, 0)
                                                                        )
                                                                    }, 0)
                                                                }, 0)
                                                                let idsInWeekRange = [];
                                                                let weekRangeObject = {};
                                                                const noOfSales = Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).length
                                                                return (
                                                                    <Accordion key={index}>
                                                                        <AccordionSummary>
                                                                            <div>
                                                                                <h3>Week Range: {new Date(weekRangeKey.split("-")[0].trim()).toDateString()} - {new Date(weekRangeKey.split("-")[1].trim()).toDateString()}</h3>
                                                                                <p>Customers : {noOfSales}</p>
                                                                            </div>
                                                                            {user.userType === "SuperAdmin" && (salesPayAmount > 0 ?
                                                                                <button onClick={() => {
                                                                                    console.log(idsInWeekRange, weekRangeObject)
                                                                                    payBulkCommissionTracker(idsInWeekRange, weekRangeObject)
                                                                                }}>Pay ${salesPayAmount}</button> : <p className='paid'>Paid</p>)}

                                                                        </AccordionSummary>

                                                                        <AccordionDetails sx={{
                                                                            marginLeft: "20px"
                                                                        }}>
                                                                            <div>
                                                                                {
                                                                                    Object.keys(commissionTrackers[commissionPlanId][weekRangeKey]).map((saleId, index) => {
                                                                                        const customerName = commissionTrackers[commissionPlanId][weekRangeKey][saleId][0].sale.firstName + " " + commissionTrackers[commissionPlanId][weekRangeKey][saleId][0].sale.lastName
                                                                                        const fiberPlan = commissionTrackers[commissionPlanId][weekRangeKey][saleId][0].sale.fiberPlanString

                                                                                        let idsInSale = [];
                                                                                        let saleObject = {};

                                                                                        const amountToBePaid = commissionTrackers[commissionPlanId][weekRangeKey][saleId].reduce((acc, item) => {
                                                                                            return acc + (item.paid ? 0 : item.amount
                                                                                                - item.deductionTrackers.reduce((acc2, item) => {
                                                                                                    return acc2 + item.amount
                                                                                                }, 0)
                                                                                                - item.accountingEntries.reduce((acc3, item) => {
                                                                                                    return acc3 + item.amount
                                                                                                }, 0)
                                                                                            )
                                                                                        }
                                                                                            , 0)

                                                                                        return (
                                                                                            <Accordion key={index}>
                                                                                                <AccordionSummary>
                                                                                                    <div>
                                                                                                        <h3>Customer: {customerName}</h3>
                                                                                                        <p>Fiber Plan: {fiberPlan}</p>
                                                                                                    </div>
                                                                                                    {user.userType === "SuperAdmin" && amountToBePaid > 0 ?
                                                                                                        <button onClick={() => {
                                                                                                            console.log(idsInSale, saleObject)
                                                                                                            payBulkCommissionTracker(idsInSale, saleObject)
                                                                                                            // payBulkCommissionTracker(ids)
                                                                                                        }}>Pay ${amountToBePaid}</button> : <p className='paid'>Paid</p>
                                                                                                    }
                                                                                                </AccordionSummary>
                                                                                                <AccordionDetails sx={{
                                                                                                    marginLeft: "20px"
                                                                                                }} >
                                                                                                    <div>
                                                                                                        {
                                                                                                            commissionTrackers[commissionPlanId][weekRangeKey][saleId].map((item, index) => {

                                                                                                                const payableAmount = item.amount - item.deductionTrackers.reduce((acc, item) => {
                                                                                                                    return acc + item.amount
                                                                                                                }, 0) - item.accountingEntries.reduce((acc, item) => {
                                                                                                                    return acc + item.amount
                                                                                                                }, 0)

                                                                                                                // store ids for bulk commission payment
                                                                                                                idsInCommission.push(item.id)
                                                                                                                commissionObject[item.id] = payableAmount;
                                                                                                                // store ids for bulk week range payment
                                                                                                                idsInWeekRange.push(item.id)
                                                                                                                weekRangeObject[item.id] = payableAmount;
                                                                                                                // store ids for bulk sale payment
                                                                                                                idsInSale.push(item.id)
                                                                                                                saleObject[item.id] = payableAmount;



                                                                                                                return (
                                                                                                                    <Accordion key={index}>
                                                                                                                        <AccordionSummary>
                                                                                                                            <div style={{
                                                                                                                                padding: "0rem",
                                                                                                                                border: "none"
                                                                                                                            }} className='commission_tracker' key={index}>
                                                                                                                                <div>
                                                                                                                                    <h3>{item.type} {item.commissionScale}</h3>
                                                                                                                                    <p>Amount: ${payableAmount}</p>
                                                                                                                                    <p>Status: {item.paid ? 'Paid' : 'Pending'}</p>
                                                                                                                                </div>
                                                                                                                                {user.userType === "SuperAdmin" && !item.paid && <button onClick={() => {
                                                                                                                                    // payCommissionTracker(item.id)
                                                                                                                                    payBulkCommissionTracker([item.id], { [item.id]: payableAmount })
                                                                                                                                    console.log(item.id, payableAmount)
                                                                                                                                }}>Pay</button>}
                                                                                                                            </div>
                                                                                                                        </AccordionSummary>
                                                                                                                        <AccordionDetails sx={{
                                                                                                                            marginLeft: "20px"
                                                                                                                        }}>
                                                                                                                            {
                                                                                                                                item.deductionTrackers.map((deduction, index) => {
                                                                                                                                    return (
                                                                                                                                        <div key={index} >
                                                                                                                                            <h3 style={{
                                                                                                                                                color: "red",
                                                                                                                                                fontWeight: "bold"
                                                                                                                                            }}>{deduction.deductionReason}</h3>
                                                                                                                                            <p style={{
                                                                                                                                                color: "red",
                                                                                                                                                fontWeight: "bold"
                                                                                                                                            }}>Amount: ${deduction.amount}-</p>
                                                                                                                                            {/* <p>Status: {deduction.paid ? 'Paid' : 'Pending'}</p> */}
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }
                                                                                                                        </AccordionDetails>
                                                                                                                    </Accordion>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                </AccordionDetails>
                                                                                            </Accordion>
                                                                                        )
                                                                                    }
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </AccordionDetails>


                                                                    </Accordion>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    })
                                }

                            </div>
                }
            </div>
        </Layout>
    )
}

export default Index