/* eslint-disable array-callback-return */
import React, { useContext, useEffect } from 'react'
// import UsersApi from '../../../API/UsersApi.js'
import { MenuItemsContext, UserContext } from '../../../App'
// import GenerateAffiliateCodeReq from '../../../Requests/ReferralCode/GenerateAffiliateCodeReq.js'
import DashboardCard from './components/DashboardCard.js'
import './index.scss'
import Layout from '../../../Layouts/Layout.js'
const Index = () => {

    const user = useContext(UserContext)
    // const [userData, setUserData] = React.useState(null)
    // const [loaded, setLoaded] = React.useState(true)
    const menuItems = useContext(MenuItemsContext)
    const [search, setSearch] = React.useState('')
    const [themeMode, setThemeMode] = React.useState(localStorage.getItem('theme'+user?.id));
    // console.log('menuItems', menuItems)


    
    useEffect(() => {
        const handleThemeChange  = () => {
            setThemeMode(localStorage.getItem('theme' + user?.id));
        };

        window.addEventListener('themeChange', handleThemeChange);

        return () => {
            window.removeEventListener('themeChange', handleThemeChange);
        };
    }, [user]);

    return (
        <Layout>
            { (
                <div className='customerDashboard'>
                    {themeMode === 'light' &&
                        <div className='dashboardImage'>

                            <img alt='Logo' src="/images/nav/nav-logo.png" />

                        </div>
                    } 
                    {themeMode === 'dark' &&
                            <div className='dashboardImage'>

                                <img alt='Logo' src="/images/nav/dark-nav-logo.png" />
                    
                            </div>
                    }
                    <div className='dashboardSearch'>
                        <input onChange={(e)=>{setSearch(e.target.value)}} value={search} type='text' placeholder='Search' />
                        <div className='dashboardSearchIcon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="31" viewBox="0 0 21 31" fill="none">
                                <path d="M19.5703 11.8133L12.4511 10.657L16.2984 3.57675C16.6879 2.73772 16.1194 1.75496 15.1508 1.59765L6.26723 0.154853C5.52693 0.0346198 4.81407 0.447161 4.60359 1.11938L0.375082 14.6794C0.122605 15.4932 0.693318 16.3457 1.58785 16.491L8.91065 17.6803L4.23968 28.4675C3.87481 29.3105 4.45611 30.2538 5.39999 30.4071C5.9182 30.4913 6.45307 30.3169 6.7959 29.9215L20.5092 14.1028C21.2323 13.2764 20.7116 11.9986 19.5703 11.8133Z" fill="#2761CF" />
                            </svg>
                        </div>
                    </div>
                    <div className='dashboardBody'>
                        {
                            menuItems.map((item, index) => {
                                if(item.name.toLowerCase().includes(search.toLowerCase()))
                                return (
                                    <DashboardCard key={index} item={item} user={user} />
                                )
                            })
                        }
                    </div>
                </div>
            )}
        </Layout>
    )
}

export default Index