import { Delete } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Pagination from '@mui/material/Pagination'
import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import OrgApi from '../../../API/OrgApi'
import UsersApi from '../../../API/UsersApi'
import Sidebar from '../../../Layouts/Sidebar'
import RemoveMemberOrgReq from '../../../Requests/Organizations/RemoveMemberOrgReq'
import SearchUserReq from '../../../Requests/Users/SearchUserReq'
import Datatable from '../../../components/Datatable'
import { UserContext } from "./../../../App"
import './index.scss'
import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import { PaginationItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
const Index = () => {
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [userType, setUserType] = useState("");
    const [loading, setLoading] = useState(false)
    const user = useContext(UserContext)
    const navigate = useNavigate()
    const headers = [
        {
            key: "id",
            name: "ID"
        },
        {
            key: "firstName",
            name: "First Name"
        },
        {
            key: "lastName",
            name: "Last Name"
        },
        {
            key: "email",
            name: "Email Address"
        },
        {
            key: "phoneNumber",
            name: "Phone Number"
        },
        {
            key: "address",
            name: "Address"
        },
        {
            key: "userType",
            name: "User Type"
        },
        {
            key: "status",
            name: "Status"
        },
        {
            key: "commissionDetails",
            name: "Commission Details"
        },
        {
            key: "RemoveMember",
            name: "Remove Member"
        }
    ]

    function deleteUsers(id) {
        Swal.fire(
            {
                title: 'Are you sure?',
                text: `You want to Delete a member`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: "Yes, I'm Sure!",
                didClose: () => {
                    Swal.showLoading();
                }
            }
        ).then(
            (result) => {
                if (result.isConfirmed) {
                    let req = new RemoveMemberOrgReq();

                    req.int = id
                    OrgApi.RemoveUsersFromOrg(req).then(
                        (res) => {
                            if (res?.status?.success) {
                                
                                Swal.fire(
                                    {
                                        title: "Member Removed",
                                        text: "Member has been removed",
                                        icon: "success",
                                        didOpen: () => {
                                            Swal.hideLoading()
                                        }
                                    }

                                )
                                fetchData()
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: res?.status?.message,
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showConfirmButton: true,
                                    didOpen: () => {
                                        Swal.hideLoading()
                                    }
                                });
                            }
                        }
                    )
                }
            }
        )
    }
    function fetchData() {
        const req = new SearchUserReq()
        setLoading(true)
        req.pagingParams.pageNumber = currentPage;
        req.pagingParams.pageSize = 10;
        if (firstName !== "") {
            req.firstName = firstName
        }
        if (lastName !== "") {
            req.lastName = lastName
        }
        if (email !== "") {
            req.email = email
        }
        if (phone !== "") {
            req.phoneNumber = phone
        }
        if (address !== "") {
            req.address = address
        }

        req.userType = 'SalesRep'
        
        req.salesOrgId = user.salesOrgId
        UsersApi.SearchUser(req).then((res) => {
            if (res?.status?.success) {
                


                let userTemp = []
                res?.data?.list.map(
                    (user, index) => {
                        userTemp.push({
                            ...user, RemoveMember: <IconButton onClick={() => { deleteUsers(user.id) }} sx={{
                                "&:hover": {
                                    color: "red"
                                }
                            }} ><Delete /></IconButton>,
                             commissionDetails: <button
                             style={{
                                    backgroundColor: "var(--color-primary)",
                                    color: "white",
                                    border: "none",
                                    padding: "0.2rem 0.7rem",
                                    borderRadius: "5px",
                                    cursor: "pointer"
                                
                             }}
                             onClick={()=>{
                                navigate('/user-commissions',{
                                    state :{
                                        userId: user.id,
                                        name : user.firstName + " " + user.lastName,
                                    }
                                })
                            }}>
                                Commission Details
                            </button>
                        })
                    }
                )

                setUsers(userTemp)
                if (currentPage > res.data.totalPages) {
                    setCurrentPage(res.data.totalPages)
                }
                setTotalPages(res.data.totalPages)
                setLoading(false)
            }

        })
    }

    useEffect(
        () => {
            fetchData()
        }, [currentPage]
    )

    return (
        <Layout>
            <div className='manageLeadsContainer'>
                <div className='manageLeadsHeader'>
                    <h1 className='manageLeadsTitle'>Manage Team Members</h1>
                    <div className='searchFiltersInput'>
                        <input onChange={(e) => { setFirstName(e.target.value) }} value={firstName} type='text' placeholder='FIRST NAME' />
                        <input onChange={(e) => { setLastName(e.target.value) }} value={lastName} type='text' placeholder='LAST NAME' />
                        <input onChange={(e) => { setPhone(e.target.value) }} value={phone} type='tel' placeholder='PHONE' />
                        <input onChange={(e) => { setEmail(e.target.value) }} value={email} type="email" placeholder='EMAIL' />
                        <input className='single' onChange={(e) => { setAddress(e.target.value) }} value={address} type="text" placeholder='ADDRESS' />
                        {/* <select value={userType} onChange={(e) => { setUserType(e.target.value) }}>
                            <option value={""}>USER TYPE</option>
                            <option value={"SuperAdmin"}>Super Admin</option>
                            <option value={"Admin"}>Admin</option>
                            <option value={"SalesOrgAdmin"}>Team Admin</option>
                            <option value={"EndUser"}>End User</option>
                            <option value={"SalesRep"}>SalesRep</option>
                        </select> */}
                    </div>
                    <button disabled={loading} onClick={fetchData}>{loading ? "SEARCHING..." : "SEARCH"}</button>
                </div>
                {loading ? <div className='spinner' >
                    <CircularProgress sx={{color:"var(--color-icon)"}} size={50} /></div> : <Datatable currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} headers={headers} list={users ? users : []} />}
                <div className='pagination'>
                    <Pagination color='light' renderItem={(item)=>(
                    <PaginationItem   components={{
           
                        next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                        first: (props) => <button {...props}>First</button>,
                        previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                      }}  {...item} sx={{color: "white"}} />
                )} count={totalPages} onChange={(e, pageNo) => {
                        setCurrentPage(pageNo)
                    }} />
                </div>
            </div>
        </Layout>
    )
}

export default Index