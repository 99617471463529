import React from 'react'
import { Modal } from '@mui/material'
import './index.scss'
import { Link } from 'react-router-dom'
import { IoCloseCircle } from 'react-icons/io5'
const Index = ({ customer, notes, open, setOpen }) => {
    console.log(customer)
    return (
        <Modal sx={{ outline: "none" }} open={open} onClose={() => {
            setOpen(false)
        }}>
            <div className='customer_info_model'>
                <IoCloseCircle onClick={() => {
                    setOpen(false)
                }} size={30} style={{ marginLeft: "auto", cursor: "pointer" }} color='#2761d0' />
                <h2>Customer Info</h2>
                <div className='customer_info'>
                    <h3 className='single'>Personal Information</h3>
                    <div className='info'>
                        <h4>Name</h4>
                        <p>{customer?.firstName + " " + customer?.lastName}</p>
                    </div>
                    <div style={{
                        alignItems: 'flex-start'
                    }} className='info single'>
                        <h4>Address</h4>
                        <p>{customer?.address}</p>
                    </div>
                    <div className='info'>
                        <h4>Email</h4>
                        <p>{customer?.email}</p>
                    </div>
                    <div className='info'>
                        <h4>Phone</h4>
                        <p>{customer?.cellPhone}</p>
                    </div>

                    <h3 className='single'>Installation Information</h3>
                    <div className='info'>
                        <h4>Sales Rep</h4>
                        <p>{customer?.salesRep?.firstName + " " + customer?.salesRep?.lastName}</p>
                    </div>
                    <div className='info'>
                        <h4>Sold At</h4>
                        <p>{new Date(customer?.timeStamp).toLocaleDateString(
                            'en-US',
                            {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            }
                        )}</p>
                    </div>
                    <div className='info'>
                        <h4>Account Status</h4>
                        <p>{customer?.accountStatus}</p>
                    </div>
                    <div className='info'>
                        <h4>Install Date</h4>
                        <p>{
                            customer?.installDateTime ?
                                new Date(customer?.installDateTime).toLocaleDateString(
                                    'en-US',
                                    {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }
                                ) : "Not Scheduled"
                        }</p>
                    </div>
                    <div className='info'>
                        <h4>Internet Plan</h4>
                        <p>{customer?.fiberPlan?.description}</p>
                    </div>
                    <div className='info'>
                        <h4>Rep Notes</h4>
                        <p>{notes ? notes : "No Notes"}</p>
                    </div>
                    <Link to={`/CustomerDetails/${customer?.id}`} target='_blank' className='view_more'>View More</Link>
                </div>
            </div>
        </Modal>
    )
}

export default Index