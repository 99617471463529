import React, { useEffect, useState } from 'react'
import Layout from '../../../Layouts/Layout'
import './index.scss'
import Datatable from '../../../components/Datatable'
import CircularProgress from '@mui/material/CircularProgress'
import { PaginationItem } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import { BiRightArrow, BiLeftArrow } from 'react-icons/bi'
import Swal from 'sweetalert2'
import StringIntReq from '../../../Requests/StringIntReq'
import SearchJobApplicationReq from '../../../Requests/Users/SearchJobApplicationReq'
import UsersApi from '../../../API/UsersApi'
import { ref, deleteObject } from 'firebase/storage'
import { getStorage } from 'firebase/storage'
import { useNavigate } from 'react-router-dom'
const Index = () => {
  const [jobApplications, setJobApplications] = useState([])
  const [loading, setLoading] = useState(true)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [notes, setNotes] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const navigate = useNavigate()
  const storage = getStorage();
  const getJobApplications = async () => {
    setLoading(true)
    const req = new SearchJobApplicationReq()
    req.firstName = firstName
    req.lastName = lastName
    req.email = email
    req.phone = phone
    req.address = address
    req.notes = notes
    console.log(req)
    req.pagingParams = {
      pageNumber: currentPage,
      pageSize: 10
    }
    const res = await UsersApi.SearchJobApplication(req);
    if (res?.status?.success) {
      console.log(res.data)

      setTotalPages(res.data.totalPages)

      const list = [];
      for (let index = 0; index < res?.data?.list.length; index++) {
        list.push(res?.data?.list[index]);
        list[index].details = <button style={{
          color: "white",
          backgroundColor: "#41B6E6",
          padding: "0.5rem 1rem",
          borderRadius: "10px",
        }} onClick={() => { navigate(`/manage-job-applications/details/${res?.data?.list[index].id}`) }} >
          Details
        </button>;
        list[index].delete = <button sx={{
          '&:hover': {
            backgroundColor: 'red',
          },
          backgroundColor: "red",
          color: "white !important",

        }} onClick={() => { deleteJobApplication(res?.data?.list[index].id) }} color="error"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
            <path d="M14 1H10.5L9.5 0H4.5L3.5 1H0V3H14M1 16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H11C11.5304 18 12.0391 17.7893 12.4142 17.4142C12.7893 17.0391 13 16.5304 13 16V5H1V16Z" fill="#FF472E" />
          </svg></button>;

        list[index].notes = <textarea style={{ width: "200px", height: "100px", resize: "none", outline: "none" }} value={res?.data?.list[index].notes} readOnly></textarea>
        list[index].message = <textarea style={{ width: "200px", height: "100px", resize: "none", outline: "none" }} value={res?.data?.list[index].message} readOnly></textarea>
        const date = new Date(res?.data?.list[index].timeStamp);
        const formattedDate = date.toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'long',
          year: 'numeric'
        });


        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });


        list[index].timeStamp = `${formattedDate} ${formattedTime}`;
      }
      setJobApplications(res?.data?.list)
      setLoading(false)
    }
    else {
      console.log(res?.status?.message)
    }
  }

  useEffect(() => {
    getJobApplications()
  }, [currentPage])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      getJobApplications();
    }
};


  async function deleteJobApplication(id) {
    const selected = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });

    if (selected.isConfirmed) {
      Swal.fire({
        icon: "info",
        title: "Deleting...",
        text: "Please wait",
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      const req = new StringIntReq()
      req.int = id
      const deleteFiles = async () => {
        const f = jobApplications.find(j => j.id === id).file;
        const fileRef = ref(storage, f.path);
        try {
          await deleteObject(fileRef);

        } catch (error) {
          console.error('An error occurred!', error);
        }

      };

      if (jobApplications.find(j => j.id === id)?.file)
        await deleteFiles();
      const res = await UsersApi.DeleteJobApplication(req);
      if (res?.status?.success) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Job Application deleted successfully",
          showConfirmButton: false,
          didOpen: () => {
            Swal.hideLoading();
          }
        });
        getJobApplications();
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: res?.status?.message,
          showConfirmButton: false,
          didOpen: () => {
            Swal.hideLoading();
          }
        });

      }
    }
  }
  // 7243
  const headers = [
    {
      key: "timeStamp",
      name: "Submitted At"
    },
    {
      key: "id",
      name: "ID"
    },
    {
      key: "position",
      name: "Position"
    },
    {
      key: "firstName",
      name: "First Name"
    },
    {
      key: "lastName",
      name: "Last Name"
    },
    {
      key: "email",
      name: "Email"
    },
    {
      key: "phone",
      name: "Phone"
    },
    {
      key: "howDidYouHear",
      name: "How did you hear about us?"
    },
    {
      key: "address",
      name: "Address"
    },
    {
      key: "notes",
      name: "Notes"
    },
    {
      key: "status",
      name: "Status"
    },
    {
      key: "message",
      name: "Message"
    },
    {
      key: "details",
      name: "Details"
    },
    {
      key: "delete",
      name: "Delete"
    }
  ]

  return (
    <Layout>
      <div className='manageLeadsContainer'>

        <div className='manageLeadsHeader'>
          <h1 className='manageLeadsTitle'>Manage Job Applications</h1>
          <div className='searchFiltersInput'>
            <input type='text' placeholder='First Name' value={firstName} onKeyDown={handleKeyPress} onChange={e => setFirstName(e.target.value)} />
            <input type='text' placeholder='Last Name' value={lastName} onKeyDown={handleKeyPress} onChange={e => setLastName(e.target.value)} />
            <input type='text' placeholder='Email' value={email} onKeyDown={handleKeyPress} onChange={e => setEmail(e.target.value)} />
            <input type='text' placeholder='Phone' value={phone } onKeyDown={handleKeyPress} onChange={e => setPhone(e.target.value)} />
            <input type='text' placeholder='Address' value={address} onKeyDown={handleKeyPress} onChange={e => setAddress(e.target.value)} />
            <input type='text' placeholder='Notes' value={notes} onKeyDown={handleKeyPress} onChange={e => setNotes(e.target.value)} />
          </div>
          <button onClick={() => {
            setCurrentPage(1)
            getJobApplications()
          }}>Search</button>
        </div>

        {
          loading ?
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "4rem"
            }} >
              <CircularProgress sx={{ color: "var(--color-icon)" }} size={50} />
            </div>
            :
            <Datatable headers={headers} list={jobApplications} />
        }
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem " }}>
          <Pagination color='light' renderItem={(item) => (
            <PaginationItem components={{

              next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
              first: (props) => <button {...props}>First</button>,
              previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
            }}  {...item} sx={{ color: "white" }} />
          )} count={totalPages} onChange={(e, pageNo) => {
            setCurrentPage(pageNo)
          }} />
        </div>

      </div>
    </Layout>
  )
}

export default Index
