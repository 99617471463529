/* eslint-disable react-hooks/exhaustive-deps */
import { toLonLat } from 'ol/proj'
import React, { useEffect } from 'react'
// import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import Swal from 'sweetalert2'
import { MapContext } from '..'
import FiberHouseApi from '../../../../API/FiberHouseApi'
// import MapIconApi from '../../../../API/MapIconApi'
import { UserContext } from '../../../../App'
import CreateFiberHouseReq from '../../../../Requests/FiberHouse/CreateFiberHouseReq'
// import StringIntReq from '../../../../Requests/StringIntReq'
import MapHelper from '../MapHelper'
import { Icon, Style } from 'ol/style'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Point } from 'ol/geom'
import { Feature } from 'ol'


const CreateFiberHouse = () => {

    // const [displayMarker, setDisplayMarker] = React.useState(false)

    const user = React.useContext(UserContext)
    // const [coordinates, setCoordinates] = React.useState(null)
    const { mapObject, setIndex, searchFiberHouses,mapIcons,setFiberHouses } = React.useContext(MapContext)
    const helper = new MapHelper(mapObject)
    const [address, setAddress] = React.useState("")

    // const [markerLayer, setMarkerLayer] = React.useState(null)
    // const [mapIcons, setMapIcons] = React.useState([]);
    const [selectedMapIcon, setSelectedMapIcon] = React.useState(null);

    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])



    //delete display markers when component unmounts
    useEffect(() => {
        return () => {
            // Remove the marker layer by name from the map object without having to keep track of the layer object
            if (mapObject) {
                const layers = mapObject.getLayers();
                layers.forEach((layer) => {
                    if (layer?.get('name') === 'markerLayer') {
                        mapObject.removeLayer(layer);
                    }
                });
            }
        }
    }, [])




    // function getPolygonArea(coordinates) {
    //     const numPoints = coordinates.length;

    //     if (numPoints < 3) {
    //         return 0; // A polygon with less than 3 vertices has no area
    //     }

    //     let area = 0;

    //     for (let i = 0; i < numPoints; i++) {
    //         const xi = coordinates[i].latitude;
    //         const yi = coordinates[i].longitude;

    //         const nextIndex = (i + 1) % numPoints;
    //         const xj = coordinates[nextIndex].latitude;
    //         const yj = coordinates[nextIndex].longitude;

    //         area += xi * yj - xj * yi;
    //     }

    //     area = Math.abs(area) / 2;

    //     return area;
    // }

    // function getSmallestServicableArea(servicableAreas) {
    //     if (servicableAreas.length === 0) {
    //         return null; // Return null or handle empty array case as needed
    //     }

    //     return servicableAreas.reduce((smallestArea, currentArea) => {
    //         const areaSmallest = smallestArea ? getPolygonArea(smallestArea.coordinates) : Infinity;
    //         const areaCurrent = getPolygonArea(currentArea.coordinates);

    //         if (areaCurrent < areaSmallest) {
    //             return currentArea;
    //         } else {
    //             return smallestArea;
    //         }
    //     }, null);
    // }


    // useEffect(() => {
    //     if (!coordinates || coordinates?.length === 0) {
    //         return;
    //     }

    //     if (ServicableAreas?.length === 0) {
    //         return;
    //     }

    //     let inside = false;
    //     let servicableAreasMatched = [];
    //     for (let i = 0; i < ServicableAreas.length; i++) {
    //         const servicableArea = ServicableAreas[i];
    //         if (checkIfCoordinatesAreInsideServicableArea(coordinates, servicableArea)) {
    //             inside = true;
    //             servicableAreasMatched.push(servicableArea);
    //         }
    //     }
    //     if (inside) {
    //         const smallestServicableArea = getSmallestServicableArea(servicableAreasMatched);

    //         setInsideServicableArea(smallestServicableArea);
    //     }
    //     else {
    //         setInsideServicableArea(null);
    //     }

    // }, [coordinates])


    // const GetMapIcons = async () => {
    //     let req = new StringIntReq();
    //     req.string = "";
    //     req.int = user.salesOrgId;

    //     let res = await MapIconApi.GetSalesOrgMapIcons(req);
    //     if (res?.status?.success) {
    //         res.data.sort((a, b) => a.order - b.order)
    //         setMapIcons(res?.data);
    //     }
    //     else {

    //     }
    // }

    // useEffect(() => {
    //     if (!isLoaded) {
    //         GetMapIcons();
    //     }
    // }, [isLoaded])

    // useEffect(() => {
    //     if (mapIcons.length > 0) {
    //         setIsLoaded(true);
    //     }
    // }, [mapIcons])




    const createFiberHouse = (add, coor, assignedMapIcons,vectorLayer) => {


        if (!address && !add) {
            Swal.fire({
                icon: 'error',
                title: 'Address is required',
                text: 'Please enter Address',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        // if (!coordinates && !coor) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Fiber House Position is required',
        //         text: 'Please enter Fiber House Position',
        //         allowOutsideClick: false,
        //         allowEscapeKey: false,
        //         allowEnterKey: false,
        //         showConfirmButton: true,
        //         didOpen: () => {
        //             Swal.hideLoading()
        //         }
        //     });
        //     return;
        // }

        if (!selectedMapIcon) {
            Swal.fire({
                icon: 'error',
                title: 'Map Icon is required',
                text: 'Please enter Map Icon',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            });
            return;
        }

        let orgId = null;
        if (user?.userType === "SuperAdmin" || user?.userType === "Admin") {
            orgId = null;
        }
        else {
            orgId = user.salesOrgId;
        }

        const req = new CreateFiberHouseReq()
        req.createdById = user.id
        req.salesOrgId = orgId

        req.mapString = add ? add : address

        req.coordinate.latitude = coor[1]
        req.coordinate.longitude = coor[0]
        req.coordinate.popUpColor = "#000000"
        req.mapIconId = assignedMapIcons.id
        req.lead.salesOrgId = user.salesOrgId
        FiberHouseApi.CreateFiberHouse(req).then(
            (res) => {
                if (res?.status?.success) {
                    console.log(res)

                    
                    mapObject.removeLayer(vectorLayer);
                    setFiberHouses((prev) => {
                        return [...prev, res.data]
                    })

                }
                else {

                }
            }
        )

    }


    // const displayMoveableMarker = async () => {
    //     if (mapObject) {
    //         if (displayMarker) {
    //             if (!markerLayer) {
    //                 const vectorSource = new VectorSource({
    //                     features: [
    //                         new Feature({
    //                             geometry: new Point(mapObject.getView().getCenter())
    //                         })
    //                     ]
    //                 });

    //                 const vectorLayer = new VectorLayer({
    //                     source: vectorSource,
    //                     name: 'markerLayer'
    //                 });
    //                 setMarkerLayer(vectorLayer);

    //                 vectorLayer.getSource().getFeatures()[0].setStyle(new Style({
    //                     image: new Icon({
    //                         src: locationPng,
    //                         scale: 1.5,
    //                         anchor: [0.5, 1],
    //                         zIndex: 100000000
    //                     })
    //                 }));

    //                 const modifyInteraction = new Modify({
    //                     source: vectorSource,

    //                 });

    //                 modifyInteraction.on('modifyend', function (event) {
    //                     // Handle the modifyend event here
    //                     // For example, you can get the new coordinates of the feature like this:
    //                     const feature = event.features.getArray()[0];
    //                     const coordinates = feature.getGeometry().getCoordinates();
    //                     setCoordinates(toLonLat(coordinates))
    //                     getLocationName(toLonLat(coordinates)).then((res) => {
    //                         console.log(res)
    //                         setAddress(res)
    //                     })
    //                     mapObject.getView().setCenter(coordinates);
    //                 });

    //                 mapObject.addInteraction(modifyInteraction);
    //                 mapObject.addLayer(vectorLayer);
    //             }
    //         } else {
    //             setDisplayMarker(true)
    //             // Remove the marker layer if displayMarker is false
    //             if (markerLayer) {
    //                 mapObject.removeLayer(markerLayer);
    //                 setMarkerLayer(null);
    //             }
    //         }
    //     }
    // }

    const displayDraggableMapIcon = async (coordinates) => {
        if (mapObject && selectedMapIcon) {
            // display the marker on the map

            // const id = Math.random().toString(36).substring(7)
            // const id = "NOT A NUMBER"
            // setFiberHouses((prev) => {
            //     return [...prev, {
            //         coordinates: {
            //             latitude: toLonLat(coordinates)[1],
            //             longitude: toLonLat(coordinates)[0],
            //         },
            //         address: 'New Fiber House',
            //         mapIconId: selectedMapIcon.id,
            //         id: id,
            //         owners: [],
            //         assignedAcountStatus: false,
            //         customer: false,
            //         isNewHouse: true
            //     }]
            // })
             
            const feature = new Feature({
                geometry: new Point(coordinates)
            });

            const vectorSource = new VectorSource({
                features: [feature]
            });

            const vectorLayer = new VectorLayer({
                source: vectorSource,
                name: 'markerLayer'
            });

            vectorLayer.getSource().getFeatures()[0].setStyle(new Style({
                image: new Icon({
                    src: "data:image/png;base64,"+selectedMapIcon.image,
                    scale: 0.5,
                    anchor: [0.5, 1],
                })
            }));

            mapObject.addLayer(vectorLayer);


            const res = await helper.getLocationName(toLonLat(coordinates))
            createFiberHouse(res, toLonLat(coordinates), selectedMapIcon,vectorLayer)
            setAddress(res)
            setSelectedMapIcon(null)
        }
    }

    //Display the moveable marker
    useEffect(() => {

        async function displayMarkerOnMap(event) {
            const coordinates = event.coordinate;
            displayDraggableMapIcon(coordinates);
        }

        if (selectedMapIcon) {
            mapObject.on('click', displayMarkerOnMap);
        }

        return () => {
            mapObject.un('click', displayMarkerOnMap);
        }
    }, [selectedMapIcon])

    //Search for the location and display it on the map
    // useEffect(
    //     () => {
    //         if (!country && !state && !city && !address) {
    //             return;
    //         }
    //         if (mapObject && SnapPosition) {
    //             searchLocation(`${country},${state},${city},${address}`).then(
    //                 (res) => {
    //                     if (res) {
    //                         mapObject.getView().setCenter(fromLonLat(res));
    //                         mapObject.getView().setZoom(15);
    //                         if (markerLayer) {
    //                             mapObject.removeLayer(markerLayer);
    //                             setMarkerLayer(null);
    //                         }

    //                         const vectorSource = new VectorSource({
    //                             features: [
    //                                 new Feature({
    //                                     geometry: new Point(mapObject.getView().getCenter())
    //                                 })
    //                             ]
    //                         });

    //                         const vectorLayer = new VectorLayer({
    //                             source: vectorSource,
    //                             name: 'markerLayer'
    //                         });
    //                         setMarkerLayer(vectorLayer);
    //                         vectorLayer.getSource().getFeatures()[0].setStyle(new Style({
    //                             image: new Icon({
    //                                 src: "/images/placeholder/location.png",
    //                                 scale: 1.5,
    //                                 anchor: [0.5, 1],
    //                             })
    //                         }));

    //                         const modifyInteraction = new Modify({
    //                             source: vectorSource,

    //                         });

    //                         modifyInteraction.on('modifyend', function (event) {
    //                             // Handle the modifyend event here
    //                             // For example, you can get the new coordinates of the feature like this:
    //                             const feature = event.features.getArray()[0];
    //                             const _coordinates = feature.getGeometry().getCoordinates();
    //                             setCoordinates(toLonLat(_coordinates))
    //                             mapObject.getView().setCenter(_coordinates);
    //                         });

    //                         mapObject.addInteraction(modifyInteraction);
    //                         mapObject.addLayer(vectorLayer);

    //                     }
    //                 }
    //             )
    //         }
    //     }, [country, state, city])

    const style = {
        borderRadius: screenWidth < 1000 ? "50%" : "0",
        // width:screenWidth<1000? "3.1em":"",
        // height:screenWidth<1000? "3.1em":"",
        padding: screenWidth < 1000 ? "0.2rem" : "0",

    }

    return (
        <div style={{
            // width:"98vw",
            // overflow:"auto"
        }} className='CreateFiberHouse'>
            {screenWidth > 1000 && <div className='TitleDiv'>
                <h3 className='text-4xl font-bold'>Create Fiber House</h3>

                {user.userType === "SuperAdmin" && <button onClick={() => setIndex(6)} className='uppercase text-[#2761D0] '>Create Using CSV</button>}

            </div>}

            {/* <div className='w-full flex flex-col gap-4 mx-auto sm:mt-8 mt-0 CreateSingleFiberHouseForm'>
                <div className='MapIconsDiv'>
                    {mapIcons.map((assignedMapIcons, index) => {
                        return (
                            <div className='MapIconDiv' key={index} style={{ border: selectedMapIcon?.id === assignedMapIcons.id ? screenWidth > 1000 ? "2px solid #2761D0" : "none" : "none", cursor: 'pointer' }} onClick={() => setSelectedMapIcon(assignedMapIcons)}>
                                <div className='MapIconImageDiv'>
                                    <img style={{
                                        border: screenWidth < 1000 && selectedMapIcon?.id === assignedMapIcons.id ? "4px solid #2761D0" : "none",
                                        ...style
                                    }} src={"data:image/png;base64," + assignedMapIcons.image} alt="Map Icon" />
                                </div>
                                { <p className='MapIconName'>{assignedMapIcons.abbreviation}</p>}

                            </div>
                        )
                    })}
                </div>
               
            </div> */}
            <div style={{
                overflow:"auto",
                width:"100%",
                margin:"0rem"
            }} className='MapIconDisplay' >
                {
                    mapIcons.map(
                        (assignedMapIcons) => {
                            // return <div onClick={()=>{onClick(assignedMapIcons)}} className={`flex flex-col items-center justify-center w-fit h-28 hover:bg-blue-300 p-2 rounded-lg focus:bg-blue-300 ${selectedMapIcon?.id===assignedMapIcons.id?"bg-blue-300":""} `}>
                            return <div className={`MapIconDiv`}
                            style={{ border: selectedMapIcon?.id === assignedMapIcons.id ? screenWidth > 1000 ? "2px solid #2761D0" : "none" : "none", cursor: 'pointer' }} onClick={() => setSelectedMapIcon(assignedMapIcons)}>
                                 <img style={{
                                        border: screenWidth < 1000 && selectedMapIcon?.id === assignedMapIcons.id ? "4px solid #2761D0" : "none",
                                        ...style
                                    }} src={"data:image/png;base64," + assignedMapIcons.image} alt="Map Icon" />
                                <div>{assignedMapIcons.abbreviation}</div>
                            </div>
                        }

                    )
                }
            </div>
        </div>
    )
}

export default CreateFiberHouse