import React, { useContext, useEffect } from 'react'
import Swal from 'sweetalert2'
import CreateLightCustomerReq from '../../../Requests/Customer/CreateLightCustomerReq'
// import FiberCompanySelector from '../../FiberCompanySelector'
// import FiberPlanSelector from '../../FiberPlanSelector'
import { UserContext } from '../../../App'
import CustomerApi from '../../../API/CustomerApi'
import PhoneInput from 'react-phone-input-2'
const FreeInstallForm = ({lead}) => {
    const user = useContext(UserContext)
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const [consent, setConsent] = React.useState(false)
    // const [companyId, setCompanyId] = React.useState('')
    // const [fiberPlanId, setFiberPlanId] = React.useState('')

    useEffect(() => {
        setFirstName(lead?.name?.split(' ')[0])
        setLastName(lead?.name?.split(' ')[1])
        setEmail(lead?.email)
        setPhone(lead?.phone)
    }, [lead])


    const createLightCustomer = async () => {
        Swal.fire({
            title:"Creating Customer",
            icon:"info",
            showConfirmButton:false,
            allowOutsideClick:false,
            allowEscapeKey:false,
            allowEnterKey:false,
            customClass:{
                container:"custom-swal"
            },
            didOpen:()=>{
                Swal.showLoading()
            }
        })

        if(!firstName){
            Swal.fire({
                icon:'error',
                title:'First Name is required',
                customClass:{
                    container:"custom-swal"
                },
                didOpen : ()=>{
                    Swal.hideLoading()
                }
            })
            return
        }

        if(!email){
            Swal.fire({
                icon:'error',
                title:'Email is required',
                customClass:{
                    container:"custom-swal"
                },
                didOpen : ()=>{
                    Swal.hideLoading()
                }
            })
            return
        }
        
        if(!phone){
            Swal.fire({
                icon:'error',
                title:'Phone is required',
                customClass:{
                    container:"custom-swal"
                },
                didOpen : ()=>{
                    Swal.hideLoading()
                }
            })
            return
        }

        if(!consent){
            Swal.fire({
                icon:'error',
                title:'Consent is required',
                customClass:{
                    container:"custom-swal"
                },
                didOpen : ()=>{
                    Swal.hideLoading()
                }
            })
            return
        }

        const req = new CreateLightCustomerReq();
        req.firstName = firstName;
        req.lastName = lastName;
        req.email = email;
        req.phoneNumber = phone;
        // req.companyId = companyId;
        // req.planId = fiberPlanId.id;
        req.salesRepId = user?.id;
        req.leadId = lead?.id;
        req.signedUpBy = "SalesRep"
        const res  = await CustomerApi.CreateLightCustomer(req);

        if(res?.status?.success){
            Swal.fire({
                icon:'success',
                title:'Customer Created Successfully',
                customClass:{
                    container:"custom-swal"
                },
                didOpen : ()=>{
                    Swal.hideLoading()
                }
            })
        }
        else{
            Swal.fire({
                icon:'error',
                title:'Failed to create customer',
                customClass:{
                    container:"custom-swal"
                },
                didOpen : ()=>{
                    Swal.hideLoading()
                }
            })
        }


    }

    return (
        <div className='form'>
            <h2>Free Install Form</h2>
            <div className='input_div'>
                <label>First Name</label>
                <input type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </div>
            <div className='input_div'>
                <label>Last Name</label>
                <input type='text' value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </div>
            <div className='input_div'>
                <label>Email</label>
                <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className='input_div'>
                <label>Phone</label>
                <PhoneInput country={'us'} value={phone} containerStyle={{
                    width: '100%',
                }} inputStyle={{
                    paddingLeft: '50px',
                    width: '100%',
                }} onChange={(phone) => setPhone(phone)} />
            </div>
      
          
            <div className='consent'>
                <input type='checkbox' checked={consent} onChange={() => setConsent(!consent)} />
                <p>I agree to receive emails and/or sms messages from Lightning OS LLC.</p>
            </div>
            <button onClick={()=>{
                createLightCustomer()
            }}>Reserve Free Install</button>
        </div>
    )
}

export default FreeInstallForm