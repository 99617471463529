import React, { useContext, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import CreateCustomerReq from '../../../../Requests/Customer/CreateCustomerReq';
import Swal from 'sweetalert2';
import OfferApi from '../../../../API/OfferApi';
import logo from './logo.png'
import authorized from './authorized.png'
import LightAcceptOfferReq from '../../../../Requests/Customer/LightAcceptOfferReq';
import { IoMdCloseCircle } from 'react-icons/io';

const LightAcceptOfferForm = ({ lead, offer, setOpen, setReload, fiberPlan, isSharedOffer }) => {
    const [lightFirstName, setLightFirstName] = useState(lead?.name?.split(' ')[0])
    const [lightLastName, setLightLastName] = useState(lead?.name?.split(' ').length > 1 ? lead?.name?.split(' ')[1] : '')
    const [lightEmail, setLightEmail] = useState(lead?.email)
    const [lightPhone, setLightPhone] = useState(lead?.phone)
    const [lightConsent, setLightConsent] = useState(false)


    const createLightCustomer = async () => {
        // console.log(offer)
        // console.log(fiberPlan)
        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Creating Light Customer",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: 'custom-swal'
            },
            willOpen: () => {
                Swal.showLoading();
            },
        })

        if (!lightFirstName) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "First Name is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lightPhone) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Phone is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }


        if(!offer.fiberCompanyId){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Company is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(!fiberPlan){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Plan is required",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lightConsent) {
            Swal.fire({
                icon: "error",
                title: "Please agree to the terms and conditions",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        

        var req = new LightAcceptOfferReq()
        req.leadId = lead.id
        req.firstName = lightFirstName
        req.lastName = lightLastName
        req.email = lightEmail
        req.phoneNumber = lightPhone
        req.salesRepId =  offer?.salesRepId;
        req.signedUpBy = "SalesRep"
        req.companyId = offer.fiberCompanyId
        req.planId = fiberPlan.id
        req.offerId = offer.id
        const res = await OfferApi.LightAcceptOffer(req)
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Light Customer Created Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                willClose: () => {
                    Swal.hideLoading()
                }
            })
            setReload(true);
            setOpen(false);
        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                customClass: {
                    container: 'custom-swal'
                },
                text: res?.status?.message
            })
        }
    }


    return (
        <div className='custom_modal'>
            <div style={{width:"100%"}}>
                <IoMdCloseCircle style={{marginLeft:"auto",cursor:"pointer"}} onClick={() => {
                    setOpen(false)
                }} size={30} color='#2761D0' />
            </div>
            <img alt="Logo" src={authorized} className='logo' />
            {/* <h1 >Customer Info</h1> */}
            <div className='inputs'>
                <input placeholder='*First Name' value={lightFirstName} onChange={(e) => {
                    setLightFirstName(e.target.value)
                }} />
                <input placeholder='Last Name' value={lightLastName} onChange={(e) => {
                    setLightLastName(e.target.value)
                }} />
                <input placeholder='Email' value={lightEmail} onChange={(e) => {
                    setLightEmail(e.target.value)
                }} />
                {/* <FiberCompanySelector setUser={setLightCompany} disabled={false} placeholder="Select a Company" className="fiber_company_selector" />
                <FiberPlanSelector setUser={setLightPlan} disabled={lightCompany ? false : true}
                    placeholder="Select a Plan" className="fiber_company_selector" fiberCompany={lightCompany} /> */}
                <PhoneInput placeholder='* Cellphone' country={'us'} containerStyle={{
                    width: "100%",
                    // marginBottom: "1rem",
                    borderRadius: "10px"
                }} inputClass='custom_input' inputStyle={{
                    // width: "100%",
                    // height: "auto",
                    // padding: "0.5rem",
                    // fontSize: "1.2rem",
                    // borderRadius: "10px"
                }} value={lightPhone} onChange={(e) => {
                    setLightPhone(e)
                }} />
                <div className='consent'>
                    <input type="checkbox"
                        checked={lightConsent}
                        onChange={(e) => {
                            setLightConsent(e.target.checked)
                        }}
                    />
                    <label>I agree to receive email and/or sms messages from Lightning OS LLC.</label>
                </div>
                <button onClick={() => {
                    createLightCustomer()
                }
                }>Reserve Free Install</button>
                <img alt='Logo' src={logo} style={{
                    width: "100%",
                    // marginTop: "1rem"
                }} className='logo' />
            </div>
        </div>
    )
}

export default LightAcceptOfferForm
