import React, { useContext, useEffect, useState } from 'react'
import QRCode from 'qrcode.react'
import Layout from '../../../Layouts/Layout';
import { CircularProgress } from '@mui/material';
import { UserContext } from '../../../App';
import { FaCopy } from 'react-icons/fa';
import Swal from 'sweetalert2';
import FiberCompanySelector from '../../../components/FiberCompanySelector';
import './index.scss'; 

const SetReferalCompany = () => {

    const [loaded, setLoaded] = React.useState(false)
    const [fiberCompanyId, setFiberCompanyId] = useState(null);
    const [QrCodeValue, setQrCodeValue] = useState(null);
    const [isGeneric, setIsGeneric] = useState(false);
    const user = useContext(UserContext)

    useEffect(() => {
        setQrCodeValue(`${window.location.origin}` + (fiberCompanyId ? `/digital-business-center/${user.id}/${fiberCompanyId}` : '/digital-business-center/' + user.id));
    }, [fiberCompanyId, isGeneric])



    
    // if (!loaded) {
    //     return (
    //         <Layout>
    //             <div style={{
    //                 display: 'flex',
    //                 justifyContent: 'center',
    //                 alignItems: 'center',
    //                 width: '100%',
    //                 height: '100%'
    //             }}>
    //                 <CircularProgress size={60} sx={{
    //                     color: "#181E4B"
    //                 }} />
    //             </div>
    //         </Layout>
    //     )
    // }

    const GenerateUrl = () => {
        if(fiberCompanyId== null && !isGeneric)
            {
                Swal.fire({
                    title: "Please Select a Company",
                    icon: "error",
                    showConfirmButton: true,
                })
                return;
            } 
        navigator.clipboard.writeText(`${window.location.origin}` + (fiberCompanyId ? `/digital-business-center/${user.id}/${fiberCompanyId}` : '/digital-business-center/' + user.id));
        Swal.fire({
            title: "Link Generated & Copied To Clipboard",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
            toast: true,
            position: "bottom-right",
            timerProgressBar: true,
        })
    }
    const copyUrlToClipboard = () => {
        navigator.clipboard.writeText(`${window.location.origin}` + (fiberCompanyId ? `/digital-business-center/${user.id}/${fiberCompanyId}` : '/digital-business-center/' + user.id));
        Swal.fire({
            title: "Link Copied",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            position: "bottom-right",
            timerProgressBar: true,
        })
    }

    const shareReferralLink = () => {
        const affiliateCodePath = fiberCompanyId ? `/digital-business-center/${user.id}/${fiberCompanyId}` : '/digital-business-center/' + user.id;
        const fullUrl = `${window.location.origin}${affiliateCodePath}`;
    
        if (navigator.share) {
            navigator.share({
                title: 'Join me on LightningOS!',
                text: 'Here is my referral link:',
                url: fullUrl
            }).then(() => {
                console.log('Referral link shared successfully');
            }).catch((error) => {
                console.error('Error sharing the referral link:', error);
            });
        } else {
            console.log('Web Share API not supported in this browser.');
        }
    };


    const handleCheck = (value) => {
        setIsGeneric(value)
        setFiberCompanyId(null)
    }


    return (
        <Layout>
            <div className="referral-container">
                <div className="qr-code-container">
                    <QRCode size={200} value={QrCodeValue} />
                    <h3>Qr Code</h3>
                </div>
    
                {/* <div className="copy-link-button" onClick={copyUrlToClipboard}>
                    <h5>Copy Link</h5>
                    <button><FaCopy size={20}/></button>
                </div> */}
    
                <div className="form-group">    
                    <div className='checkbox_container'>
                <div className='checkbox'>
                  <input type="checkbox" name="isGeneric" id="isGeneric" checked={isGeneric} onChange={(e) => handleCheck(e.target.checked)} />
                  <label htmlFor="isGeneric">Make Generic</label>
                </div>
              </div>
                    {!isGeneric && 
                    <FiberCompanySelector className="fiber_company_selector" placeholder='Select Fiber Company' setUser={setFiberCompanyId} />
                    }
                    <button className="generate-link-button" onClick={() => {
                        GenerateUrl()
                    }}>Generate Link</button>
                    <button style={{
                        padding: "0.5rem 1rem",
                        backgroundColor: "var(--color-primary)",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        width: "100%",
                        height:"100%"
                    }} onClick={() => {
                        shareReferralLink()
                    }} >Send Business Card</button>
                </div>
            </div>
        </Layout>
    );
    

}


export default SetReferalCompany;