import React, { useContext, useEffect, useState } from 'react'
import './MainBizCard.scss'
import { UserContext } from './../../../../../App'
import Swal from 'sweetalert2';
import { MdOutlineTextsms } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { MdOutlineContentCopy } from "react-icons/md";
import OfferApi from '../../../../../API/OfferApi';
import CreateOrEditOfferReq from '../../../../../Requests/Offer/CreateOrEditOfferReq';
import ContactButtons from '../../../../../Services/ContactButtons'
import FiberCompanySelector from '../../../../../components/FiberCompanySelector';
import PhoneInput from 'react-phone-input-2';
import EditFiberHouseReq from '../../../../../Requests/FiberHouse/EditFiberHouseReq';
import FiberHouseApi from '../../../../../API/FiberHouseApi';
import { MapContext } from '../..';
import { IoMdArrowRoundBack } from 'react-icons/io';
import OfferTemplateSelector from "./../../../../../components/OfferTemplateSelector"

const getFirstName = (lead) => {
    if (lead?.name?.length > 0) {
        return lead?.name?.split(' ')[0];
    } else {
        return '';
    }
}
const getLastName = (lead) => {
    if (lead?.name?.length > 0 && lead?.name?.split(' ')[1]?.length > 0) {
        return lead?.name?.split(' ')[1];
    } else {
        return '';
    }
}

const MainBizCard = ({ lead }) => {
    const [firstName, setFirstName] = useState(getFirstName(lead));
    const [lastName, setLastName] = useState(getLastName(lead));
    const [cellPhone, setCellPhone] = useState(lead?.phone);
    const [email, setEmail] = useState(lead?.email);
    const [fiberCompanyId, setFiberCompanyId] = useState(null);
    const user = useContext(UserContext)
    const map = useContext(MapContext)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [free, setFree] = useState(0);
    const [fiberCompany, setFiberCompany] = useState(null)
    // const [offerTemplateId,setOfferTemplateId] = useState(null);
    const contactButtons = new ContactButtons(user);
    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenWidth(window.innerWidth)
        })

        return () => {
            window.removeEventListener('resize', () => {
                setScreenWidth(window.innerWidth)
            })
        }

    }, [])



    console.log(lead)



    const createOffer = async (method) => {

        Swal.fire({
            icon: "info",
            title: 'Sending Offer',
            html: 'Please Wait',
            allowOutsideClick: false,
            customClass: 'custom-swal',
            didOpen: () => {
                Swal.showLoading()
            }
        })

        console.log(lastName)
        const req = new CreateOrEditOfferReq();
        req.firstName = firstName;
        req.lastName = lastName;
        if (email === "null") {
            req.email = null;
        } else {
            req.email = email
        }
        if (cellPhone === "null") {
            req.phone = null;
        } else {
            req.phone = cellPhone
        }
        req.fiberType = "1G";
        req.leadId = lead.id;
        req.salesRepId = user?.id;
        req.fiberCompanyId = fiberCompanyId;
        req.fidiumCustomerId = lead.fidiumCustomerId ? lead.fidiumCustomerId : 0;
        if (free == "1") {
            req.freeOffer = true;
            req.freeInstall = false;
        }
        else if (free == "2") {
            req.freeOffer = false;
            req.freeInstall = true;

        }
        else if (free == "3") {
            req.freeOffer = true;
            req.freeInstall = true;
        }
        else if (free == "0") {
            req.freeOffer = false;
            req.freeInstall = false;
        }
        // if(offerTemplateId){
        //     req.templateId = offerTemplateId
        // }
        req.promo = free
        req.templateId = fiberCompany?.offerTemplates[0]?.id
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 10;
        const res = await OfferApi.CreateEditOffer(req);
        if (res?.status?.success) {
            map.setFiberHouses(
                (prev) => {
                    return prev.map(
                        (fiberHouse) => {
                            if (fiberHouse.id == req.id) {
                                fiberHouse.mapIconId = 8;
                            }
                            return fiberHouse;
                        }
                    )
                })
            console.log(res)
            Swal.fire({
                icon: "success",
                title: 'Offer Created Successfully',
            })
            const offerLink = window.location.origin + "/fiber-offer/" + res.data.offerGuid;
            const message = `Hey, ${firstName}${lastName ? " " + lastName : ""}.\n\nIt was great to meet today. As promised, the link below is the Lightcurve’s Internet Offer.\n\nRemember… this is a LIMITED TIME OFFER, and the link will expire in 7 days from now. Click below and fill out the form to reserve your spot on the Free Install Map - and receive your FIRST MONTH FREE & A LIFETIME PRICE PROMISE.\n\n${offerLink}`;
            console.log("HII", offerLink)
            console.log("FirstName", firstName)
            console.log("LastName", lastName)

            if (method === 'email') {
                contactButtons.email(`${email}?subject=Digital Business Card&body=${encodeURIComponent(message)}`);
            }
            else if (method === "wa") {
                contactButtons.whatsapp(`${cellPhone}?text=${encodeURIComponent(message)}`);

            }
            else if (method === "sms") {
                contactButtons.sms(`${cellPhone}?body=${encodeURIComponent(message)}`);
            }
            else if (method === "copy") {
                Swal.fire({
                    icon: 'info',
                    title: 'Copying',
                    text: 'Please Keep The Window Open To Copy Link',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true
                })
                try {

                    navigator.clipboard.writeText(window.location.origin + "/fiber-offer/" + res.data.offerGuid)
                    Swal.fire({
                        icon: 'success',
                        title: 'Copied',
                        text: 'Link Copied to Clipboard',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true
                    })
                } catch (e) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Link Not Copied to Clipboard because browser window was closed',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true
                    })
                }
            }
            const req = new EditFiberHouseReq();
            req.id = lead?.fiberHouse?.id;
            req.mapIconId = 8;
            req.coordinate = null;
            req.salesRepId = user?.id;

            try {
                const res = await FiberHouseApi.EditFiberHouse(req);
                if (res?.status?.success) {

                }
            }
            catch (e) {
                console.log(e)
            }

        }
        else {
            Swal.fire({
                icon: "error",
                title: 'Error',
                text: res?.status?.message
            })
        }

    }



    function sendOffer(type) {
        if (!firstName) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please Enter First Name"
            });
            return
        }
        // if (!lastName) {
        //     Swal.fire({
        //         icon: "error",
        //         title: "Error",
        //         text: "Please Enter Last Name"
        //     });
        //     return
        // }
        // if (!cellPhone) {
        //     Swal.fire({
        //         icon: "error",
        //         title: "Error",
        //         text: "Please Enter Cell Phone"
        //     });
        //     return
        // }
        // if (!email) {
        //     Swal.fire({
        //         icon: "error",
        //         title: "Error",
        //         text: "Please Enter Email"
        //     });
        //     return
        // }

        if (!fiberCompanyId) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please Select Fiber Company"
            });
            return
        }

        createOffer(type)
    }

    return (
        <div className='MainBizCardContainer'>

            <div className='dissappearOnMobile'>
                <IoMdArrowRoundBack style={{ cursor: "pointer" }} color='black' size={30} onClick={() => map.setIndex(10)} />
            </div>

            <h1>Send Offer</h1>
            {/* <div className='firstAndLastName'> */}
            {/* FirstName */}
            <div className='input_div'>
                <label>First Name</label>
                <input type="text" value={firstName} placeholder='First Name' onChange={(e) => setFirstName(e.target.value)} />
            </div>
            {/* LastName */}
            <div className='input_div'>
                <label>Last Name</label>
                <input type="text" value={lastName} placeholder='Last Name' onChange={(e) => setLastName(e.target.value)} />
            </div>
            {/* </div> */}
            {/* CellPhone */}
            <div className='input_div'>
                <label>Cell Phone</label>
                <PhoneInput country={'us'} value={cellPhone} onChange={(e) => setCellPhone(e)} containerStyle={{
                    width: '100%',
                }} inputStyle={{
                    width: '100%',
                    paddingLeft: "75px"
                }} />
            </div>
            {/* Email */}
            <div className='input_div'>
                <label>Email</label>
                <input type="text" value={email} placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className='input_div' >
                <label>Fiber Company</label>
                <FiberCompanySelector companiesWithOfferTemplateOnly={true} placeholder="Select Fiber Company" setCompanyObject={setFiberCompany} setUser={setFiberCompanyId} />
                {
                    !fiberCompany &&
                    <p className='note'>Note: Select a fiber Company to See the Promos.</p>
                }
            </div>
            {
                fiberCompany &&
                <>
                    <div className='input_div'>
                        <label>Promos</label>
                        <div className="userType">
                            {
                                fiberCompany?.offerTemplates?.length > 0 ?
                                    fiberCompany?.offerTemplates[0]?.promos?.split('\n').map(
                                        (promo, index) => {
                                            return <label>
                                                <input type='checkbox' name={`free` + index} onChange={(e) => {
                                                    if (free) {
                                                        let arr = free.split('\n')
                                                        if (arr.includes(promo)) {
                                                            arr = arr.filter((item) => item !== promo)
                                                            setFree(arr.join('\n'))
                                                        }
                                                        else {
                                                            setFree((prev) => prev + '\n' + promo)
                                                        }
                                                    }
                                                    else {
                                                        setFree(promo)
                                                    }
                                                }} />
                                                <span>{promo}</span>
                                            </label>
                                        }
                                    )
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div style={{
                        padding: 0
                    }} className='button'>
                        <p style={{
                            gridColumn: "span 5/span 5",
                            color: 'black',
                            fontSize: screenWidth > 500 ? 20 : 15,
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}>Send Offer To Customer</p>
                        <button onClick={() => sendOffer('sms')}><MdOutlineTextsms className='icon' size={screenWidth > 500 ? 50 : 30} /></button>
                        <button onClick={() => sendOffer('wa')}><FaWhatsapp className='icon' size={screenWidth > 500 ? 50 : 30} /></button>
                        <button onClick={() => sendOffer('email')}><MdEmail className='icon' size={screenWidth > 500 ? 50 : 30} /></button>
                        <button onClick={() => sendOffer('copy')}><MdOutlineContentCopy className='icon' size={screenWidth > 500 ? 50 : 30} /></button>
                    </div>
                </>
            }
            {/* <button className='send_offer_button' onClick={() => { sendOffer() }}>Send Offer</button> */}
        </div>
    )
}

export default MainBizCard