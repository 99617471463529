import { CircularProgress, Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import UsersApi from '../../../API/UsersApi'
import Sidebar from '../../../Layouts/Sidebar'
import ChangeUserStatusReq from '../../../Requests/Users/ChangeUserStatusReq'
import SearchUserReq from '../../../Requests/Users/SearchUserReq'
import DataTable from "../../../components/Datatable"
import './index.scss'
import MobileLayout from '../../../Layouts/MobileLayout'
import Layout from '../../../Layouts/Layout'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import { PaginationItem } from '@mui/material'
const Index = () => {
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [userType, setUserType] = useState("");
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const headers = [
        {
            key: "id",
            name: "ID"
        },
        {
            key: "firstName",
            name: "First Name"
        },
        {
            key: "lastName",
            name: "Last Name"
        },
        {
            key: "email",
            name: "Email Address"
        },
        {
            key: "phoneNumber",
            name: "Phone Number"
        },
        {
            key: "address",
            name: "Address"
        },
        {
            key: "userType",
            name: "User Type"
        },

        {
            key: "status",
            name: "Status"
        },
        {
            name: 'Commission Details',
            key: 'commissionDetails'
        },
        {
            key: "details",
            name: "Details"
        },
        {
            key: "actions",
            name: "Actions"
        }
    ]

    function Button({ onClick, children }) {
        return <button style={{
            backgroundImage: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",
            color: "white",
            padding: "0.5rem 1rem",
            borderRadius: "10px"
        }} onClick={() => { onClick() }}>
            {children}
        </button>
    }

    function activateAccount(user, status = "Active") {
        Swal.fire({
            title: status === "Active" ? 'Activating Account' : 'Deactivating Account',
            text: 'Please wait...',
            icon: 'info',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })


        const req = new ChangeUserStatusReq()
        req.int = user.id
        req.string = user.status === "Active" ? "Inactive" : "Active"


        req.status = status

        UsersApi.ChangeUserStatus(req).then(
            (res) => {

                if (res?.status?.success) {
                    Swal.fire({
                        title: status === "Active" ? 'Account Activated' : 'Account Deactivated',
                        text: status === "Active" ? 'The account has been activated.' : 'The account has been deactivated.',
                        icon: 'success',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    })
                    fetchData()
                }
                else {
                    Swal.fire({
                        title: 'Error',
                        text: res?.status?.message,
                        icon: 'error',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    })
                }
            }
        )
    }



    function fetchData() {
        setLoading(true)
        let req = new SearchUserReq()
        req.pagingParams.pageNumber = currentPage
        req.pagingParams.pageSize = 5
        if (firstName !== "") {
            req.firstName = firstName
        }
        if (lastName !== "") {
            req.lastName = lastName
        }
        if (email !== "") {
            req.email = email;
        }
        if (phone !== "") {
            req.phoneNumber = phone
        }
        if (address !== "") {
            req.address = address
        }
        if (userType !== "") {
            req.userType = userType
        }
        req.includeDeactivated = true
        UsersApi.SearchUser(req).then(
            (res) => {
                if (res?.status?.success) {
                    setTotalPages(res?.data?.totalPages)

                    const userTemp = []
                    res?.data?.list.map(
                        (user, index) => {

                            userTemp.push({
                                ...user,
                                actions: user.status === "Active" ?
                                    <Button onClick={() => { activateAccount(user, "Inactive") }} >Deactivate</Button>
                                    :
                                    <Button onClick={() => activateAccount(user)} >Activate</Button>,

                                details: <Button onClick={() => {
                                    navigate('/searchUser/userDetails', {
                                        state: {
                                            userId: user.id
                                        }
                                    })
                                }}   >Details</Button>,

                                commissionDetails: <Button onClick={() => {
                                    navigate('/commission-details', {
                                        state: {
                                            userId: user.id,
                                            name: user.firstName + " " + user.lastName
                                        }
                                    })
                                }}  >Commission Details</Button>,
                            })
                        })
                    setUsers(userTemp)
                    setLoading(false)
                }
            }
        )
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          fetchData();
        }
      };

    useEffect(
        () => {
            fetchData()
        }, [currentPage]
    )
    return (
        <Layout>
            <div className='manageLeadsContainer' >
                <div className='manageLeadsHeader' >
                    <h1 className='manageLeadsTitle'>Manage Users</h1>
                    <div className='searchFiltersInput' >

                        <input onChange={(e) => { setFirstName(e.target.value) }} onKeyDown={handleKeyPress} value={firstName} type='text' placeholder='FIRST NAME' />
                        <input onChange={(e) => { setLastName(e.target.value) }} onKeyDown={handleKeyPress} value={lastName} type='text' placeholder='LAST NAME' />
                        <input onChange={(e) => { setPhone(e.target.value) }} onKeyDown={handleKeyPress} value={phone} type='tel' placeholder='PHONE' />
                        <input onChange={(e) => { setEmail(e.target.value) }} onKeyDown={handleKeyPress} value={email} type="email" placeholder='EMAIL' />
                        <input onChange={(e) => { setAddress(e.target.value) }} onKeyDown={handleKeyPress} value={address} type="text" placeholder='ADDRESS' />
                        <select  value={userType} onChange={(e) => { setUserType(e.target.value) }}>
                            <option  value={""}>USER TYPE</option>
                            <option  value={"SuperAdmin"}>Super Admin</option>
                            <option  value={"Admin"}>Admin</option>
                            <option  value={"SalesOrgAdmin"}>Team Admin</option>
                            <option  value={"Customer"}>Customer</option>
                            <option  value={"SalesRep"}>SalesRep</option>
                        </select>

                    </div>
                    <button disabled={loading} onClick={() => { setCurrentPage(1); fetchData() }}>{loading ? "SEARCHING..." : "SEARCH"}</button>
                </div>
                {loading ? <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%"
                }} ><CircularProgress sx={{ color: "var(--color-icon)" }} size={50} /></div> :

                    <DataTable currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} headers={headers} list={users ? users : []} />
                }
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem " }}>
                    <Pagination color='light' renderItem={(item) => (
                        <PaginationItem components={{

                            next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                            first: (props) => <button {...props}>First</button>,
                            previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                        }}  {...item} sx={{ color: "white" }} />
                    )} count={totalPages} onChange={(e, pageNo) => {
                        setCurrentPage(pageNo)
                    }} />
                </div>
            </div>
        </Layout>
    )
}

export default Index