import Swal from "sweetalert2";
import * as XLSX from 'xlsx';

class ExportData {

    // Function to convert camel case to capitalized words
    capitalizeKeys(data) {
        return data.map(item => {
            const newItem = {};
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    const capitalizedKey = key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
                    newItem[capitalizedKey] = item[key];
                }
            }
            return newItem;
        });
    }

    ExportToCsv(filename, data, selectedIds) {
        const dataToExport = data.filter((item) => selectedIds.includes(item.id));
        if (dataToExport.length === 0) {
            Swal.fire({
                icon: "error",
                title: "No Customers Selected",
                text: "Please select customers to download"
            });
            return;
        }
        const capitalizedData = this.capitalizeKeys(dataToExport);
        const ws = XLSX.utils.json_to_sheet(capitalizedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "sheet");
        XLSX.writeFile(wb, filename);
    }

    ExportToXLSX(filename, data, selectedIds) {
        const selected = data.filter((item) => selectedIds.includes(item.id));
        if (selected.length === 0) {
            Swal.fire({
                icon: "error",
                title: "No Customers Selected",
                text: "Please select customers to download"
            });
            return;
        }
        const capitalizedData = this.capitalizeKeys(selected);
        const ws = XLSX.utils.json_to_sheet(capitalizedData);
    
        // Calculate column widths
        const columnWidths = Object.keys(capitalizedData[0]).map(key => {
            const maxLength = Math.max(
                key.length,
                ...capitalizedData.map(item => (item[key] ? item[key].toString().length : 0))
            );
            return { wch: maxLength + 5 }; // Add extra space for padding
        });
        ws['!cols'] = columnWidths;
    
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "sheet");
        XLSX.writeFile(wb, filename);
    }

    // CSV Export Method
    ExportIssuesToCsv(filename, data) {
        if (!Array.isArray(data) || data.length === 0) {
            console.log("Data to export:", data); // Debug line
            Swal.fire({
                icon: "error",
                title: "No Data",
                text: "There is no data to export."
            });
            return;
        }
    
        const capitalizedData = this.capitalizeKeys(data);
        const ws = XLSX.utils.json_to_sheet(capitalizedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Issues");
        XLSX.writeFile(wb, filename);
    }
    
    ExportIssuesToXLSX(filename, data) {
        if (!Array.isArray(data) || data.length === 0) {
            console.log("Data to export:", data); // Debug line
            Swal.fire({
                icon: "error",
                title: "No Data",
                text: "There is no data to export."
            });
            return;
        }
    
        const capitalizedData = this.capitalizeKeys(data);
        const ws = XLSX.utils.json_to_sheet(capitalizedData);
    
        // Calculate column widths
        const columnWidths = Object.keys(capitalizedData[0]).map(key => {
            const maxLength = Math.max(
                key.length,
                ...capitalizedData.map(item => (item[key] ? item[key].toString().length : 0))
            );
            return { wch: maxLength + 5 }; // Add extra space for padding
        });
        ws['!cols'] = columnWidths;
    
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Issues");
        XLSX.writeFile(wb, filename);
    }
    
    
    
}

export default ExportData