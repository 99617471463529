import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";

class FiberCompanyApi{
    baseUrl = EnvSettings.url;
    CreateFiberCompanyUrl = "CreateFiberCompany";
    SearchFiberCompanyUrl = "SearchFiberCompanies";
    DeleteFiberCompanyUrl = "DeleteFiberCompany";
    EditFiberCompanyUrl = "EditFiberCompany";
    GetCompanyFormUrl = "GetCompanyForm";
    CreateCompanyFormUrl = "CreateCompanyForm";
    EditCompanyFormUrl = "EditCompanyForm";

    async EditCompanyForm(req)
    {
        const res = await fetch(this.baseUrl + this.EditCompanyFormUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body:JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateCompanyForm(req)
    {
        const res = await fetch(this.baseUrl + this.CreateCompanyFormUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body:JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async GetCompanyForm(req)
    {
        const res = await fetch(this.baseUrl + this.GetCompanyFormUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body:JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async CreateFiberCompany(req)
    {
        const res = await fetch(this.baseUrl + this.CreateFiberCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`
            },
            body:req
        })
        const data = await res.json();
        return data;
    }

    async SearchFiberCompany(req)
    {
        const res = await fetch(this.baseUrl + this.SearchFiberCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async DeleteFiberCompany(req)
    {
        const res = await fetch(this.baseUrl + this.DeleteFiberCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(req)
        })
        const data = await res.json();
        return data;
    }

    async EditFiberCompany(req)
    {
        const res = await fetch(this.baseUrl + this.EditFiberCompanyUrl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${UsersApi.GetToken()}`
            },
            body:req
        })
        const data = await res.json();
        return data;
    }

}

export default new FiberCompanyApi();