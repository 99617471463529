/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef, useCallback } from 'react'
import SearchUserReq from '../../../Requests/Users/SearchUserReq';
import { UserContext } from '../../../App';
import UsersApi from '../../../API/UsersApi';
import greyUser from '../../../assets/images/userGrey.png'
import ContactButtons from '../../../Services/ContactButtons';
import CircularProgress from '@mui/material/CircularProgress';
import { FaSearch, FaWhatsapp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { TbMessage2Bolt } from 'react-icons/tb';
import { MdOutlineMail, MdOutlineMessage, MdOutlinePhone } from 'react-icons/md';
import OrganizationSelector from '../../../components/OrganizationSelector';
const TeamMembers = () => {


  const user = useContext(UserContext)
  const [teamMembers, setTeamMembers] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [loading, setLoading] = useState(true);
  const contactButtons = new ContactButtons(user);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [name, setName] = useState("");
  const observer = useRef(); // (*)
  const navigate = useNavigate();
  const lastCard = useCallback(  // (*)
    (node) => {
      if (loading) {
        console.log("Skip due to loading");
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {

        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prev) => prev + 1);
          console.log("visible");
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loading]  // (*) Add firstRender to the dependency array
  );

  const getTeamMembers = async () => {

    setLoading(true);
    console.log("Sales Org Id", user.salesOrgId);
    const req = new SearchUserReq();
    if (user.userType === 'SuperAdmin') {
      if (selectedOrg === -1) {
        req.salesOrgId = null;
      }
      else {
        req.salesOrgId = selectedOrg
      }
    }
    else {
      req.salesOrgId = user.salesOrgId;
    }
    req.userTypes = ["SalesRep", "SalesOrgAdmin"];
    req.pagingParams.pageNumber = pageNumber;
    req.pagingParams.pageSize = 20;
    req.firstName = name.split(" ")[0];
    if (name.split(" ").length > 1)
    req.lastName = name.split(" ")[1];
    const res = await UsersApi.SearchUser(req);
    if (res?.status?.success) {
      setTeamMembers((prev) => {
        if (prev !== null && pageNumber > 1) {
          const filteredList = res?.data?.list.filter(member => member.id !== user.id);
          // SalesOrgAdmin should be at the top
          const salesOrgAdmin = filteredList.filter(member => member.userType === 'SalesOrgAdmin');
          const salesRep = filteredList.filter(member => member.userType === 'SalesRep');
          return [...new Set([...salesOrgAdmin, ...salesRep])];
        } else {

          const filteredList = res?.data?.list.filter(member => member.id !== user.id);
          // SalesOrgAdmin should be at the top
          const salesOrgAdmin = filteredList.filter(member => member.userType === 'SalesOrgAdmin');
          const salesRep = filteredList.filter(member => member.userType === 'SalesRep');
          return [...new Set([...salesOrgAdmin, ...salesRep])];

        }
      });
      if (res.data.pageNumber === res.data.totalPages) {
        setHasMore(false);
      }
      else {
        setHasMore(true);
      }
      console.log("Team Members", res?.data?.list);
      setLoading(false);
    }
    else {
      console.log("Error", res?.status?.message);
    }
  }

  const filterSearch = async () => {
    setPageNumber(1);
    setTeamMembers(null);
    getTeamMembers();
  }

  useEffect(
    () => {
      const timeoutId = setTimeout(() => {
        setPageNumber(1);
        setTeamMembers(null);
        getTeamMembers();
      }, 1000);

      return () => clearTimeout(timeoutId);

    }, [selectedOrg,name]
  )

  const getImage = (member) => {
    if (member?.profileImage != null) {
      return <img src={member.profileImage.url} alt="User" />
    }
    else {
      return <img src={greyUser} alt="User" />
    }
  }


  const lightningMessage = async (member) => {
    const res = await contactButtons.lightningMessage(member);
    if (res != null) {
      console.log("Conversation", res)
      navigate('/chat', {
        state: { conversation: res }
      })
    }

  }


  return (
    <div className='Leads'>
      <div className="SearchFilters">
        <form onSubmit={(e) => { e.preventDefault(); filterSearch() }} className='search-bar'>
          <input value={name} onChange={(e) => { setName(e.target.value) }} type="text" placeholder="Search Name" />
          <FaSearch color='#2761CF' size={30} />
        </form>
        {
          user.userType === 'SuperAdmin' &&
          <div style={{
            marginTop:"1rem"
          }}>
            <OrganizationSelector setUser={setSelectedOrg} placeholder='Select Organization' />
          </div>
        }
      </div>
      <div className='LeadsList'>
        {!loading && <div>
          <h3 style={{
            color: "#5E6282",
            fontWeight: "bold",
            marginTop: "1rem"
          }}>RESULTS ({teamMembers?.length})</h3>
        </div>}
        {
          teamMembers?.map(
            (member, index) => {
              return (
                <div key={index} ref={teamMembers.length - 1 === index ? lastCard : null} className="LeadCard">
                  <div className='ImagesDiv'>
                    <div className='Image'>
                      {
                        getImage(member)
                      }
                    </div>

                  </div>
                  <div className='DataDiv'>
                    <div className='Name'>
                      <h3>{member?.firstName + " " + member.lastName} <span style={{
                        fontSize:"0.8rem"
                      }} >({member?.userType})</span></h3>
                    </div>
              
                    <div className='Address'>
                      <p title={member?.address} >{member?.address}</p>
                    </div>
                    <div className='Phone'>
                      <p>{member?.phoneNumber}</p>
                    </div>
                  </div>
                  <div className="MainLeadCard_Buttons">
                    <div className="MainLeadCard_Button" onClick={() => lightningMessage(member)}>
                      <TbMessage2Bolt color='#81848A' size={30} />
                    </div>
                    <div className="MainLeadCard_Button" onClick={() => contactButtons.email(member?.email)}>
                      <MdOutlineMail color='#81848A' size={30} />
                    </div>
                    <div className="MainLeadCard_Button" onClick={() => contactButtons.whatsapp(member?.phoneNumber)}>
                      <FaWhatsapp color='#81848A' size={30} />
                    </div>
                    <div className="MainLeadCard_Button" onClick={() => contactButtons.sms(member?.phoneNumber)}>
                      <MdOutlineMessage color='#81848A' size={30} />
                    </div>
                    <div className="MainLeadCard_Button" onClick={() => contactButtons.call(member?.phoneNumber)}>
                      <MdOutlinePhone color='#81848A' size={30} />
                    </div>
                  </div>
                </div>
              )
            }
          )
        }

        {
          loading &&
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
          }}>
            <CircularProgress size={40} sx={{
              color: '#2761CF',
            }} />
          </div>
        }
      </div>
    </div>
  )
}

export default TeamMembers