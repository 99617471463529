import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './issueRescheduleDetails.scss';
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CustomerApi from '../../../../API/CustomerApi';
import StringIntReq from '../../../../Requests/StringIntReq';
import UpdateCustomerInstallDateReq from '../../../../Requests/Customer/UpdateCustomerInstallDateReq';
import Layout from '../../../../Layouts/Layout';
import { CircularProgress } from '@mui/material';
import { IoArrowBack } from 'react-icons/io5';
const RescheduleDetailsPage = () => {
    const { id } = useParams();
    const [issueDetails, setIssueDetails] = useState(null);
    const [NewSelectedDateFrom, setNewSelectedDateFrom] = useState(null);
    const [NewSelectedDateTo, setNewSelectedDateTo] = useState(null);
    const [NewAssignDateFrom, setNewAssignDateFrom] = useState(null);
    const [NewAssignDateTo, setNewAssignDateTo] = useState(null);
    const [selectedTimeRange, setSelectedTimeRange] = useState("");
    const [installTimeFrom, setInstallTimeFrom] = useState("");
    const [installTimeTo, setInstallTimeTo] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [phone, setPhone] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchIssueDetails = async () => {
            let req = new StringIntReq();
            req.int = id;
            const res = await CustomerApi.GetRescheduleFormById(req);
            console.log(res);
            if (res?.status?.success) {
                setIssueDetails(res.data);
                setNewSelectedDateFrom(res.data.newInstallDate);
                setNewSelectedDateTo(res.data.newInstallDateTo);
                setPhone(res.data.phoneNumber);
                setIsLoading(false);
            }
        };

        if (id) {
            fetchIssueDetails();
        }

    }, [id, setIsLoading]);

    const handleForceResolve = async () => {
        setIsLoading(true);
        let req = new StringIntReq();
        req.string = "Reschedule";
        req.int = id;
        const res = await CustomerApi.ForceResolveIssue(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Issue Resolved",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                },
                didClose: () => {
                    setIsLoading(false);
                    window.location.reload();
                }

            });
        } else {
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                }
            });
        }
    };
    const formatDateToISOString = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

        // Concatenate without timezone offset
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };
    const handleConfirmReschedule = async () => {
    
        const req = new UpdateCustomerInstallDateReq();
        req.customerId = issueDetails.fidiumCustomerId;
        req.installDateTime = NewSelectedDateFrom;
        req.installDateTimeTo = NewSelectedDateTo;
        req.rescheduleFormId = id;
    
        if (!NewAssignDateFrom) {
            Swal.fire({
                icon: "error",
                title: "Please Assign A Date",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                },
                // didClose: () => {
                //     setIsLoading(false);
                //     window.location.reload();
                // }
            });
            return
        }

        if (!installTimeFrom || !installTimeTo) {
            Swal.fire({
                icon: "error",
                title: "Please Select Time Slot",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                },
                // didClose: () => {
                //     setIsLoading(false);
                //     window.location.reload();
                // }
            });
            return
        }

      
        let date = new Date().setMonth(NewAssignDateFrom.split('-')[1] - 1)
        date = new Date(date).setDate(NewAssignDateFrom.split('-')[2])
        date = new Date(new Date(date).setFullYear(NewAssignDateFrom.split('-')[0]))
        let timeFrom = formatDateToISOString(new Date(date.setHours(installTimeFrom.split(":")[0], installTimeFrom.split(":")[1], 0, 0)));
        let timeTo = formatDateToISOString(new Date(date.setHours(installTimeTo.split(":")[0], installTimeTo.split(":")[1], 0, 0)));

        req.installDateTime = timeFrom;
        req.installDateTimeTo = timeTo;

        setIsLoading(true);
        const res = await CustomerApi.EditCustomerInstallDate(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Reschedule Confirmed",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                },
                didClose: () => {
                    setIsLoading(false);
                    window.location.reload();
                }
            });
        } else {
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                title: res?.status?.message,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading();
                },
                didClose: () => {
                    setIsLoading(false);
                    window.location.reload();
                }
            });
        }
    };

    return (
        <Layout>
            {isLoading ?
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: "100%",
                    height: "100%"
                }}>
                    <CircularProgress size={50} sx={{
                        color: "var(--primary-color)"
                    }} />
                </div>
                :
                <>
                    <IoArrowBack size={30} color='black' style={{
                        marginLeft: "1rem",
                        cursor: "pointer"
                    }} onClick={() => { navigate(-1) }} />
                    <div className='reschedule_form_container'>

                        <h1 className='title'>Reschedule Issue</h1>
                        <div className='inputs'>
                            <label className='phoneLabel' htmlFor="payless">Customer Name</label>
                            <input className='datePicker' type="text" value={issueDetails?.fidiumCustomer?.firstName + " " + issueDetails?.fidiumCustomer?.lastName} disabled={true} />
                        </div>
                        <div className='inputs'>


                            <label className='inputLabel' htmlFor="phoneNumber"> Phone Number</label>
                            <PhoneInput
                                country={'us'}
                                value={phone}
                                onChange={setPhone}
                                excludeCountries={['il']}
                                style={{ width: "100%" }}
                                inputStyle={{ width: "100%" }}
                                disabled={true}
                            />

                        </div>

                        {issueDetails?.fidiumCustomer.originallInstallDateTimeFrom &&
                            <div className='inputs'>
                                <label className='inputLabel' htmlFor="rescheduleDate">Original Date From</label>
                                <input
                                    className='datePicker'
                                    type='datetime-local'
                                    value={issueDetails?.fidiumCustomer.originallInstallDateTimeFrom}
                                    disabled={true}
                                />
                            </div>
                        }

                        {issueDetails?.fidiumCustomer.originalIntstallDateTimeTo &&
                            <div className='inputs'>
                                <label className='inputLabel' htmlFor="rescheduleDate">Original Date To</label>
                                <input
                                    className='datePicker'
                                    type='datetime-local'
                                    value={issueDetails?.fidiumCustomer.originalIntstallDateTimeTo}
                                    disabled={true}
                                />
                            </div>
                        }


                        {issueDetails?.fidiumCustomer.updatedInstallDateTimeFrom &&
                            <div className='inputs'>
                                <label className='inputLabel' htmlFor="rescheduleDate">Last Updated Date From</label>
                                <input
                                    className='datePicker'
                                    type='datetime-local'
                                    value={issueDetails?.fidiumCustomer.updatedInstallDateTimeFrom}
                                    disabled={true}
                                />
                            </div>
                        }

                        {issueDetails?.fidiumCustomer.updatedInstallDateTimeTo &&
                            <div className='inputs'>
                                <label className='inputLabel' htmlFor="rescheduleDate">Last Updated Date To</label>
                                <input
                                    className='datePicker'
                                    type='datetime-local'
                                    value={issueDetails?.fidiumCustomer.updatedInstallDateTimeTo}
                                    disabled={true}
                                />
                            </div>
                        }

                        {!issueDetails?.isResolved &&
                            <div className='inputs' style={{ marginTop: "2rem" }}>
                                <label className='inputLabel' htmlFor="rescheduleDate">Customer's New Desired Date From</label>
                                <input
                                    className='datePicker'
                                    type='datetime-local'
                                    value={NewSelectedDateFrom}
                                    disabled={true}
                                />
                            </div>
                        }

                        {!issueDetails?.isResolved &&
                            <div className='inputs' >
                                <label className='inputLabel' htmlFor="rescheduleDate">Customer's New Desired Date To</label>
                                <input
                                    className='datePicker'
                                    type='datetime-local'
                                    value={NewSelectedDateTo}
                                    disabled={true}
                                />
                            </div>
                        }


                        {!issueDetails?.isResolved &&

                            <div className='inputs' style={{ marginTop: "2rem" }}>
                                <label className='inputLabel' htmlFor="rescheduleDate">Assign a New Date</label>
                                <input
                                    className='datePicker'
                                    type='date'
                                    value={NewAssignDateFrom}
                                    onChange={(e) => setNewAssignDateFrom(e.target.value)}
                                />
                            </div>
                        }

                        {!issueDetails?.isResolved &&

                            <div className='inputs' style={{ gap: "0.5rem", display: "grid" }}>
                                <label className='inputLabel' htmlFor="rescheduleDate">Assign a Time Slot</label>
                                {/* <input
                                    className='datePicker'
                                    type='datetime-local'
                                    value={NewAssignDateTo}
                                    onChange={(e) => setNewAssignDateTo(e.target.value)}
                                /> */}
                                <div className='datePicker' style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr auto 1fr",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    gap: "1rem"
                                }}>
                                    <input type="time" value={installTimeFrom} onChange={(e) => setInstallTimeFrom(e.target.value)} />
                                    <p>TO</p>
                                    <input type="time" value={installTimeTo} onChange={(e) => setInstallTimeTo(e.target.value)} />
                                </div>
                                <p style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                }}>OR</p>
                                <div className='am_pm'>
                                    <p onClick={
                                        () => {
                                            setSelectedTimeRange("AM");
                                            setInstallTimeFrom("08:00")
                                            setInstallTimeTo("12:00")
                                        }
                                    } className={`${selectedTimeRange === "AM" ? 'active' : ''}`} >AM</p>
                                    <p onClick={
                                        () => {
                                            setSelectedTimeRange("PM");
                                            setInstallTimeFrom("12:00")
                                            setInstallTimeTo("18:00")
                                        }
                                    } className={`${selectedTimeRange === "PM" ? 'active' : ''}`}>PM</p>
                                </div>
                            </div>
                        }

                        {/* {issueDetails?.isResolved && (
                    <div className='inputs'>
                        <label className='inputLabel' htmlFor="rescheduleDate">New Assigned Date From</label>
                        <input
                        className='datePicker'
                        value={new Date(issueDetails.fidiumCustomer.installDateTime).toLocaleString()}
                        aria-disabled={true}
                        placeholder='Select Installation Date'
                        disabled={true}
                        />
                    </div>
            )}
            {issueDetails?.isResolved && (
                    <div className='inputs'>
                        <label className='inputLabel' htmlFor="rescheduleDate">New Assigned Date To</label>
                        <input
                        id="createdAtFrom"
                        className='datePicker'
                        value={new Date(issueDetails.fidiumCustomer.updatedInstallDateTimeTo).toLocaleString()}
                            aria-disabled={true}
                            placeholder='Select Installation Date'
                            disabled={true}
                            />
                    </div>
            )} */}
                        {!issueDetails?.isResolved && (
                            <>
                                <button style={{ backgroundColor: "#2761D0" }} className='submitBtn' onClick={handleConfirmReschedule}>Confirm Reschedule</button>
                                <button style={{ backgroundColor: "#d32f2f" }} className='submitBtn' onClick={handleForceResolve}>Force Resolve</button>
                            </>
                        )}
                    </div>
                </>
            }
        </Layout>
    );
};

export default RescheduleDetailsPage;
