/* eslint-disable react-hooks/exhaustive-deps */
// import { Rating } from '@mui/material'
import React, { useContext, useEffect, useRef } from 'react'
import {MdOutlineLocalPhone, MdOutlineMail } from "react-icons/md";
// import { FaPhone } from "react-icons/fa6";
// import { MdEmail } from "react-icons/md";
// import MyReviews from './MyReviews';
import { UserContext } from '../../../../../App';
// import ReviewContainer from '../../../../Reps/DigitalBusinessCenter/components/ReviewContainer';
// import greyUser from './../../../../../assets/images/userGrey.png'
import { GiWireframeGlobe } from 'react-icons/gi';
import QRCode from 'qrcode.react';
const BizCard = ({ salesRep }) => {

    const user = useContext(UserContext)
    console.log(user);
    const videoRef = useRef(null)
    const getImage = () => {
        if (salesRep) {
            return salesRep.profileImage ? salesRep.profileImage.url : null
        }

        return user?.profileImage ? user.profileImage.url : null
    }

    const getVideo = () => {
        if (salesRep) {
            return salesRep.profileVideo ? salesRep.profileVideo.url : null
        }

        return user?.profileVideo ? user.profileVideo.url : null

    }

    useEffect(
        () => {
            if (videoRef.current) {
                videoRef.current.load()
            }
        }, [videoRef.current]
    )

    return (
        <div style={{
            backgroundImage: "url('/images/Banner/background.png')"
        }} className='BizCard'>
            {/* Part 1 */}
            <div className='part1'>
                <h1>{salesRep ? salesRep?.firstName + " " + salesRep.lastName : user?.firstName + " " + user?.lastName}</h1>
                <h3>Fiber Specialist</h3>
                {/* <div className='rating'>
                    <Rating name="read-only" value={4} readOnly />
                    <p>(744 reviews)</p>
                </div> */}
                {/* image and video */}

                <div className='imageAndVideo'>
                    <div style={{
                        border: (salesRep?.profileImage || user?.profileImage) ? "none" : "1px solid black",
                    }} className='image'>
                        {(salesRep?.profileImage || user?.profileImage) ? <img style={{
                            objectFit: salesRep?.profileImage ? 'cover' : user?.profileImage ? "cover" : "contain"
                        }} src={getImage()} alt="Shasta Weishampel" />
                            :
                            <img src={'/images/Avatar/profile.jpg'} alt="Shasta Weishampel" />
                        }
                    </div>


                    {
                        (salesRep?.profileVideo || user?.profileVideo) &&
                        <div className='video'>
                            <video ref={videoRef} controls src={getVideo() + "#t=0.001"} alt="video" />
                        </div>}
                </div>
                {/* My Reviews */}
                {/* <MyReviews /> */}

            </div>
            {/* Part 2 */}
            <div className='part2'>
                {/* Logo */}
                <div className='fiber-logo'>
                    <img src='/images/nav/nav-logo.png' alt='logo' />
                </div>
                {/* email, location, number */}

                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gap: "1rem",
                    // placeItems: "center",
                }}>
                    <div className='userInfo' >
                        <div className='element'>
                            <GiWireframeGlobe color='black' size={30} />
                            <p>{salesRep ? salesRep?.address : user?.address}</p>
                        </div>
                        <div className='element'>
                            <MdOutlineLocalPhone color='black' size={30} />
                            <p>{salesRep ? salesRep?.phoneNumber : user?.phoneNumber}</p>
                        </div>
                        <div className='element'>
                            <MdOutlineMail color='black' size={30} />
                            <p>{salesRep ? salesRep?.email : user.email}</p>
                        </div>
                    </div>
                    {/* <div style={{
                        width:"100%",
                        display:"flex",
                        justifyContent:"flex-end",
                        alignItems:"center",
                        // marginTop:"-5rem"
                    }}> */}
                        <QRCode size={100} style={{margin:"auto"}} value={window.location.origin + "/digital-business-center/" + user.id} />
                    {/* </div> */}
                </div>

            </div>
            {/* <div className='trustedPartner'>
                <h3>Trusted Fiber Partners</h3>
                <div>
                    <img src='/images/NetworkTeam/fidium.png' alt='fidium' />
                    <img src='/images/NetworkTeam/verzionLogo.png' alt='' />
                    <img src='/images/NetworkTeam/welink.png' alt='' />
                    <img src='/images/NetworkTeam//brightSpeed.png' alt='' />
                </div>
            </div> */}
            <div style={{
                gridColumn: "span 5/span 5",
            }}>

                {/* <ReviewContainer salesRepId={salesRep?.id} /> */}
            </div>
        </div>
    )
}

export default BizCard