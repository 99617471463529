/* eslint-disable react-hooks/exhaustive-deps */
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useEffect, useState,useRef } from 'react';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import UsersApi from '../../../../API/UsersApi';
import { UserContext } from '../../../../App';
import CreateFile from '../../../../Requests/CloudFile/CreateFileReq';
import UpdateUserImageAndVideoReq from '../../../../Requests/Users/UpdateUserImageAndVideoReq';
import userGrey from './../../../../assets/images/userGrey.png';
import { MdEdit } from "react-icons/md";
const ProfileImageVideo = () => {

  const user = useContext(UserContext);
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const videoRef = useRef(null); // Create a ref for the video element
  const storage = getStorage();
  const [userImage, setUserImage] = useState(user.profileImage);
  const [userVideo, setUserVideo] = useState(user.profileVideo);

  useEffect(
    ()=>{
      if(user.userType==="Customer" && !user.profileImage){
        
      }
    },[]
  )

  const updateUserImageAndVideo = async () => {

    if (!image && !video) {
      return;
    }
    const req = new UpdateUserImageAndVideoReq();
    let img = null;
    let vid = null;
    let downloadUrls = [];
    let filePaths = [];
    const uploadAndGetData = async (file, type) => {
      // Generate a unique ID 
      const uniqueId = uuidv4();

      const storageRef = ref(storage, `/profileImageAndVideo/${user.id}/${uniqueId}`);
      await uploadBytes(storageRef, file);
      // Get download URL and push to downloadURLs array
      const downloadURL = await getDownloadURL(storageRef);
      downloadUrls.push(downloadURL);
      
      filePaths.push(`/profileImageAndVideo/${user.id}/${uniqueId}`)
    };
    if (image) {

      if(userImage){
        try{
          const storageRef = ref(storage, userImage.path);
          await deleteObject(storageRef);
        }
        catch(e){
          console.log(e)
        }
      }

      img = new CreateFile();
      Swal.fire({
        icon: 'info',
        title: 'Updating Image',
        text: 'Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });
      await uploadAndGetData(image, 'image')
      img.name = image.name;
      img.path = filePaths[0];
      img.url = downloadUrls[0];
      img.fileType = 'image';
      img.extension = image.type;
      img.fileSize = image.size.toString();
      img.caption = 'Profile Image';
      img.userId = user.id;
    }
    if (video) {

      if(userVideo){
        try{
          const storageRef = ref(storage, userVideo.path);
          await deleteObject(storageRef);
        }
        catch(e){
          console.log(e)
        }
      }

      downloadUrls = [];
      filePaths = [];
      vid = new CreateFile();
      Swal.fire({
        icon: 'info',
        title: 'Updating Video',
        text: 'Please wait...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });
      await uploadAndGetData(video, 'video')
      vid.name = video.name;
      vid.path = filePaths[0];
      vid.url = downloadUrls[0];
      vid.fileType = 'video';
      vid.extension = video.type;
      vid.fileSize = video.size.toString();
      vid.caption = 'Profile Video';
      vid.userId = user.id;
    }
    req.ProfileImage = img;
    req.ProfileVideo = vid;
    req.UserId = user.id;
    
    let res = await UsersApi.UpdateUserImageAndVideo(req);
    if (res?.status?.success) {
      
      setImage(null);
      setVideo(null);
      setUserImage(res.data.profileImage);
      setUserVideo(res.data.profileVideo);  
      Swal.fire({
        icon: 'success',
        title: 'Updated',
        text: 'Profile Image/Video Updated Successfully',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
    }
    else {
      setImage(null);
      setVideo(null);

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: res.message,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: true,
        didOpen: () => {
          Swal.hideLoading()
        }
      });
    }
  }

  const fetchImageAndVideo = async () => {
    let res = await UsersApi.GetCurrentUser();
    
    if (res?.status?.success) {
      console.log(res.data)
      setUserImage(res.data.profileImage);
      setUserVideo(res.data.profileVideo);
    }
  
  }

  useEffect(() => {
    fetchImageAndVideo();
  }, [])

  useEffect(
    () =>{
      if(image || video){
        updateUserImageAndVideo()
      }
    },[image,video]
  )

  useEffect(
    () =>{
      if (videoRef.current) {
        videoRef.current.load(); 
      }
    },[video,userVideo]
  )

  const getVideo = () => {
    if(video){
       console.log(URL.createObjectURL(video))
        return URL.createObjectURL(video)
    }
    else{
        if(userVideo){
            return userVideo.url
        }
        else{
            return "https://placehold.co/1920x1080.mp4"
        }
    }
  }


  return (
    <div className='profileImageVideo' >
      <p >Profile Picture/Video</p>
      <div className='image-videoContainer' >
        <div className='image-container' >
          {image ? (
            <img src={URL.createObjectURL(image)} alt="Customer Feedback Analysis" />
          ) : (
            <img src={userImage ? userImage.url : userGrey} style={{
              objectFit:userImage ? 'cover' : 'contain'
            }} alt="Customer Feedback Analysis" />
          )}
          <input type="file" id="image" name="image" accept="image/*" onChange={(e) => setImage(e.target.files[0])} />
          <label htmlFor='image' ><MdEdit color='#2761D0' size={20} /></label>
        </div>
        <div className='video-container'>
          {/* <img src="/images/Avatar/dummy.png" alt="Customer Feedback Analysis" /> */}
          {
              <video ref={videoRef} controls>
                <source src={getVideo()} type="video/mp4" />
                Your browser does not support the video tag.
              </video> 
          }
          <input type="file" id="video" name="video" accept="video/*" onChange={(e) =>{ setVideo(e.target.files[0]);}} />
          <label htmlFor='video' ><MdEdit color='#2761D0' size={20} /></label>
        </div>
      </div>
    
    </div>
  )
}

export default ProfileImageVideo