import React from 'react'
import Swal from 'sweetalert2'
import MapIconApi from '../../../../API/MapIconApi'
import Input from '../../../../components/Input'
import CreateMapIconReq from '../../../../Requests/MapIcon/CreateMapIconReq'
const CreateMapIcon = () => {

    const [mapIconName, setMapIconName] = React.useState("");
    const [mapIconImage, setMapIconImage] = React.useState(null);
    const [abbreviation, setAbbreviation] = React.useState("");

    const createMapICon = () => {
        Swal.fire({
            icon: "info",
            title: "Creating Map Icon",
            text: "Please Wait ...",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if (!mapIconImage) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please Select an Image",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        if (!mapIconName) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please Enter a Name",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: true,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return;
        }

        let req = new CreateMapIconReq();
        req.Name = mapIconName;
        req.Image = mapIconImage;
        req.Abbreviation = abbreviation;
        // req.SalesOrgId = user?.salesOrgId?user?.salesOrgId:"";
        req.SalesOrgId = "";
        MapIconApi.CreateMapIcon(req).then(
            (res) => {
                if (res?.status?.success) {
                    Swal.fire(
                        {
                            title: "Map Icon Created",
                            text: "Map Icon has been created",
                            icon: "success",
                            didOpen: () => {
                                Swal.hideLoading()
                            }
                        }
                    )
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: res?.status?.message,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: true,
                        didOpen: () => {
                            Swal.hideLoading()
                        }
                    });
                }
            }
        )

    }
    return (
        <div className='flex flex-col gap-4 w-full m-auto px-4 CreateMapIconDiv'>
            <div className='TitleDiv'>
                <h1 >Create Map Icon</h1>
                {/* <button className='CloseButton' onClick={() => map.closeTab()}>X</button> */}
            </div>
            <div className='CreateMapIconForm'>
                <Input label="Name" value={mapIconName} onChange={(e) => setMapIconName(e.target.value.slice(0, 120))} placeholder="NAME" />
                <Input label="Abbreviation" value={abbreviation} onChange={(e) => setAbbreviation(e.target.value.slice(0, 32))} placeholder="Abbreviation" />

                <input  className="hidden" type="file" id="fileUpload" onChange={(e) => setMapIconImage(e.target.files[0])} placeholder="Enter image" />
                <label htmlFor="fileUpload" className="cursor-pointer overflow-hidden rounded-lg border-2 border-[#C4C4C4] bg-white text-center text-black text-base focus:outline-[#181E4B] flex justify-between items-center px-8 py-2">
                    <div className='flex items-center'>
                        <p className="mr-2">{mapIconImage ? mapIconImage.name : "Select Image"}</p>
                        {mapIconImage && (
                            <div className='w-8 h-8'>
                                <img src={URL.createObjectURL(mapIconImage)} className='' alt="UploadedImage" />
                            </div>
                        )}
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
                        <path d="M5 12.9987H9C9.55 12.9987 10 12.5487 10 11.9987V6.99869H11.59C12.48 6.99869 12.93 5.91869 12.3 5.28869L7.71 0.698694C7.61749 0.60599 7.5076 0.532443 7.38662 0.482261C7.26565 0.43208 7.13597 0.40625 7.005 0.40625C6.87403 0.40625 6.74435 0.43208 6.62338 0.482261C6.5024 0.532443 6.39251 0.60599 6.3 0.698694L1.71 5.28869C1.08 5.91869 1.52 6.99869 2.41 6.99869H4V11.9987C4 12.5487 4.45 12.9987 5 12.9987ZM1 14.9987H13C13.55 14.9987 14 15.4487 14 15.9987C14 16.5487 13.55 16.9987 13 16.9987H1C0.45 16.9987 0 16.5487 0 15.9987C0 15.4487 0.45 14.9987 1 14.9987Z" fill="#2761D0" />
                    </svg>
                </label>
            </div>
                <button className='GreenCreateButton' onClick={createMapICon}>Create</button>
        </div>
    )
}

export default CreateMapIcon