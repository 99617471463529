import { CircularProgress } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import React, { useContext, useEffect } from 'react'
import { UserContext } from '../../../App'
import Datatable from '../../../components/Datatable'
import Layout from '../../../Layouts/Layout'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import { PaginationItem } from '@mui/material'
import CustomerApi from '../../../API/CustomerApi'
import SearchCustomerFeedbackReq from '../../../Requests/CustomerFeedback/SearchCustomerFeedbackReq'
import UserSelector from '../../../components/UserSelector'
import StringIntReq from '../../../Requests/StringIntReq'
import OrganizationSelector from '../../../components/OrganizationSelector'
import Swal from 'sweetalert2'
const Index = () => {

    const user = useContext(UserContext)

    const [customerFeedbacks, setCustomerFeedbacks] = React.useState([])
    const [userId, setUserId] = React.useState([])
    const [salesRepId, setSalesRepId] = React.useState(null)
    const [organizationId, setOrganizationId] = React.useState(null)
    const [rating, setRating] = React.useState()
    const [feedbackType, setFeedbackType] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [currentPage, setCurrentPage] = React.useState(1)
    const [totalPages, setTotalPages] = React.useState(1)



    const searchCustomerFeedbacks = () => {
        setLoading(true)
        const req = new SearchCustomerFeedbackReq()
        req.salesRepId = salesRepId
        req.fidiumCustomerIds = userId
        req.rating = rating
        req.salesOrgId = organizationId
        req.feedbackType = feedbackType
        req.pagingParams.pageNumber = currentPage
        req.pagingParams.pageSize = 20

        CustomerApi.SearchCustomerFeedback(req).then(
            (res) => {
                
                const l = []
                res?.data?.list.map(
                    (item) => {

                            l.push({ ...item, pay: item.rewardApproved?
                                <p className=' text-green-500 cursor-pointer' >Approved</p>
                                :<p onClick={()=>{
                                approveCustomerFeedbackUrl(item.id)
                            }} className='text-[#2761D0] underline cursor-pointer' >Approve Reward</p>,
                            feedbackVideo:item.video?.id?<a href={item.video.url} target='_blank' className='text-[#2761D0] underline cursor-pointer' >View Video</a>:"No Video",
                            speedTestImage:item.speedTest?<div className='flex'>
                                <img src={"data:image/png;base64,"+item.speedTest.beforeImage} alt="Speed Test" className='w-[100px] h-[100px]' />
                                <img src={"data:image/png;base64,"+item.speedTest.afterImage} alt="Speed Test" className='w-[100px] h-[100px]' />
                            </div>:"No Speed Test",
                            content:item.content?item.content:"No Content",
                            salesRepName:item.salesRep?item.salesRep.firstName+" "+item.salesRep.lastName:"No Sales Rep",
                            customerName:item.fidiumCustomer?item.fidiumCustomer.firstName+" "+item.fidiumCustomer.lastName:"No Customer",
                         
                        })
                    }

                )
                console.log(res.data)
                setTotalPages(res?.data?.totalPages)
                setCustomerFeedbacks(l)
                setLoading(false)
            }
        )
    }

  const approveCustomerFeedbackUrl = async (id) =>{
    Swal.fire({
        icon:"info",
        title:"Please wait...",
        text:"Approving feedback",
        showConfirmButton:false,
        allowOutsideClick:false,
        allowEscapeKey:false,
        allowEnterKey:false,
        showCloseButton:false,
        showCancelButton:false,
        didOpen: ()=>{
            Swal.showLoading()
        }
    })
    const req = new StringIntReq();
    req.int = id;
    const res = await CustomerApi.ApproveCustomerFeedbackReward(req);
    if(res?.status?.success){
        Swal.fire({
            icon:"success",
            title:"Customer feedback Reward approved",
            showConfirmButton:false,
            didOpen:()=>{
                Swal.hideLoading()
            }
        })
        searchCustomerFeedbacks();
    }
    else{
        Swal.fire({
            icon:"error",
            title:"Failed to approve feedback",
            text:res?.status?.message,
            showConfirmButton:false,
            didOpen:()=>{
                Swal.hideLoading()
            }
        })
    
    }

  }

    const headers = [
        {
            name: "Feedback ID",
            key: "id"
        },
        {
            name: "Customer Name",
            key: "customerName"
        },
        {
            name:"Sales Rep",
            key:"salesRepName"
        },
        {
            name: "content",
            key: "content"
        },
        {
            name: "Speed Test",
            key: "speedTestImage"
        },
        {
            name:"Video",
            key:"feedbackVideo"
        },
        {
            name: "Rating",
            key: "rating"
        },
        {
            name: "Feedback Type",
            key: "feedbackType"
        },
        {
            name:"Rewarded",
            key:"rewarded"
        },
        {
            name:"Approve Reward",
            key:"pay"
        }
    ]

    useEffect(
        () => {
            searchCustomerFeedbacks()
        }, [currentPage]
    )

    
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
        searchCustomerFeedbacks();
    }
};

    return (
        <Layout>
            <div className='manageLeadsContainer' >
                <div className='manageLeadsHeader'>
                    <h1 className='manageLeadsTitle'>Manage Customer Feedbacks</h1>
                    <div className='searchFiltersInput' >
                        <UserSelector placeholder='Search Customer' onKeyDown={handleKeyPress} setUser={setUserId} conversationType={'Group'} onlySelectedUserType={true} userType={'Customer'} />
                        <UserSelector placeholder='Search By SalesRep' onKeyDown={handleKeyPress} setUser={setSalesRepId}  userType={'SalesRep'} />
                        <OrganizationSelector placeholder='Search By Organization' setUser={setOrganizationId} />
                        <select onChange={(e) => { 
                            if(e.target.value!=="0")setRating(e.target.value) 
                            else setRating(null) }} >
                            <option value={0} >Rating</option>
                            <option value={1} >1</option>
                            <option value={2} >2</option>
                            <option value={3} >3</option>
                            <option value={4} >4</option>
                            <option value={5} >5</option>
                        </select>
                        <select className='single' onChange={(e)=>{
                            if(e.target.value!=="0") setFeedbackType(e.target.value)
                            else setFeedbackType(null)
                        }}>
                            <option value={0} >Feedback Type</option>
                            <option value="SpeedTest" >Speed Test</option>
                            <option value="Video" >Video</option>
                            <option value="Written" >Written</option>
                        </select>
                    </div>
                    <button onClick={searchCustomerFeedbacks} >Search</button>
                </div>
                {
                    loading ?
                        <div className='spinner'>
                            <CircularProgress sx={{color:"var(--color-icon)"}} />
                        </div>
                        :
                        <>
                            <Datatable headers={headers} list={customerFeedbacks} currentPage={currentPage} totalPages={totalPages} />
                        </>
                }
                <div className='pagination'>
                    <Pagination color='light' renderItem={(item)=>(
                    <PaginationItem  components={{
           
                        next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                        first: (props) => <button {...props}>First</button>,
                        previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                      }}  {...item} sx={{color: "white"}} />
                )} count={totalPages} page={currentPage} onChange={(e, v) => { setCurrentPage(v) }} />
                </div>
            </div>
        </Layout>
    )
}

export default Index