import React from 'react'
import Swal from 'sweetalert2'
import CreateRescheduleFormReq from '../../../Requests/Customer/CreateRescheduleFormReq'
import CustomerApi from '../../../API/CustomerApi'

const RescheduleForm = ({ lead }) => {

    const [scheduleDate, setScheduleDate] = React.useState('')
    const [scheduleDateTo, setScheduleDateTo] = React.useState('')
    const [timeFrom, setTimeFrom] = React.useState('')
    const [timeTo, setTimeTo] = React.useState('')
    const [selectedTimeRange, setSelectedTimeRange] = React.useState('')
    const formatDateToISOString = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

        // Concatenate without timezone offset
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };
    const submitReschedule = async () => {

        Swal.fire({
            icon: "info",
            title: "Please Wait",
            text: "Submitting Reschedule Request",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            customClass: {
                container: 'custom-swal'
            },
            willOpen: () => {
                Swal.showLoading();
            },
        })

        if (!scheduleDate ) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select Install Date and Time",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(!timeFrom || !timeTo){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select Install Time",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if(timeFrom > timeTo){
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Invalid Time Range",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!lead?.fidiumCustomer) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "There is no customer associated with this lead",
                customClass: {
                    container: 'custom-swal'
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        const req = new CreateRescheduleFormReq();
        req.FidiumCustomerId = lead.fidiumCustomer.id;

        let date = new Date();
        date = new Date(date.setMonth(parseInt(scheduleDate.split("-")[1]) - 1));
        date = new Date(date.setFullYear(parseInt(scheduleDate.split("-")[0])));
        date = new Date(date.setDate(parseInt(scheduleDate.split("-")[2])));
        let from = formatDateToISOString(new Date(date.setHours(timeFrom.split(":")[0], timeFrom.split(":")[1], 0, 0)));
        let to = formatDateToISOString(new Date(date.setHours(timeTo.split(":")[0], timeTo.split(":")[1], 0, 0)));

        req.NewInstallDate = from;
        req.NewInstallDateTo = to;
        const res = await CustomerApi.RescheduleForm(req);
        if (res?.status?.success) {
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Reschedule Request Submitted Successfully",
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: "Close",

                customClass: {
                    container: 'custom-swal'
                },
                willOpen: () => {
                    Swal.hideLoading()
                }
            })

        }
        else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message,
                customClass: {
                    container: 'custom-swal'
                },
                willOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
    }


    return (
        <div className='form'>
            <h2>Reschedule Installation Form</h2>
            <div className='input_div'>
                <label>Scheduled Date</label>
                <input type='date' value={scheduleDate} onChange={(e) => setScheduleDate(e.target.value)} />
            </div>
            <div className='input_div'>
                <label>Scheduled Time Slot</label>
                <div className='time_slot'>
                    <input type="time" value={timeFrom} onChange={(e) => setTimeFrom(e.target.value)} />
                    <p>TO</p>
                    <input type="time" value={timeTo} onChange={(e) => setTimeTo(e.target.value)} />
                </div>
                <p style={{
                    textAlign: 'center',
                    fontWeight:"bold"
                }}>OR</p>
                <div className='am_pm'>
                    <p onClick={
                        () => {
                            setSelectedTimeRange("AM");
                            setTimeFrom("08:00")
                            setTimeTo("12:00")
                        }
                    } className={`${selectedTimeRange === "AM" ? 'active' : ''}`} >AM</p>
                    <p onClick={
                        () => {
                            setSelectedTimeRange("PM");
                            setTimeFrom("12:00")
                            setTimeTo("18:00")
                        }
                    } className={`${selectedTimeRange === "PM" ? 'active' : ''}`}>PM</p>
                </div>
            </div>
            <button onClick={() => {
                submitReschedule()
            }}>Submit</button>
        </div>
    )
}

export default RescheduleForm