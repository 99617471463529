import { CircularProgress, Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DataTable from "../../../components/Datatable"
import Layout from '../../../Layouts/Layout'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import { PaginationItem } from '@mui/material'
import UserSelector from '../../../components/UserSelector'
import SearchCommissionTrackersReq from '../../../Requests/ComissionPlan/SearchCommissionTrackersReq'
import ComissionApi from '../../../API/ComissionApi'
import Swal from 'sweetalert2'
import StringIntReq from '../../../Requests/StringIntReq'
import BulkPayCommissionTrackersReq from '../../../Requests/ComissionPlan/BulkPayCommissionTrackersReq'
import QuickDate from "./../../../components/QuickDate"
const Index = () => {

    const [userId, setUserId] = useState(null)
    const [amountFrom, setAmountFrom] = useState(null)
    const [amountTo, setAmountTo] = useState(null)
    const [type, setType] = useState(null)
    const [paid, setPaid] = useState(null)
    const [timeKeyFrom, setTimeKeyFrom] = useState(null)
    const [timeKeyTo, setTimeKeyTo] = useState(null)
    const [loading, setLoading] = useState(false)
    const [commissionTrackers, setCommissionTrackers] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [selected, setSelected] = useState([])
    const headers = [
        {
            key: "select",
            name: "Select"
        },
        {
            key: "id",
            name: "ID"
        },
        {
            key: "userId",
            name: "User ID"
        },
        {
            key: "amount",
            name: "Amount"
        },
        {
            key: "commissionPlanId",
            name: "Commission Plan ID"
        },
        {
            key: "type",
            name: "Type"
        },
        {
            key: "paid",
            name: "Paid"
        },
        {
            key: "saleId",
            name: "Sale ID"
        },

        {
            key: "timeKeyType",
            name: "Time Key Type"
        },
        {
            name: 'Time Key Volume',
            key: 'timeKeyVolume'
        },
        {
            key: "commissionScale",
            name: "Commission Scale"
        },
        {
            key: "weekRange",
            name: "Week Range"
        },
        {
            key: "recruitId",
            name: "Recruit ID"
        },
        {
            key: "pay",
            name: "Pay"
        }
    ]

    function Button({ onClick, children }) {
        return <button style={{
            backgroundImage: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",
            color: "white",
            padding: "0.5rem 1rem",
            borderRadius: "10px"
        }} onClick={() => { onClick() }}>
            {children}
        </button>
    }

    async function bulkPayCommissionTrackers() {
        const opt = await Swal.fire({
            icon: "warning",
            title: "Are you sure you want to pay these commission trackers?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        })
        if (opt.isConfirmed) {
            Swal.fire({
                icon: "info",
                title: "Paying Commission Trackers...",
                showConfirmButton: false,
                showCloseButton: false,
                showDenyButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const req = new BulkPayCommissionTrackersReq()
            req.commissionTrackerIds = selected
            const res = await ComissionApi.BulkPayCommissionTrackers(req)
            if (res?.status?.success) {
                Swal.fire({
                    icon: "success",
                    title: res?.status?.message,
                    showConfirmButton: true,
                    showCloseButton: false,
                    showDenyButton: false,
                    showCancelButton: false,
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    allowEnterKey: true,
                })
                fetchData()
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error Paying Commission Trackers",
                    showConfirmButton: true,
                    showCloseButton: false,
                    showDenyButton: false,
                    showCancelButton: false,
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    allowEnterKey: true,
                })
            }


        }
    }

    async function payCommissionTracker(id) {
        const selected = await Swal.fire({
            icon: "warning",
            title: "Are you sure you want to pay this commission tracker?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            denyButtonText: "Cancel",
        })

        if (selected.isConfirmed) {

            Swal.fire({
                icon: "info",
                title: "Paying Commission Tracker...",
                showConfirmButton: false,
                showCloseButton: false,
                showDenyButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })

            const req = new StringIntReq();
            req.int = id
            const res = await ComissionApi.PayCommissionTracker(req);
            if (res?.status?.success) {
                Swal.fire({
                    icon: "success",
                    title: "Commission Tracker Paid Successfully",
                    showConfirmButton: true,
                    showCloseButton: false,
                    showDenyButton: false,
                    showCancelButton: false,
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    allowEnterKey: true,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
                fetchData()

            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error Paying Commission Tracker",
                    showConfirmButton: true,
                    showCloseButton: false,
                    showDenyButton: false,
                    showCancelButton: false,
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    allowEnterKey: true,
                    didOpen: () => {
                        Swal.hideLoading()
                    }
                })
            }
        }
    }



    async function fetchData() {
        setLoading(true)
        const req = new SearchCommissionTrackersReq();
        req.userId = userId
        req.amountFrom = amountFrom
        req.amountTo = amountTo
        req.type = type
        req.paid = paid
        req.timeKeyFrom = timeKeyFrom
        req.timeKeyTo = timeKeyTo
        req.pagingParams.pageNumber = currentPage
        req.pagingParams.pageSize = pageSize
        const res = await ComissionApi.SearchCommissionTrackers(req);
        if (res?.status?.success) {

            res?.data?.list.map((item) => {
                item.pay = !item.paid ? <Button onClick={() => {
                    payCommissionTracker(item.id)
                }}>Pay</Button> : <p>Paid</p>
                item.paid = item.paid ? "Yes" : "No";
                item.select = <input type="checkbox" className='selects' onChange={(e) => {
                    console.log(e.target.checked)
                    if (e.target.checked) {
                        setSelected((prev) => {
                            return [...prev, item.id]
                        })
                    } else {
                        setSelected((prev)=>{
                            return prev.filter((id)=>id!==item.id)
                        })
                    }
                }} />
            })

            setCommissionTrackers(res?.data?.list)
            setTotalPages(res.data.totalPages)
            setLoading(false)
        }

    }

    useEffect(
        () => {
            fetchData()
        }, [currentPage, pageSize]
    )
    return (
        <Layout>
            <div className='manageLeadsContainer' >
                <div className='manageLeadsHeader' >
                    <h1 className='manageLeadsTitle'>Manage Commission Trackers</h1>
                    <div className='searchFiltersInput' >
                        <div className='single'>
                        <UserSelector placeNone={true} setUser={setUserId} placeholder="Select a User" />
                        </div>
                        <input type="number" placeholder="Amount From" value={amountFrom} onChange={(e) => { setAmountFrom(e.target.value) }} />
                        <input type="number" placeholder="Amount To" value={amountTo} onChange={(e) => { setAmountTo(e.target.value) }} />
                        <select onChange={(e) => { setType(e.target.value) }} >
                            <option value={null}>Select Type</option>
                            <option value="Sliding Scale">Sliding Scale</option>
                            <option value="Recruitment Bonus">Recruitment Bonus</option>
                            <option value="Team Volume">Team Volume</option>
                            <option value="Second Level Team Volume">Second Level Team Volume</option>
                            <option value="Deal Saved">Deal Saved</option>
                            <option value="Prospect">Prospect</option>
                        </select>
                        <select onChange={(e) => {
                            if(e.target.value!=="0"){ 
                                setPaid(e.target.value === 'true')
                            } 
                            else{
                                setPaid(null)
                            }
                            }}>
                            <option value="0">Select Paid</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                        {/* <input type="date" placeholder="Time Key From" value={timeKeyFrom} onChange={(e) => { setTimeKeyFrom(e.target.value) }} /> */}
                        {/* <input type="date" placeholder="Time Key To" value={timeKeyTo} onChange={(e) => { setTimeKeyTo(e.target.value) }} /> */}
                        <QuickDate setFrom={setTimeKeyFrom} setTo={setTimeKeyTo} className={'single'} placeholder={'Quick Date'} />
                    </div>
                    <button disabled={loading} onClick={() => { setCurrentPage(1); fetchData() }}>{loading ? "SEARCHING..." : "SEARCH"}</button>
                </div>
                {loading ? <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%"
                }} ><CircularProgress sx={{ color: "var(--color-icon)" }} size={50} /></div> :
                    <>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            border: "1px solid var(--color-icon)",
                            padding: "0.5rem",
                            borderRadius: "10px",
                            width: "fit-content",
                        }}>
                            <p>Page Size</p>
                            <select value={pageSize} onChange={(e) => {
                                setPageSize(parseInt(e.target.value))
                            }
                            }>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3,1fr)",
                            gap: "1rem",
                            alignItems: "center",

                        }}>

                            <Button onClick={() => {
                                setSelected(commissionTrackers.map((item) => item.id));
                                document.querySelectorAll('.selects').forEach((item) => {
                                    item.checked = true
                                })
                            }}>Select  All</Button>
                            <Button onClick={
                                () => {
                                    setSelected([])
                                    document.querySelectorAll('.selects').forEach((item) => {
                                        item.checked = false
                                    })
                                }
                            }>Deselect All</Button>
                            <Button onClick={() => {
                                bulkPayCommissionTrackers()
                            }}>Pay ${
                                    selected.length > 0 ?
                                        commissionTrackers.filter((item) => selected.includes(item.id)).reduce((acc, item) => {
                                            return item.paid === "Yes" ? acc : acc + item.amount
                                        }
                                            , 0)
                                        : 0

                                }</Button>
                        </div>
                        <DataTable currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} headers={headers} list={commissionTrackers ? commissionTrackers : []} />
                    </>
                }
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem " }}>
                    <Pagination color='light' renderItem={(item) => (
                        <PaginationItem components={{

                            next: (props) => <button {...props}><BiRightArrow color='var(--color-icon)' /></button>,
                            first: (props) => <button {...props}>First</button>,
                            previous: (props) => <button {...props}><BiLeftArrow color='var(--color-icon)' /></button>
                        }}  {...item} sx={{ color: "white" }} />
                    )} count={totalPages} onChange={(e, pageNo) => {
                        setCurrentPage(pageNo)
                    }} />
                </div>
            </div>
        </Layout>
    )
}


export default Index


