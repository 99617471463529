class SearchFiberCompanyReq{
        constructor(search){
                this.id = null;
                this.name = null;
                this.description = null;
                this.servicableAreaId = null;
                this.pagingParams = {
                    pageNumber: 1,
                    pageSize: 30
                };
        }
}
export default SearchFiberCompanyReq;