import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import CreateOfferTemplateReq from '../../../Requests/OfferTemplate/CreateOfferTemplateReq'
import OfferApi from '../../../API/OfferApi'
import SearchOfferTemplatesReq from '../../../Requests/OfferTemplate/SearchOfferTemplatesReq'

const CreateOfferTemplateModal = ({ company, offerTemplate, setOfferTemplate,reload }) => {

    const [name, setName] = React.useState('')
    const [discount, setDiscount] = React.useState('')
    const [bulletPoints, setBulletPoints] = React.useState('')
    const [promos, setPromos] = React.useState('')

    const createOfferTemplate = async () => {
        Swal.fire({
            title: offerTemplate ? "Updating Offer Template" : "Creating Offer Template",
            icon: "info",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
                container: "custom-swal"
            },
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if (!name) {
            Swal.fire({
                icon: 'error',
                title: 'Offer Template Name is required',
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!discount) {
            Swal.fire({
                icon: 'error',
                title: 'Discount is required',
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!bulletPoints) {
            Swal.fire({
                icon: 'error',
                title: 'Bullet Points is required',
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (!promos) {
            Swal.fire({
                icon: 'error',
                title: 'Promos is required',
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        const req = new CreateOfferTemplateReq()
        req.name = name
        req.discount = discount
        req.bulletPoints = bulletPoints
        req.promos = promos
        req.id = offerTemplate ? offerTemplate.id : null
        req.fiberCompanyId = company?.id
        const res = offerTemplate ? await OfferApi.EditOfferTemplate(req) : await OfferApi.CreateOfferTemplate(req)

        if (res?.status?.success) {
            Swal.fire({
                icon: 'success',
                title: offerTemplate ? "Offer Template Updated Successfully." : 'Offer Template Created Successfully',
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            searchOfferTemplate()
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Failed to create Offer Template',
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }

    }

    const searchOfferTemplate = async () => {
        const req = new SearchOfferTemplatesReq();
        req.fiberCompanyId = company.id
        const res = await OfferApi.SearchOfferTemplate(req)

        if (res?.status?.success) {
            setOfferTemplate(res.data[0])
            setName(res?.data[0]?.name)
            setDiscount(res?.data[0]?.discount)
            setBulletPoints(res?.data[0]?.bulletPoints)
            setPromos(res?.data[0]?.promos)

        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Failed to fetch Offer Template',
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }

    }

    useEffect(() => {
        if (company)
            searchOfferTemplate()
    }, [company,reload])

    return (

        <div className='create_offer_template'>
            <div className='form'>
                <img style={{ maxHeight: "100px", margin: "auto" }} src={"data:image/png;base64," + company.logo} alt={company.name} />
                <h2>Create Offer Template</h2>

                <div className='input_div'>
                    <label>Offer Template Name</label>
                    <input type='text' placeholder='Enter Offer Template Name' value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className='input_div'>
                    <label>Discount</label>
                    <input type='number' placeholder='Enter Discount' value={discount} onChange={(e) => setDiscount(e.target.value)} />
                </div>
                <div className='input_div'>
                    <label>Bullet Points</label>
                    <p className='note' style={{ marginLeft: "0px" }}>Note: Each bullet point should start from a new line</p>
                    <textarea rows={7} placeholder='Enter Bullet Points' value={bulletPoints} onChange={(e) => setBulletPoints(e.target.value)} />
                </div>
                <div className='input_div'>
                    <label>Promos</label>
                    <p className='note' style={{ marginLeft: "0px" }}>Note: Each promo should start from a new line</p>
                    <textarea rows={3} placeholder='Enter Promos' value={promos} onChange={(e) => setPromos(e.target.value)} />
                </div>
                <button onClick={() => {
                    createOfferTemplate()
                }} style={{ backgroundColor: "#181E4B" }}>{offerTemplate ? "Update" : "Create"}</button>

            </div>
        </div>

    )
}

export default CreateOfferTemplateModal