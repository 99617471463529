/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import MapContext from "@terrestris/react-geo/dist/Context/MapContext/MapContext";
import MapComponent from "@terrestris/react-geo/dist/Map/MapComponent/MapComponent";
import { renderToString } from 'react-dom/server';
import Feature from "ol/Feature";
import OlMap from "ol/Map";
import OlView from "ol/View";
import Point from "ol/geom/Point";
import OlLayerTile from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import "ol/ol.css";
import { fromLonLat, toLonLat } from "ol/proj";
import Cluster from "ol/source/Cluster.js";
import OlSourceOsm from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import CircleStyle from "ol/style/Circle.js";
import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Text from "ol/style/Text.js";
import { useContext, useEffect, useState, useRef } from "react";
import { MapContext as MP } from "..";
import LoadingIcon from "../Images/loadingicon.png";
import "../MapPage.scss";
import SatelliteLayer from "./SatelliteLayer";
import { BiCurrentLocation } from "react-icons/bi";
import { FaMap } from "react-icons/fa";
import { FaSatelliteDish } from "react-icons/fa";
import SelectArea from "./SelectArea";
import Modify from "ol/interaction/Modify";
import { Collection, Geolocation } from "ol";
import EditFiberHouseReq from "../../../../Requests/FiberHouse/EditFiberHouseReq";
import FiberHouseApi from "../../../../API/FiberHouseApi";
import Swal from "sweetalert2";
import { IoMdSettings } from "react-icons/io";
import { UserContext } from "../../../../App";
import { CiMapPin } from "react-icons/ci";
import { MdFilterListAlt } from "react-icons/md";
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom.js';
import PinchZoom from 'ol/interaction/PinchZoom.js';
import { Polygon } from "ol/geom";
import { ImBold } from "react-icons/im";
// import MapHelper from "../MapHelper";
export const Map = ({
  fiberHouseClicked,
  currentPositionLayer,
  setCurrentPositionLayer
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const user = useContext(UserContext);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
      console.log(screenWidth, screenHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [map, setMap] = useState();
  const [cluster, setCluster] = useState(null);
  const [clusterLayer, setClusterLayer] = useState(null);
  // const [circleLayers, setCircleLayers] = useState([])
  const [isDrawingToolActive, setisDrawingToolActive] = useState(false);
  // const []
  const {
    setMapObject,
    mapIcons,
    index,
    setIndex,
    selectedFiberHouse,
    fiberHouses,
    setOpenMobileMenu,
    // setFiberHouses,
    // assignedLeadsEnabled,
    // unassignedLeadsEnabled,
    // usingLight,
    setSelectedFiberHouseFeature,
    // selectedFiberHouseFeature,
    setFiberHouseIds,
    // totalFiberHouses,
    // setTotalFiberHouses,
    searchAreaFeature,
    servicableAreas,
    shouldReRenderMap
  } = useContext(MP);
  const [satelliteMode, setSatelliteMode] = useState(true);
  const [addedServicableAreas, setAddedServicableAreas] = useState([]);
  const fiberHousesRef = useRef();
  const selectedFiberHouseRef = useRef();
  selectedFiberHouseRef.current = selectedFiberHouse;
  fiberHousesRef.current = fiberHouses;
  //Displaying all fiber houses on the map
  const indexRef = useRef();
  const [zoom, setZoom] = useState(0);

  function handleZoomChange(event) {
    if (map) {
      if (map.getView().getZoom() >= 15.6) {
        setZoom("ZoomIn")
      }
      else {
        setZoom("ZoomOut")
      }
    }
    console.log(zoom)
  }

  useEffect(() => {
    if (map) {
      map.getView().on("change:resolution", handleZoomChange);
    }
  }, [map]);


  useEffect(
    () => {
      if (clusterLayer && zoom === "ZoomIn") {
        clusterLayer?.setDistance(1)
        // setClusterDistance(0)
      }
      else if (clusterLayer && zoom === "ZoomOut") {
        clusterLayer?.setDistance(50)
        // setClusterDistance(50)
      }
    }, [zoom]
  )


  useEffect(() => {
    indexRef.current = index;
  }, [index]);

    function binarySearchFiberHouses(leads, targetId) {
      let left = 0;
      let right = leads.length - 1;
     console.log(leads)
      while (left <= right) {
          const mid = Math.floor((left + right) / 2);
          const midId =  leads[mid].id;
          console.log(midId, targetId)
          if (midId < targetId) {
              left = mid + 1;
          } else if (midId > targetId) {
              right = mid - 1;
          } else {
            console.log("Target found")
              return mid; // Found the target, return its index
          }
      }
     console.log("Target not found")
      return -1; // Target not found
  }

  // useEffect(
  //   () => {
  //     if (map) {
  //       console.log("Map is set")
  //       const view = map.getView();
  //       view.on('change', function () {

  //         handleChange(view)
  //       })
  //     }
  //   }, [map, fiberHouses]
  // )


  async function resetIconStyle(vectorSource) {
    if (selectedFiberHouseRef.current) {
      // eslint-disable-next-line array-callback-return
      vectorSource.getFeatures().map((fea) => {
        if (fea.get("id") === selectedFiberHouseRef.current.id) {
          // const fiberHouseIndex =  binarySearchFiberHouses(fiberHousesRef.current, selectedFiberHouseRef.current.id);
          let houseMapIcon = mapIcons.find(
            (mi) => mi.id === fea.get("mapIconId")?.mapIconId
          );

          const newStyle = new Style({
            image: new Icon({
              scale: 0.5, // Adjust this value to change the scale of the icon
              src: "data:image/png;base64," + houseMapIcon?.image,
            }),
          });

          // Apply the circle style to the feature
          fea.setStyle([
            newStyle, // This is the icon style
          ]);
          // zoom the map a little bit to make the icon visible
          map.getView().setZoom(map.getView().getZoom() - 0.0000001);
        }
      });
      // console.log(vectorSource.getFeatures())
      // const feature = binarySearchFeatureById(vectorSource.getFeatures(), selectedFiberHouseRef.current.id);
      // console.log(feature)
      // if (feature) {
      //   const fiberHouse = fiberHousesRef.current.find(
      //     (fh) => fh.id === selectedFiberHouseRef.current.id
      //   );
      //   let houseMapIcon = mapIcons.find(
      //     (mi) => mi.id === fiberHouse?.mapIconId
      //   );
      //   const newStyle = new Style({
      //     image: new Icon({
      //       scale: 0.5, // Adjust this value to change the scale of the icon
      //       src: "data:image/png;base64," + houseMapIcon?.image,
      //     }),
      //   });

      //   // Apply the circle style to the feature
      //   feature.setStyle([
      //     newStyle, // This is the icon style
      //   ]);
      // }
    }
  }




  // function filterFunction(fh) {
  //   if (usingLight) {
  //     return true
  //   }
  //   if (fh.remove)
  //     return false
  //   else {
  //     if (assignedLeadsEnabled && fh?.owners?.length === 1) {
  //       if (leadCustomer !== null) {
  //         if (leadCustomer === fh.assignedConvertedToCustomer) {
  //           return true
  //         }
  //         else {
  //           return false
  //         }
  //       }
  //       if (leadFrom !== null) {
  //         if (fh.createdAt >= leadFrom) {
  //           return true
  //         }
  //         else {
  //           return false
  //         }
  //       }
  //       if (leadTo !== null) {
  //         if (fh.createdAt <= leadTo) {
  //           return true
  //         }
  //         else {
  //           return false
  //         }
  //       }
  //       if (leadTo !== null && leadFrom !== null) {
  //         if (fh.createdAt >= leadFrom && fh.createdAt <= leadTo) {
  //           return true
  //         }
  //         else {
  //           return false
  //         }
  //       }

  //       if (leadHasReviews !== null) {
  //         if (fh.assignedAcountStatus === leadHasReviews) {
  //           return true
  //         }
  //         else {
  //           return false
  //         }
  //       }

  //       if (leadMapIcons.length > 0) {
  //         if (leadMapIcons.includes(fh.mapIconId)) {
  //           return true
  //         }
  //         else {
  //           return false
  //         }
  //       }

  //       return true
  //     }
  //     if (unassignedLeadsEnabled && fh?.owners?.length > 1) {
  //       if (unassignedConvertedToCustomer !== null) {
  //         if (unassignedConvertedToCustomer === fh.assignedConvertedToCustomer) {
  //           return true
  //         }
  //         else {
  //           return false
  //         }
  //       }
  //       if (unassignedCreatedFrom !== null) {
  //         if (fh.createdAt >= unassignedCreatedFrom) {
  //           return true
  //         }
  //         else {
  //           return false
  //         }
  //       }

  //       if (unassignedCreatedTo !== null) {
  //         if (fh.createdAt <= unassignedCreatedTo) {
  //           return true
  //         }
  //         else {
  //           return false
  //         }
  //       }

  //       if (unassignedCreatedTo !== null && unassignedCreatedFrom !== null) {
  //         if (fh.createdAt >= unassignedCreatedFrom && fh.createdAt <= unassignedCreatedTo) {
  //           return true
  //         }
  //         else {
  //           return false
  //         }
  //       }

  //       if (unassignedAcountStatus !== null) {
  //         if (fh.assignedAcountStatus === unassignedAcountStatus) {
  //           return true
  //         }
  //         else {
  //           return false
  //         }
  //       }

  //       if (unassignedMapIcons.length > 0) {
  //         if (unassignedMapIcons.includes(fh.mapIconId)) {
  //           return true
  //         }
  //         else {
  //           return false
  //         }
  //       }

  //       return true
  //     }
  //     if (fh?.owners?.length === 0) {
  //       return true
  //     }
  //     if (!fh?.leadId) {
  //       return true
  //     }
  //     return false;
  //   }
  // }
  useEffect(() => {
    if (!shouldReRenderMap.current) {
      shouldReRenderMap.current = true;
      return
    }
    if (fiberHouses && map) {
      if (cluster) {
        map.removeLayer(cluster);
        setCluster(null);
        setClusterLayer(null)
      }
      console.log("FiberHOuses rendered")
      const features = [];
      const coordinates = [];

      for (let i = 0; i < fiberHouses.length; i++) {
        const fiberHouse = fiberHouses[i];

        if (!fiberHouse.remove) {
          let coordinate = [
            fiberHouse.coordinates.longitude,
            fiberHouse.coordinates.latitude,
          ];
          let feature = new Feature(new Point(fromLonLat(coordinate)));
          coordinates.push(coordinate);

          if (fiberHouse.id) {
            feature.set("id", fiberHouse.id);
            feature.set("fiber_house", true);
            feature.set("mapIconId", fiberHouse.mapIconId);
            feature.set("selected", fiberHouse.selected);
            feature.set("isBundled", fiberHouse.isBundled);
          } else {
            console.error("fiberHouse.id is not defined");
          }

          feature.set("isNewHouse", !!fiberHouse.isNewHouse);

          features.push(feature);
        }
      }
      // console.log("Duplicates: ", duplicates);
      const vectorSource = new VectorSource({
        features: features,
      });

      const clusterSource = new Cluster({
        distance: zoom === "ZoomIn" ? 1 : 50,
        source: vectorSource,
      });
      const styleCache = {};
      let clusters = null;

      clusters = new VectorLayer({
        source: clusterSource,
        name: "fiberHouses",
        style: function (feature) {
          const size = feature.get("features").length;
          console.log("size", size)
          let style = styleCache[size];
          if (size === 1) {
        
            let houseMapIcon = mapIcons.find(
              (mi) => mi.id === feature.get("features")[0]?.get("mapIconId")
            );


            let imgScale = 1;
            if (!houseMapIcon) {
              houseMapIcon = LoadingIcon;
              imgScale = 0.1;
            }
            else {
              houseMapIcon = "data:image/png;base64," + houseMapIcon?.image;
              imgScale = 0.5;
            }
            if (selectedFiberHouseRef.current && selectedFiberHouseRef.current.id === feature.get("features")[0].get("id")) {
              style = [
                new Style({
                  image: new Icon({
                    scale: 0.5, // Adjust this value to change the scale of the icon
                    src: houseMapIcon,
                  }),
                }),
                new Style({
                  image: new CircleStyle({
                    radius: 15, // This is now in pixels
                    stroke: new Stroke({
                      color: "black", // Border color
                      width: 4, // Border width
                    }),
                    fill: new Fill({
                      color: "rgba(0, 0, 0, 0)", // Transparent fill
                    }),
                  }),
                })
              ];
              if (feature.get("features")[0].get("isBundled")) {
                style.push (
                  new Style({
                    text: new Text({
                      text: "TV",
                      fill: new Fill({
                        color: "#fff", // White text color
                      }),
                      font: "bold 12px Arial", 
                      scale: 1.2, 
                      textAlign: "center", 
                      textBaseline: "middle", 
                      offsetX: 10, 
                      offsetY: 11, 
                    }),
                  })
                )
              }
              return style;
            }

            if (feature.get("features")[0].get("selected")) {
              style = [
                new Style({
                  image: new Icon({
                    src: houseMapIcon,
                    scale: imgScale,
                    // anchor: [0.5, 1],
                  }),
                }),
                new Style({
                  image: new CircleStyle({
                    radius: 15, // This is now in pixels
                    stroke: new Stroke({
                      color: "blue", // Border color
                      width: 4, // Border width
                    }),
                    fill: new Fill({
                      color: "rgba(0, 0, 0, 0)", // Transparent fill
                    }),
                  }),
                }),
              ];

              return style;
            }

            if(feature.get("features")[0].get("isBundled")){
              style =[ 
                new Style({
                  image: new Icon({
                    src: houseMapIcon,
                    scale: imgScale,
                    anchor: [0.5, 1],
                  })
                }),
                new Style({
                  text: new Text({
                    text: "TV",
                    fill: new Fill({
                      color: "#fff", // White text color
                    }),
                    font: "bold 12px Arial", // Make the text bold and clear
                    scale: 1.2, // Scale the text for clarity
                    textAlign: "center", // Center align text
                    textBaseline: "middle", // Vertically center text
                    offsetX: 10, // Offset the text to match the circle's displacement
                    offsetY: -2, // Offset to ensure "B" stays in the middle of the circle
                  }),
                })
              ]
              return style;
            } 

            style = new Style({
              image: new Icon({
                src: houseMapIcon,
                scale: imgScale,
                anchor: [0.5, 1],
              })
            });




          
          }
          else {
            style = new Style({
              image: new CircleStyle({
                radius: 18,
                stroke: new Stroke({
                  color: "#fff",
                  width: 2,
                }),
                fill: new Fill({
                  color: "#2761D0",
                }),
              }),
              text: new Text({
                text: size.toString(),
                fill: new Fill({
                  color: "#fff",
                }),
                scale: 1.2,
              }),
            });

          }

          return style;
        },
      });


      setCluster(clusters);
      setClusterLayer(clusterSource);
      clusters.setZIndex(11000);
      map.addLayer(clusters);
      // map.addInteraction(modify);


      console.log()


    }
  }, [fiberHouses, mapIcons]);

  useEffect(
    () => {
      if (map) {
        map.on("click", handleMapClick);

        return () => {
          map.un("click", handleMapClick);
        }
      }
    }, [map, isDrawingToolActive]
  )

  const handleMapClick = (event) => {
    mapClickHandler(event);
  }

  function mapClickHandler(event) {
    let count = 0;
    map.forEachFeatureAtPixel(event.pixel, function (feature, layer) {
      const size = feature?.get("features")?.length;
      const originalFeatures = feature.get("features");
      // console.log(feature.get("features"));
      // disable the cluster click and the lead main lead card when drawing toolis active
      // console.log(feature)
      if(count>0){
        return;
      }

      if (isDrawingToolActive) {
        return
      }

      if(Object.keys(feature.values_)[0] === "stat" && !isDrawingToolActive){
        console.log("stat area cliked");
        console.log(feature)
        setIndex(8)
        count++;
        return;
      }

      if (size > 1) {
        // const coordinates = feature.getGeometry().getCoordinates();
        console.log(isDrawingToolActive)
        if (originalFeatures.length > 5000) {

          Swal.fire({
            icon: "warning",
            title: "Too Many Houses",
            text: "Limit is 5000 houses per cluster.",
            toast: true,
            position: "top-end",
            timer: 1500,
            showConfirmButton: false

          });
          return;
        }



        let fiberHouseIds = []
        originalFeatures.map(f => {
          fiberHouseIds.push(f.get("id"))
        });

        setFiberHouseIds(fiberHouseIds)
        setIndex(8)
        count++;
        return;
        // console.log("cLICKED");
      } else if (
        (zoom === "ZoomIn" && feature.get("fiber_house")) ||
        (size === 1 && originalFeatures[0].get("fiber_house"))
      ) {
        let selectedFeature = null;
        let fiberHouseId = null;
        console.log("Fiber House Clicked");
        // console.log(zoom);
        // if (zoom === "ZoomIn") {
        //   fiberHouseId = feature.get("id");
        //   selectedFeature = feature;
        //   // console.log(feature.get("id"));
        // } else {
        fiberHouseId = originalFeatures[0].get("id");
        selectedFeature = originalFeatures[0];
        // console.log(originalFeatures[0].get("id"));
        // }
        // const fiberHouseIndex = binarySearchFiberHouses(fiberHousesRef.current, fiberHouseId);

        // const fiberHouseIndex = binarySearch(fiberHousesRef.current, fiberHouseId);
        // const fiberHouse = fiberHousesRef.current[fiberHouseIndex];
        // console.log("Clicked");

        if (!isNaN(fiberHouseId)) {
          setSelectedFiberHouseFeature(selectedFeature);
          fiberHouseClicked({
            id: fiberHouseId,
            mapIconId: selectedFeature.get("mapIconId"),
          });
          // console.log(fiberHouse);
          let houseMapIcon = mapIcons.find(
            (mi) => mi.id === selectedFeature.get("mapIconId")
          );

          const newStyle = new Style({
            image: new Icon({
              scale: 0.5, // Adjust this value to change the scale of the icon
              src: "data:image/png;base64," + houseMapIcon?.image,
              // height:30,
              // width:30
            }),
          });
          if (clusterLayer)
            resetIconStyle(clusterLayer?.getSource());
          // Apply the new style to the feature
          const circleStyle = new Style({
            image: new CircleStyle({
              radius: 15, // This is now in pixels
              stroke: new Stroke({
                color: "black", // Border color
                width: 4, // Border width
              }),
              fill: new Fill({
                color: "rgba(0, 0, 0, 0)", // Transparent fill
              }),
            }),
          });
          let modify = null;
          if (zoom === "ZoomIn") {
            modify = new Modify({
              features: new Collection([feature]),
              hitDetection: new Collection([feature]),
              style: null
            });
          } else {
            modify = new Modify({
              features: new Collection([originalFeatures[0]]),
              hitDetection: new Collection([originalFeatures[0]]),
              style: null
            });
          }
          // const modify = new Modify({
          //     features: new Collection([originalFeatures[0]]),
          //     hitDetection: new Collection([originalFeatures[0]]),
          // });
          map.getInteractions().forEach(interaction => {
            if (interaction instanceof Modify) {
                console.log("clearing")
                map.removeInteraction(interaction)
            }
        })
          modify.on("modifyend", async function (event) {
            // Swal.fire({
            //   icon: "info",
            //   title: "Please Wait",
            //   text: "Moving Fiber House...",
            //   toast: true,
            //   position: "bottom-end",
            //   didOpen: () => {
            //     Swal.showLoading();
            //   },
            // });
            const coordinates = event.features
              .getArray()[0]
              .getGeometry()
              .getCoordinates();
            const req = new EditFiberHouseReq();
            req.id = selectedFeature.get("id");

            req.coordinate = {
              latitude: toLonLat(coordinates)[1],
              longitude: toLonLat(coordinates)[0],
            };
            req.salesRepId = user?.id;
            console.log(req);
            const res = await FiberHouseApi.EditFiberHouse(req);
            console.log(res);
            if (res?.status?.success) {
              console.log("Fiber House Moved Successfully");

            } else {
              console.log("Fiber House Move Failed");
              Swal.fire({
                icon: "error",
                title: "Error",
                text: res?.status?.message || "Fiber House Move Failed",
                didOpen: () => {
                  Swal.hideLoading();
                },
              });
            }
          });
          map.addInteraction(modify);

          // Apply the circle style to the feature
          feature.setStyle([
            newStyle, // This is the icon style
            circleStyle, // This is the circle style
          ]);
          map.getView().setZoom(map.getView().getZoom() - 0.0000001);
        }
        count++;
        return;
      }
   
   
      
    });
  }

  // Map INIT
  useEffect(() => {
    setMap(
      new OlMap({
        layers: [
          new OlLayerTile({
            name: "OSM",
            source: new OlSourceOsm(),
          }),
        ],
        view: new OlView({
          center: fromLonLat([-98.5795, 39.8283]),
          zoom: 4,
          zoomDelta: 0.5,

        }),
      })

    );

  }, []);


  useEffect(
    () => {
      if (map) {
        const mouseWheelZoom = new MouseWheelZoom({
          duration: 100,
          constrainResolution: true,
          maxDelta: 1.5,
          timeout: 50
        })
        map.addInteraction(mouseWheelZoom);
        const pinchZoom = new PinchZoom({
          duration: 200 // Adjust this value for faster zooming
        });

        map.addInteraction(pinchZoom);



      }
    }, [map]
  )


  //Set Context Map Object
  useEffect(() => {
    if (map) {
      setMapObject(map);
      console.log("Map Object Set");
    }
  }, [map]);

  //Set Map Center to current location




  function makeTransparent(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  function getPolygonArea(latLngs) {
    const numPoints = latLngs.length;

    if (numPoints < 3) {
      return 0; // A polygon with less than 3 vertices has no area
    }

    let area = 0;

    for (let i = 0; i < numPoints; i++) {
      const [xi, yi] = latLngs[i];

      const nextIndex = (i + 1) % numPoints;
      const [xj, yj] = latLngs[nextIndex];

      area += xi * yj - xj * yi;
    }

    area = Math.abs(area) / 2;

    return area;
  }

  // Assuming a tooltip element with the ID 'map-tooltip' exists in your HTML
  // If not, create one and style it appropriately with CSS

  // useEffect(() => {
  //   if (map && servicableAreas && servicableAreas.length > 0) {
  //     console.log("Adding Layer");
  //     if (addedServicableAreas.length > 0) {
  //       addedServicableAreas?.map((layer) => {
  //         map.removeLayer(layer);
  //       });
  //       setAddedServicableAreas([]);
  //     }
  //     const layers = [];
  //     const sortedServicableAreas = servicableAreas.sort(
  //       (a, b) => getPolygonArea(b.latlngs) - getPolygonArea(a.latlngs)
  //     );
  //     sortedServicableAreas.map((area, ind) => {
  //       const coordinates = area.latlngs.map((latlng) => {
  //         return fromLonLat([latlng[1], latlng[0]]);
  //       });
  //       coordinates.push(coordinates[0]);
  //       const polygon = new Polygon([[...coordinates]]);
  //       const polygonFeature = new Feature({
  //         geometry: polygon,
  //         name: area.name, // Store the area's name in the feature
  //       });

  //       // Style setup omitted for brevity

  //       const vectorLayer = new VectorLayer({
  //         source: new VectorSource({
  //           features: [polygonFeature],
  //         }),
  //       });
  //       layers.push(vectorLayer);
  //       vectorLayer.setZIndex(1010 + ind);
  //       map.addLayer(vectorLayer);
  //     });

  //     setAddedServicableAreas(layers);

  //     // Tooltip display logic
  //     const tooltipElement = document.getElementById('map-tooltip');
  //     map.on("click", function (event) {
  //       map.forEachFeatureAtPixel(event.pixel, function (feature) {
  //         if (feature.getGeometry().getType() === "Polygon") {
  //           const name = feature.get('name'); // Retrieve the area's name
  //           const coordinate = event.coordinate;
  //           const [x, y] = map.getPixelFromCoordinate(coordinate);
  //           tooltipElement.innerHTML = name; // Set the tooltip content
  //           tooltipElement.style.display = 'block';
  //           tooltipElement.style.left = `${x}px`;
  //           tooltipElement.style.top = `${y}px`;
  //         }
  //       });
  //     });

  //     // Hide the tooltip when clicking elsewhere
  //     map.on("pointermove", function () {
  //       tooltipElement.style.display = 'none';
  //     });
  //   }
  // }, [servicableAreas, map]);

  useEffect(() => {
    if (map && servicableAreas && servicableAreas.length > 0) {
      console.log("Adding Layer")
      if (addedServicableAreas.length > 0) {
        addedServicableAreas?.map((layer) => {
          map.removeLayer(layer);
        });
        setAddedServicableAreas([]);
      }
      const layers = [];
      const sortedServicableAreas = servicableAreas.sort(
        (a, b) => getPolygonArea(b.latlngs) - getPolygonArea(a.latlngs)
      );
      sortedServicableAreas.map((area, ind) => {
        // Create a polygon feature
        const coordinates = area.latlngs.map((latlng) => {
          return fromLonLat([latlng[1], latlng[0]]);
        });
        coordinates.push(coordinates[0]);
        const polygon = new Polygon([[...coordinates]]);
        const polygonFeature = new Feature({
          geometry: polygon,
        });

        const style = {
          Polygon: new Style({
            stroke: new Stroke({
              color: area.color,
              width: 1,
            }),
            fill: new Fill({
              color: makeTransparent(area.color, 0.15),
            }),
          }),
        };
        polygonFeature.setStyle(style["Polygon"]);
        // Create a vector source and add the feature to it
        const vectorSource = new VectorSource({

          features: [polygonFeature],
        });

        // Create a vector layer with the source
        const vectorLayer = new VectorLayer({
          source: vectorSource,
        });
        layers.push(vectorLayer);
        // Create a tile layer for the base map\
        vectorLayer.setZIndex(1010 + ind);

        map.addLayer(vectorLayer);

        if (
          user.userType === "SalesOrgAdmin" ||
          user.userType === "SuperAdmin"
        ) {
          map.on("click", function (event) {
            event.preventDefault();
            let count = 0;
            map.forEachFeatureAtPixel(event?.pixel, function (feature, layer) {
              if (feature?.getGeometry()?.getType() === "Point") {
                count++;
              } else {
                // if (
                //   count == 0 &&
                //   feature === polygonFeature &&
                //   indexRef.current !== 2
                // )
                // servicableAreaClicked(area.id, area.latlngs, area);
              }
            });
          });
        }
      });

      setAddedServicableAreas(layers);
    }
  }, [servicableAreas, map]);

  if (!map) {
    return null;
  }
  const setCurrentLocation = () => {
    console.log("Getting Current Location")
    if (currentPositionLayer) {
      map.removeLayer(currentPositionLayer);
      setCurrentPositionLayer(null)
      console.log("removing previous blue dot")
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const center = fromLonLat([
          position.coords.longitude,
          position.coords.latitude,
        ])
        // mapObject.getView().setCenter(center)
        // mapObject.getView().setZoom(18)
        try {
          map.getView().animate({ center: center, zoom: 18, duration: 500 });
        }
        catch (e) {
          console.error(e)
        }
        const geolocation = new Geolocation({
          trackingOptions: {
            enableHighAccuracy: true,
          },
          projection: map?.getView().getProjection(),
        })
        console.log("geolocation", geolocation.getPosition())
        if (currentPositionLayer) {
          map.removeLayer(currentPositionLayer);
          setCurrentPositionLayer(null)
        }

        const positionFeature = new Feature(
          new Point(center),
        );
        const iconStyle = new Style({
          image: new CircleStyle({
            radius: 12,
            stroke: new Stroke({
              color: "#fff",
              width: 2,
            }),
            fill: new Fill({
              color: "#3399CC",
            }),
          }),
        });
        positionFeature.setStyle(iconStyle);
        // const geolocationControl = map.getControlsByType('Geolocation')[0];
        // if (geolocationControl) {
        //   // If it exists, we don't need to create a new one
        //   console.log("Using existing Geolocation control");
        //   return;
        // }


        const vectorSource = new VectorSource({
          features: [positionFeature],
        });

        const vectorLayer = new VectorLayer({
          source: vectorSource,
        });
        vectorLayer.setZIndex(1000);

        map.addLayer(vectorLayer);
        setCurrentPositionLayer(vectorLayer)

        geolocation.on("change", function () {
          const coordinates = geolocation.getPosition();
          console.log("coordinates", coordinates)
          positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
          // mapObject.getView().setZoom(mapObject.getView().getZoom() + 0.0001)
        });
        geolocation.setTracking(true);

      })
  };
  return (
    <div className="relative h-full">
      <MapContext.Provider value={map}>
        <SatelliteLayer satelliteMode={satelliteMode} map={map} />
        <MapComponent
          map={map}
          style={{
            height: screenWidth > 1000 ? "70vh" : "100%",
            overflow: "hidden",
            borderRadius: screenWidth > 1000 ? "10px" : "0px",
          }}
        />
        <div id="map-tooltip"></div>
        <div className="flex justify-center items-center">
          <div
            style={{
              top: screenWidth > 1000 ? null : "7%",
              bottom: screenWidth > 1000 ? "5%" : null,
            }}
            className={`flex absolute ${screenWidth > 1000 ? "bottom-8" : ""
              } justify-center gap-4 items-center`}
          >
            {
              (searchAreaFeature.length === 0 || screenWidth < 1000) &&
              <>
                <button
                  onClick={() => {
                    setCurrentLocation();
                  }}
                  className={"TrackLocationButton"}
                  style={{
                    borderRadius: "50%",
                    padding: "0.5rem",
                  }}
                >
                  <BiCurrentLocation
                    color="#181E4B"
                    size={screenWidth > 1000 ? 30 : 20}
                  />
                </button>
                <button
                  onClick={() => {
                    setSatelliteMode(!satelliteMode);
                  }}
                  className={"TrackLocationButton"}
                  style={{
                    borderRadius: "50%",
                    padding: "0.5rem",
                  }}
                >
                  {satelliteMode ? (
                    <FaMap color="#181E4B" size={screenWidth > 1000 ? 30 : 20} />
                  ) : (
                    <FaSatelliteDish
                      color="#181E4B"
                      size={screenWidth > 1000 ? 30 : 20}
                    />
                  )}
                </button>
                <button
                  style={{
                    borderRadius: "50%",
                    padding: "0.5rem",
                  }}
                  onClick={() => {
                    setIndex(2);
                  }}
                  className="TrackLocationButton"
                >
                  <CiMapPin color="#181E4B" size={screenWidth > 1000 ? 30 : 20} />
                </button>

              </>}
            <SelectArea active={isDrawingToolActive} setActive={setisDrawingToolActive} screenWidth={screenWidth} />
            {
              (searchAreaFeature.length === 0 || screenWidth < 1000) &&
              <button
                style={{
                  borderRadius: "50%",
                  padding: "0.5rem",
                }}
                onClick={() => {
                  setIndex(7);
                }}
                className="TrackLocationButton"
              >
                <MdFilterListAlt
                  size={screenWidth > 1000 ? 30 : 20}
                  color="#181E4B"
                />
              </button>}
            {screenWidth > 1000 ? null : (
              <button
                style={{
                  borderRadius: "50%",
                  padding: "0.5rem",
                }}
                onClick={(e) => {
                  setOpenMobileMenu(e.currentTarget);
                }}
                className="TrackLocationButton"
              >
                <IoMdSettings
                  size={screenWidth > 1000 ? 30 : 20}
                  color="#181E4B"
                />
              </button>
            )}

          </div>
        </div>
      </MapContext.Provider>
    </div>
  );
};
