import EnvSettings from "./EnvSettings";
import UsersApi from "./UsersApi";
class CommissionApi{
    baseUrl = EnvSettings.url;
    CreateCommissionUrl = "CreateCommissionPlan";
    SearchCommissionPlansUrl = "SearchCommissionPlans";
    DeleteCommissionPlanUrl = "DeleteCommissionPlan";
    EditCommissionPlanUrl = "EditCommissionPlan";
    AddUserToCommissionPlanUrl = "AddUserToCommissionPlan";
    RemoveUserFromCommissionPlanUrl = "RemoveUserFromCommissionPlan";
    AddSalesOrgToCommissionPlanUrl = "AddSalesOrgToCommissionPlan";
    RemoveSalesOrgFromCommissionPlanUrl = "RemoveSalesOrgFromCommissionPlan";
    GetUserCommissionsUrl = "GetUserCommissions";
    GetUserCommissionTrackersUrl = "GetUserCommissionTrackers";
    PayCommissionTrackerUrl = "PayCommissionTracker";
    BulkPayCommissionTrackersUrl = "BulkPayCommissionTrackersByAmount";
    SearchCommissionTrackersUrl = "SearchCommissionTrackers";
    SearchDebtSheetUrl = "SearchDebtSheet";
    PayDebtSheetEntryUrl = "PayDebtSheetEntry";
    BulkPayDebtSheetEntriesUrl = "BulkPayDebtSheetEntries";
    SearchAccountingEntriesUrl = "SearchAccountingEntries";
    SearchRewardsUrl = "SearchRewards";
    SearchFiberCommissionPlansUrl = "SearchFiberCommissionPlans";

    async SearchFiberCommissionPlans(req){
        let response = await fetch(this.baseUrl + this.SearchFiberCommissionPlansUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }


    async SearchRewards(req){
        let response = await fetch(this.baseUrl + this.SearchRewardsUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async SearchAccountingEntries(req){
        let response = await fetch(this.baseUrl + this.SearchAccountingEntriesUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async BulkPayDebtSheetEntries(req){
        let response = await fetch(this.baseUrl + this.BulkPayDebtSheetEntriesUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }
    

    async PayDebtSheetEntry(req){
        let response = await fetch(this.baseUrl + this.PayDebtSheetEntryUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async SearchDebtSheet(req){
        let response = await fetch(this.baseUrl + this.SearchDebtSheetUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async SearchCommissionTrackers(req){
        let response = await fetch(this.baseUrl + this.SearchCommissionTrackersUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async BulkPayCommissionTrackers(req){
        let response = await fetch(this.baseUrl + this.BulkPayCommissionTrackersUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        }); 
        return await response.json();
    }

    async PayCommissionTracker(req){
        let response = await fetch(this.baseUrl + this.PayCommissionTrackerUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        }); 
        return await response.json();
    }


    async GetUserCommissionTrackers(req){
        let response = await fetch(this.baseUrl + this.GetUserCommissionTrackersUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async CreateCommissionPlan(req){
        let response = await fetch(this.baseUrl + this.CreateCommissionUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async SearchCommissionPlans(req){
        let response = await fetch(this.baseUrl + this.SearchCommissionPlansUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async DeleteCommissionPlan(req){
        let response = await fetch(this.baseUrl + this.DeleteCommissionPlanUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async EditCommissionPlan(req){
        let response = await fetch(this.baseUrl + this.EditCommissionPlanUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async AddUserToCommissionPlan(req){
        let response = await fetch(this.baseUrl + this.AddUserToCommissionPlanUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async RemoveUserFromCommissionPlan(req){
        let response = await fetch(this.baseUrl + this.RemoveUserFromCommissionPlanUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async AddSalesOrgToCommissionPlan(req){
        let response = await fetch(this.baseUrl + this.AddSalesOrgToCommissionPlanUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async RemoveSalesOrgFromCommissionPlan(req){
        let response = await fetch(this.baseUrl + this.RemoveSalesOrgFromCommissionPlanUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }

    async GetUserCommissions(req){
        let response = await fetch(this.baseUrl + this.GetUserCommissionsUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsersApi.GetToken()
            },
            body: JSON.stringify(req)
        });
        return await response.json();
    }
    
    
    
}
export default new CommissionApi();