import { CardMembership, Chat, Dashboard, Map } from "@mui/icons-material";
import { FcCustomerSupport } from "react-icons/fc";
import { AiOutlineGlobal, AiOutlineStock } from "react-icons/ai";
import { BiSolidNetworkChart, BiSolidOffer } from "react-icons/bi";
import { HiOutlineCreditCard } from "react-icons/hi";
import { LuFolderCog } from "react-icons/lu";
import { MdDashboard, MdLeaderboard, MdOutlineBugReport, MdOutlinePayments } from "react-icons/md";
import { PiMapTrifoldLight } from "react-icons/pi";
import { RiContactsBookLine } from "react-icons/ri";
import { TbMessageBolt, TbReportAnalytics } from "react-icons/tb";
import { VscFeedback, VscSettings } from "react-icons/vsc";
import { GoOrganization } from "react-icons/go";

const RepMenuItems = [

    {
        name: "Dashboard",
        key: "Index",
        route: "/",
        icon: (color,size=40) => <MdDashboard size={size} color={color} />
    },
    {
        name:"General Sales Report",
        key:"GeneralSalesReport",
        route:"/general-sales-reporting-page",
        icon: (color,size=40) => <TbReportAnalytics size={size} color={color} />,
    },
    {
        name: "Digital Business Center",
        key: "DigitalBusinessCenter",
        route: "/digital-business-center",
        icon: (color,size=40) => <HiOutlineCreditCard size={size} color={color} />,
    },
    // {
    //     name: "Sales Portal",
    //     key: "SalesPortal",
    //     route: "/sales-portal",
    //     icon: (color,size=40) => <AiOutlineStock size={size} color={color} />,
    // },
    // {
    //     name: "Performance Analytics",
    //     key: "Analytics",
    //     route: "/performance-analytics",
    //     icon: (color,size=40) => <BiSolidNetworkChart size={size} color={color} />,
    // },
    {
        name: "Chat",
        key: 'privatechat', 
        route: '/chats',
        icon:(color,size=40)=><TbMessageBolt size={size} color={color} /> ,
        disabled: true
    },
    {
        name: "Map",
        key: "map",
        route: "/map",
        icon: (color,size=40) => <PiMapTrifoldLight size={size} color={color} />,
    },
    {
        name :"Leader Board",
        key:"LeaderBoard",
        route:"/leader-board",
        icon: (color,size=40) => <MdLeaderboard size={size} color={color} />,
        // disabled:true
    },
    {
        name: "Training Modules",
        key: 'manageSellingCard', 
        route: '/manage-training-modules', 
        icon: (color,size=40) => <LuFolderCog size={size} color={color} />, 
        noCollapse: true,
        disabled: true
    },
    {
        type: "collapse",
        name: "Contact center",
        key: 'ContactCenter', 
        route: '/contact-center', 
        icon: (color,size=40) =><RiContactsBookLine size={size} color={color} />, 
        noCollapse: true,
        // disabled: true
    },
    {
        name: "Customer Feedback",
        key: 'CustomerFeedback', 
        route: '/customer-feedback', 
        icon: (color,size=40) => <VscFeedback size={size} color={color} />, 
        noCollapse: true
    },
    {
        name:"Report A Bug",
        key:"CreateBugTicket",
        route:"/create-bug-ticket",
        icon: (color,size=40) => <MdOutlineBugReport size={size} color={color} />,
    },
    {
        name:"My Commissions",
        key:"MyCommissions",
        route:"/my-commissions",
        icon: (color,size=40) => <MdOutlinePayments size={size} color={color} />,
        disabled:true
    },
    {
        name:"Offers Sales Report",
        key:"offersSalesReport",
        route:"/offers-reporting-page",
        icon: (color,size=40) => <BiSolidOffer size={size} color={color} />,
        disabled: true
    },
    {
        name:"Manage Business Card",
        key:"manageBusinessCard",
        route:"/manage-business-card",
        icon: (color,size=40) => <VscSettings size={size} color={color} />,
    },
]

export default RepMenuItems