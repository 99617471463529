class EnvSettings {
    constructor() {

        if(window.location.href.includes('localhost')){
            this.env = 'development';
            this.url = 'https://localhost:7251/';
        }
        else{
            this.env = 'Live';
            this.url = 'https://playitforward-001-site2.btempurl.com/';
        }

        //  this.env ='development';
        // this.env = 'Live';

        // this.url = 'https://playitforward-001-site2.btempurl.com/';
    }
}
const env = new EnvSettings();
export default env;