/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import FiberCompanyApi from '../../API/FiberCompanyApi';
import SearchFiberCompanyReq from '../../Requests/FiberCompany/SearchFiberCompanyReq';
import SoftTypography from './../SoftTypography';
const Index = ({setUser,setCompanyObject,companiesWithOfferTemplateOnly,disabled=false,placeholder="Select a parent Organization",className,multiple, value}) => {
    const [UsersSearch, setUserSearch] = useState()
    const [options, setOptions] = useState([])
    
    useEffect(() => {
        if(setUser){
            setUser(null);
        }
    }, []);
    const GetOrg = async () => {
        let searchReq = new SearchFiberCompanyReq();
        // searchReq.userType = "company"
        if(UsersSearch)
        searchReq.name = UsersSearch;

        if(companiesWithOfferTemplateOnly){
            searchReq.hasOfferTemplate = true;
        }
        let res = await FiberCompanyApi.SearchFiberCompany(searchReq)
        if (res?.status?.success) {
            let optionsArr = [];
            
            res?.data?.list.forEach((item) => {
                optionsArr.push({ value: item, label: item.name,id:item.id })
            })
            return optionsArr;
        }
        else {
            Swal.fire({
                title: 'Error',
                text: res.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }

    useEffect(() => {
        const getOptions = async () => {
            const optionsArr = await GetOrg();
            setOptions(optionsArr);
        };
        getOptions();
    }, []);

    return (
        <div className={className}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
                <AsyncSelect
                    loadOptions={GetOrg}
                    onInputChange={setUserSearch}
                    onChange={(e) => {
                        setUser(e.id)
                        if(setCompanyObject){
                            setCompanyObject(e.value)
                        }
                    }}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    isMulti={multiple}
                    defaultValue={value}
                    defaultOptions={options}
                    classNamePrefix={'async_select'}
                />
            </SoftTypography>

        </div>
    )
}

export default Index