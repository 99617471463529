import React from 'react'
import FiberPlanSelector from "./../../../components/FiberPlanSelector"
import Swal from 'sweetalert2'
import OfferApi from '../../../API/OfferApi'
import { MdDelete } from 'react-icons/md'
import StringIntReq from '../../../Requests/StringIntReq'
const AddFiberPlanToOfferTemplate = ({ company, offerTemplate, setReload }) => {

    const [fiberPlan, setFiberPlan] = React.useState(null)
    const [discount, setDiscount] = React.useState(0)

    const addFiberPlan = async () => {
        Swal.fire({
            title: "Adding Fiber Plan",
            icon: "info",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
                container: "custom-swal"
            },
            didOpen: () => {
                Swal.showLoading()
            }
        })

        if (!fiberPlan) {
            Swal.fire({
                icon: 'error',
                title: 'Fiber Plan is required',
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        if (isNaN(discount)) {
            Swal.fire({
                icon: 'error',
                title: 'Discount must be a number',
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            return
        }

        const offerTemplateFiberPlan = {
            fiberPlanId: fiberPlan.id,
            discount: discount,
            offerTemplateId: offerTemplate.id
        }

        const res = await OfferApi.CreateOfferTemplateFiberPlan(offerTemplateFiberPlan)
        if (res?.status?.success) {
            Swal.fire({
                icon: 'success',
                title: 'Fiber Plan Added Successfully',
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            setReload((prev) => !prev)
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Failed to add Fiber Plan',
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
    }


    const removeFiberPlan = async (offerTemplatePlanId) => {
        const selected = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })

        if (!selected.isConfirmed) {
            return
        }

        Swal.fire({
            title: "Removing Fiber Plan",
            icon: "info",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
                container: "custom-swal"
            },
            didOpen: () => {
                Swal.showLoading()
            }
        })

        const req = new StringIntReq()
        req.int = offerTemplatePlanId

        const res = await OfferApi.DeleteOfferTemplateFiberPlan(req)
        if (res?.status?.success) {
            Swal.fire({
                icon: 'success',
                title: 'Fiber Plan Removed Successfully',
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            setReload((prev) => !prev)
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Failed to remove Fiber Plan',
                customClass: {
                    container: "custom-swal"
                },
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
    }

    return (
        <div className='add_plan_to_template'>
            {
                offerTemplate?
                <>
                    <div className='form'>
                        <h2>Offer Template Fiber Plans</h2>
                        <FiberPlanSelector placeholder='Slect Fiber Plan' setUser={setFiberPlan} fiberCompany={company?.id} />
                        {
                            fiberPlan &&
                            <>
                                <input type='number' placeholder='Discount' value={discount} onChange={(e) => setDiscount(e.target.value)} />
                                <button onClick={() => {
                                    addFiberPlan()
                                }}>Add</button>
                            </>
                        }
                    </div>
                    <div className='fiber_plans'>
                        {
                            offerTemplate?.offerTemplateFiberPlans?.map((item, index) => {
                                console.log(item)
                                return (
                                    <div key={index} className='fiber_plan'>
                                        <h3>{item?.fiberPlan?.name}</h3>
                                        <div>
                                            <p>Discount: ${item?.discount}</p>
                                            <p>Price: ${item?.fiberPlan?.price}</p>
                                            <p>Speed: {item?.fiberPlan?.speed * 1000} Mbps</p>
                                        </div>
                                        <button onClick={() => {
                                            removeFiberPlan(item.id)
                                        }}><MdDelete color='white' size={30} /></button>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
                :
                <h1>Create an Offer Template First</h1>
            }
        </div>
    )
}

export default AddFiberPlanToOfferTemplate