/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef, useCallback } from 'react'
import FiberHouseApi from '../../../../../API/FiberHouseApi'
import { UserContext } from '../../../../../App'
import EditLeadReq from '../../../../../Requests/FiberHouse/EditLeadReq'
import MapIconDisplay from './../MapIconDisplay'
import './MainLeadCard.scss'
import LeadEdit from './LeadEdit'
import LeadData from './LeadData'
import LightningMessage from './Images/LightningMessage.png'
import Call from './Images/Call.png'
import Email from './Images/Email.png'
import Message from './Images/Message.png'
import wa from './Images/whatsapp.svg'
import Directions from './Images/Directions.png'
// import { MdEmail, MdLocationOn } from 'react-icons/md'
// import CustomerApi from '../../../../../API/CustomerApi'
import StringIntReq from '../../../../../Requests/StringIntReq'
import ContactButtons from '../../../../../Services/ContactButtons'
import { useNavigate } from 'react-router-dom'

import { ImSpinner8 } from "react-icons/im";
// import SearchLeadsForContactCenterReq from '../../../../../Requests/Customer/SearchLeadsForContactCenterReq'
// import MapIconApi from '../../../../../API/MapIconApi'
import { MapContext } from '../..'
import { CircularProgress } from '@mui/material'
import { FaRegCopy } from 'react-icons/fa'
import Swal from 'sweetalert2'
// import { TbFilePhone } from 'react-icons/tb'
// import UpdateLeadAppointmentReq from '../../../../../Requests/Customer/UpdateLeadAppointmentReq'
const MainLeadCard = ({ searchFiberHouses, selectedFiberHouse, user, setIndex }) => {
    let User = useContext(UserContext)
    const { selectedLead, setSelectedLead, setSelectedFiberHouse } = useContext(MapContext);
    const [loading, setLoading] = useState(true);
    const [leadNotes, setLeadNotes] = useState(selectedLead?.notes);
    const [edit, setEdit] = useState(false);
    const [fidiumCustomer, setFidiumCustomer] = useState(null);
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [mapIcon, setMapIcon] = useState(selectedFiberHouse?.mapIconId);
    // const [appointmentDate, setAppointmentDate] = useState(selectedLead?.appointmentDateTime)
    const [reload, setReload] = useState(false)
    const intialRender = useRef(true)
    const shouldSaveText = useRef(false)
    const navigate = useNavigate();
    const getLead = async () => {
        setFidiumCustomer(null);
        const req = new StringIntReq()
        req.int = selectedFiberHouse.id;
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 1;
        const res = await FiberHouseApi.GetLeadByFiberHouseId(req);
        if (res?.status?.success) {
            console.log("Lead", res.data)
            setSelectedLead(res.data);
            setLoading(false);
        }
    }



    useEffect(() => {
        if (selectedLead?.notes) {
            setLeadNotes(selectedLead?.notes);
        }
        else {
            setLeadNotes('');
        }
    }, [selectedLead])

    useEffect(
        () => {
            if (selectedFiberHouse != null) {
                setLoading(true);
                setMapIcon(selectedFiberHouse.mapIconId);
                getLead();
            }
        }, [selectedFiberHouse, reload]
    )

    // useEffect(
    //     () => {
    //         return () => {
    //             searchFiberHouses();
    //         }
    //     }, []
    // )

    // useEffect(() => {
    //     const getFidiumCustomer = async () => {
    //         const req = new StringIntReq();
    //         req.int = selectedLead.fidiumCustomerId;
    //         const res = await CustomerApi.GetFidiumCustomerById(req);
    //         if (res?.status?.success) {
    //             setFidiumCustomer(res.data);
    //             console.log("Fidium Customer", res.data);
    //         }
    //     }
    //     if (selectedLead?.fidiumCustomerId != null) {
    //         getFidiumCustomer();
    //     }
    // }, [selectedLead, loading])




    let contactButtons = new ContactButtons(User);

    const handleLightningMessage = async (lead) => {
        console.log("Lead", lead)
        const res = await contactButtons.lightningMessageLead({ ...lead, fidiumCustomer: fidiumCustomer });
        if (res != null) {
            console.log("Conversation", res)
            navigate('/chat', {
                state: { conversation: res }
            })
        }
    }


    const editNotes = useCallback(
        async () => {
            let req = new EditLeadReq();
            req.id = selectedLead.id;
            req.salesOrgId = User.salesOrgId;
            req.salesRepId = User.id;
            req.notes = leadNotes;
            await FiberHouseApi.EditLead(req);
            setRequestInProgress(false);
        }, [leadNotes]
    )

    useEffect(() => {

        if (intialRender.current) {
            intialRender.current = false;
            return;
        }
        else if (shouldSaveText.current) {
            const timer = setTimeout(
                async () => {
                    await editNotes();
                }, 1000);

            return () => clearTimeout(timer);
        }

    }, [leadNotes, editNotes]);

    if (loading) {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%"
            }}>
                <CircularProgress size={40} sx={{
                    color: "var(--primary-color)"
                }} />
            </div>
        )
    }

    const copyNotes = () => {
        navigator.clipboard.writeText(leadNotes);
        Swal.fire({
            icon: 'success',
            title: 'Copied',
            text: 'Notes Copied to Clipboard',
            showConfirmButton: false,
            toast: true,
            position: 'bottom-end',
            timer: 1500
        })
    }

    return (
        <div className="MainLeadCard">
            <div className="MainLeadCard_LeadData">
                {
                    edit ?
                        <LeadEdit lead={selectedLead} setEdit={setEdit} fiberHouse={selectedFiberHouse} searchFiberHouses={getLead} /> :
                        <LeadData mapIcon={mapIcon} setReload={setReload} fidiumCustomer={selectedLead?.fidiumCustomer} lead={selectedLead} setEdit={setEdit} fiberHouse={selectedFiberHouse} setFiberouse={setSelectedFiberHouse} searchFiberHouses={getLead} setIndex={setIndex} />
                }
            </div>

            {/* New  */}
            {
                // fidiumCustomer != null &&
                // <div className="viewCustomerCard">
                //     <div className='image'>
                //         {
                //             fidiumCustomer?.user?.profileImage != null ?
                //                 <img src={fidiumCustomer?.user?.profileImage?.url} alt="Profile" />
                //                 :
                //                 <img src="https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png" alt="Profile" />
                //         }
                //     </div>
                //     <div className='info'>
                //         <h2>{fidiumCustomer?.user?.firstName} {fidiumCustomer?.user?.lastName}</h2>
                //         <div className='address'>
                //             <MdLocationOn color='black' size={30} />
                //             <p>{
                //                 selectedLead?.fiberHouse?.mapString ? selectedLead?.fiberHouse?.mapString : (
                //                     selectedLead?.fiberHouse?.address + " " + selectedLead?.fiberHouse?.city + ", " + selectedLead?.fiberHouse?.state + " " + selectedLead?.fiberHouse?.zip
                //                     // + ", " + selectedLead?.fiberHouse?.country)
                //                 )
                //             }</p>

                //         </div>
                //         {/* <h4>View Customer Card</h4> */}
                //     </div>
                // </div>
            }
            {
                selectedLead.fidiumCustomer != null &&
                <div className="isCustomerMarked">
                    <span>
                        <span className="check-icon">✔</span>
                        Customer
                        {/* put a check icon */}
                    </span>    
                </div>
            }
              {
                selectedLead.isProspect === true &&
                <div className="isCustomerMarked">
                    <span>
                        <span className="check-icon">✔</span>
                        Offer Sent
                        {/* put a check icon */}
                    </span>    
                </div>
            }
            <div className="MainLeadCard_MapIcons">
                <MapIconDisplay setMapIcon={setMapIcon} selectedFiberHouse={selectedFiberHouse} />
            </div>

            <div className="MainLeadCard_Notes">
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1em"
                }}>
                    <h2 style={{
                        marginPTop: "0em"
                    }}>Notes</h2>
                    <FaRegCopy size={20} color='#84A1C8' onClick={() => {
                        copyNotes()
                    }} />
                </div>
                <div className='textArea'>
                    <textarea rows={4} value={leadNotes} onChange={(e) => { setLeadNotes(e.target.value); shouldSaveText.current = true }} />
                    {requestInProgress && <ImSpinner8 className='animate-spin' />}
                </div>
            </div>
            <div className="MainLeadCard_Buttons">
                <div className="MainLeadCard_Button" onClick={() => {
                    handleLightningMessage(selectedLead)
                }}>
                    <img src={LightningMessage} alt="Lightning Message" />
                    <span>Lightning Message</span>
                </div>
                <div className="MainLeadCard_Button" onClick={() => contactButtons.emailLead(selectedLead)}>
                    <img src={Email} alt="Email" />
                    <span>Email</span>
                </div>
                <div className="MainLeadCard_Button" onClick={() => contactButtons.smsLead(selectedLead)}>
                    <img src={Message} alt="Message" />
                    <span>SMS</span>
                </div>
                <div className="MainLeadCard_Button" onClick={() => contactButtons.whatsappLead(selectedLead)}>
                    <img src={wa} alt="Message" />
                    <span>WA</span>
                </div>
                <div className="MainLeadCard_Button" onClick={() => contactButtons.callLead(selectedLead)}>
                    <img src={Call} alt="Call" />
                    <span>Call</span>
                </div>
                <div className="MainLeadCard_Button" onClick={() => {
                    contactButtons.directions(selectedFiberHouse.coordinates)
                }}>
                    <img src={Directions} alt="Directions" />
                    <span>Directions</span>
                </div>
            </div>

   

        </div>
    )
}

export default MainLeadCard