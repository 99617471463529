import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import './index.css';


// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // apiKey: "AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0",
  apiKey: "AIzaSyAaKK8tx3031f-PpUCvNX-awAnRTVd04z0",
  authDomain: "lightningsalesengine.firebaseapp.com",
  projectId: "lightningsalesengine",
  storageBucket: "lightningsalesengine.appspot.com",
  messagingSenderId: "930309423427",
  appId: "1:930309423427:web:bf304fe5eb390ede61eff6",
  measurementId: "G-J1KD1FVGQK",
  phoneAuthOptions: {
    defaultCountry: 'US',
  },
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

