/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { Rating } from '@mui/material'
import './DesktopVersion.scss'
import Button from '../../../../components/Button';
import Swal from 'sweetalert2';
import QRCode from 'qrcode.react';
import ContactButtons from '../../../../Services/ContactButtons';
import { UserContext } from '../../../../App';

import { MdMailOutline, MdOutlineLocalPhone, MdOutlineMessage } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { GiWireframeGlobe } from 'react-icons/gi';
import { GrGroup } from 'react-icons/gr';
import SearchCustomerFeedbackReq from '../../../../Requests/CustomerFeedback/SearchCustomerFeedbackReq';
import CustomerFeedbackApi from '../../../../API/CustomerFeedbackApi';
import Modal from './../../../../components/Modal'
import ReviewContainer from './ReviewContainer';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import SearchFiberPlanReq from '../../../../Requests/FiberPlan/SearchFiberPlansReq';
import FiberPlanApi from '../../../../API/FiberPlanApi';
import LightCustomerModal from './LightCustomerModal';
import { SwiperSlide, Swiper } from 'swiper/react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { LuFileVideo } from 'react-icons/lu';
import { uploadFileToFirebase } from '../../../../Services/DisplayFile';
import { v4 as uuidv4 } from 'uuid';
import UsersApi from '../../../../API/UsersApi';
import StringIntReq from '../../../../Requests/StringIntReq';
import FiberCompanySelector from '../../../../components/FiberCompanySelector';
const DesktopVersionBusinessCard = ({ salesRep, salesRepIsUser, share, fiberCompany }) => {

    const currentUser = useContext(UserContext)
    const contactButtons = new ContactButtons(currentUser);
    const [avgRating, setAvgRating] = useState(5);
    const [openModal, setOpenModal] = useState(false);
    const [openLightModal, setOpenLightModal] = useState(false);
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [fiberCompanyId, setFiberCompanyId] = useState(fiberCompany ? fiberCompany : 2);
    const [bulletPoints, setBulletPoints] = useState(null)
    const [userVideos, setUserVideos] = useState([])
    
    const copyUrlToClipboard = () => {
        navigator.clipboard.writeText(window.location.origin + "/digital-business-center/" + salesRep.id)
        Swal.fire({
            title: "Link Copied",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            position: "bottom-right",
            timerProgressBar: true,
        })
    }

    const handleOpenLightModal = () => {
        if(fiberCompanyId === null){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a Fiber Company',
                showCloseButton: true,
            })
            return
        }
        setOpenLightModal(true)
    }

    const buttonClicked = async (text) => {
        console.log(currentUser)

        if (text === "email") {
            contactButtons.email(salesRep.email)
        }
        else if (text === "message") {
            contactButtons.sms(salesRep.phoneNumber)
        }
        else if (text === "Wa") {
            contactButtons.whatsapp(salesRep.phoneNumber)
        }
        else if (text === "Call") {
            contactButtons.call(salesRep.phoneNumber)
        }
    }

    const getAvgRating = async () => {
        const req = new SearchCustomerFeedbackReq();
        req.pagingParams.pageNumber = 1;
        req.pagingParams.pageSize = 1000;
        req.feedbackType = null;
        const res = await CustomerFeedbackApi.SearchCustomerFeedbacks(req);
        if (res?.status?.success) {
            let totalRating = 0;
            res.data.list.forEach(
                review => {
                    totalRating += review.rating;
                }
            )
            setAvgRating(totalRating / res.data.totalItems);
            console.log(totalRating / res.data.totalItems)
        }
    }

    var plansdata =
    {
    fiberPlans: [
        {
            id: 1,
            speed: 500,
            price: 100,
        },
        {
            id: 2,
            speed: 1,
            price: 200
        },
    ],
    bulletPoints: "Point1\nPoint2\nPoint3" 
    }   

    
    useEffect(() => {
        if (fiberCompany) {
            setFiberCompanyId(fiberCompany);
        } else
            setFiberCompanyId(2);

    }, [fiberCompany]);
    

    const getPlans = async () => {
        const req = new StringIntReq();
        req.int = fiberCompanyId;
        const res = await FiberPlanApi.GetFiberPlanOffers(req);
        
        if (res.status.success) {


            const fiberPlans = res.data.fiberPlans;
            const bulletPoints = res.data.bulletPoints;
    
   
            setPlans(fiberPlans);
            setSelectedPlan(fiberPlans[0]);
            setBulletPoints(bulletPoints);
        }

    };

    useEffect(() => {
        if(fiberCompanyId != null)
        {
            getPlans()
            console.log(fiberCompanyId)
        }
    }, [fiberCompanyId])

    useEffect(() => {
        getAvgRating();
    }, [])


    const getAddress = () => {
        const { city, state, country } = salesRep;
        let addressParts = [];

        // if (address) addressParts.push(address);
        if (city) addressParts.push(city);
        if (state) addressParts.push(state);
        if (country) addressParts.push(country);

        return addressParts.join(', ');
    };

    function getPlanSpeed(plan) {
        if (plan.speed < 1) {
            return plan.speed * 1000 + " Mbps"
        } else {
            return plan.speed + " Gig"
        }
    }

    const uploadFile = async (e) => {
        Swal.fire({
            title: "Uploading Video",
            icon: "info",
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        const uniqueId = uuidv4()
        const cloudFile = await uploadFileToFirebase(e.target.files[0], `profileVideos/${currentUser.id}/${uniqueId}`)
        cloudFile.videoUserId = currentUser.id
        const res = await UsersApi.AddUserVideoForBizCard(cloudFile)
        if (res?.status?.success) {
            Swal.fire({
                title: "Video Uploaded",
                icon: "success",
                showConfirmButton: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
            searchUserVideos();
        }
        else {
            Swal.fire({
                title: "Error Uploading Video",
                icon: "error",
                showConfirmButton: false,
                didOpen: () => {
                    Swal.hideLoading()
                }
            })
        }
    }

    const searchUserVideos = async () => {
        const req = new StringIntReq()
        req.int = salesRep.id
        const res = await UsersApi.SearchUserVideosForBizCard(req)
        if (res.status.success) {
            setUserVideos(res.data)
        }
        else {
            console.log(res.status.message)
        }
    }

    useEffect(() => {
        searchUserVideos()
    }, [])



    return (
        <>
            <LightCustomerModal salesOrgId={salesRep.salesOrgId} salesRepId={salesRep.id} lightOpenModal={openLightModal} setLightOpenModal={setOpenLightModal} fiberCompanyId={fiberCompanyId} fiberPLanId={selectedPlan?.id} />
            <Modal open={openModal} setOpen={setOpenModal}>
                <div className='review_modal'>
                    <ReviewContainer />
                </div>
            </Modal>
            <div style={{
            }} className='cardContainer'>

                <div className='businessCardContainer'>
                    <div className={`part1 both`}>
                        <h1 className='business-card-part1 '>{salesRep.firstName + " " + salesRep.lastName}</h1>
                        <div className='business-card-part2'>
                            <Rating name="read-only" size='large' precision={0.5} value={avgRating} readOnly />
                            <span className="avg-rating" style={{fontSize: "20px"}}>
                                {avgRating.toFixed(1)}
                            </span>
                            <p onClick={() => {
                                setOpenModal(true)
                            }}>See Reviews</p>
                        </div>
                        <h3 className='business-card-part3 '>
                            {
                                salesRep.userType === "SalesRep" ?
                                    "Fiber Specialist" :
                                    salesRep.userType === "SalesOrgAdmin" ?
                                        "Area Manager" :
                                        salesRep.userType === "SuperAdmin" ?
                                            "Super Admin" :
                                            ""
                            }
                        </h3>
                        
                        <div className='business-card-part4 '>
                            <GiWireframeGlobe size={40} color='black' />
                            <p className=''>{getAddress()}</p>
                        </div>
                        {
                            salesRep.profileImage ?
                                <div className="business-card-part5 ">
                                    <img src={salesRep.profileImage.url} alt="" />
                                </div>
                                :
                                <div style={{
                                    border: "1px solid black",
                                }} className="business-card-part5 ">
                                    <img src="/images/Avatar/profile.jpg" alt="" />
                                </div>
                        }
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            loop={true}
                            pagination={{ clickable: true }}
                            navigation={true}
                            modules={[EffectCoverflow, Pagination, Navigation]}
                            className='business-card-part6 '
                            style={{
                                width: "100%",
                            }}
                        >
                            {
                                salesRep.profileVideo &&
                                <SwiperSlide>
                                    <video src={salesRep.profileVideo.url + "#t=0.001"}
                                        controls></video>
                                </SwiperSlide>
                            }
                            {
                                userVideos.map((video, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <video src={video.url + "#t=0.001"}
                                                controls></video>
                                        </SwiperSlide>
                                    )
                                })
                            }
                            {
                                currentUser.id === salesRep.id &&
                                <SwiperSlide>
                                    <div className='add_video_bizcard'>
                                        <label htmlFor='biz_card_video'>UPLOAD <LuFileVideo size={30} color='#4FAFCB' /></label>
                                        <input onChange={(e) => { uploadFile(e) }} type="file" id="biz_card_video" accept="video/*" />
                                    </div>
                                </SwiperSlide>
                            }
                        </Swiper>
                        <div className='business-card-part7'>

                            {salesRep.salesOrg &&
                                <div style={{
                                    marginBottom: "1rem",
                                    display: "flex",
                                    gap: "1rem"
                                }} >
                                    <GrGroup size={40} color='black' />
                                    <p style={{
                                        fontSize: "1.5rem"
                                    }}>{salesRep.salesOrg?.name}</p>
                                </div>}
                            <div style={{
                                cursor: 'pointer',
                                marginBottom: "1rem",
                                display: "flex",
                                gap: "1rem"
                            }} onClick={() => { contactButtons.call(salesRep.phoneNumber) }} >
                                <MdOutlineLocalPhone size={40} color='black' />
                                <p style={{
                                    fontSize: "1.5rem"
                                }}>{salesRep.phoneNumber}</p>
                            </div>
                            <div style={{
                                display: "flex",
                                gap: "1rem",
                                cursor: 'pointer',
                                marginBottom: "1rem"
                            }} onClick={() => { contactButtons.email(salesRep.email) }} >
                                <MdMailOutline size={40} color='black' />
                                <p style={{
                                    fontSize: "1.5rem"
                                }}>{salesRep.email}</p>
                            </div>
                        </div>
                        <div className='contact_buttons'>

                            <div onClick={() => { buttonClicked('email') }} className="contact_button" >
                                <MdMailOutline size={40} />
                                <span>Email</span>
                            </div>
                            <div onClick={() => { buttonClicked('message') }} className="contact_button" >
                                <MdOutlineMessage size={40} />
                                <span>SMS</span>
                            </div>
                            <div onClick={() => { buttonClicked('Wa') }} className="contact_button" >
                                <FaWhatsapp size={40} />
                                <span>WA</span>
                            </div>
                            <div onClick={() => { buttonClicked('Call') }} className="contact_button" >
                                <MdOutlineLocalPhone size={40} />
                                <span>Call</span>
                            </div>
                            </div>
                    </div>
                    <div className='part2'>

                        <div className='connect-share'>
                        <div>
                            <img src="/images/nav/nav-logo.png" alt='navLogo' />
                        </div>
                            <p >Connect/Share</p>
                            <div className='qr' >
                                <QRCode value={window.location.origin + "/digital-business-center/" + salesRep.id} size={250} />
                            </div>
                            <Button onClick={copyUrlToClipboard}>Copy Link</Button>
                            {currentUser?.id !== salesRep.id &&
                            
                                <>
                                    {!fiberCompany &&

                                    <FiberCompanySelector className="fiber_company_selector" placeholder='Select Fiber Company' setUser={setFiberCompanyId} />
                                    }
                                    {plans.length > 0 ?
                                    <div className='banner_top'>
                                        {
                                            plans.map((plan, index) => {

                                                    return (
                                                        <div key={index} onClick={() => {
                                                            setSelectedPlan(plan)
                                                        }} className={`banner_card ${selectedPlan?.id === plan?.id && "active"}`}>
                                                            {selectedPlan?.id === plan?.id && <IoIosCheckmarkCircle style={{
                                                                position: 'absolute',
                                                                top: '-5%',
                                                                right: '-5%'
                                                            }} size={40} color='#2761D0' />}
                                                            <img style={{
                                                        height: "30px"
                                                    }} src={'data:image/png;base64,' + plan.fiberCompany?.logo} alt='fiber company logo' />
                                                            <h2 style={{
                                                                fontSize: "1.2rem",
                                                                marginBottom: "0.5rem"
                                                            }}>
                                                                {
                                                                    // plan.speed < 1 ?
                                                                    // plan.speed * 1000 + " Mbps"
                                                                    getPlanSpeed(plan)
                                                                    // :
                                                                    // plan.speed + " Gig"
                                                                }
                                                            </h2>

                                                            <h4>${plan.price}/mo*</h4>
                                                            {/* <p style={{
                                                                textAlign: "left",
                                                                fontSize: "0.8rem",
                                                            }}>No Data Caps</p>
                                                            <p style={{
                                                                textAlign: "left",
                                                                fontSize: "0.8rem",
                                                            }}>1st Month Free</p>
                                                            <p style={{
                                                                textAlign: "left",
                                                                fontSize: "0.8rem",
                                                            }}>No Contracts</p>
                                                            <p style={{
                                                                fontSize: "0.8rem",
                                                                textAlign: "left",
                                                            }}>Free Modem</p> */}
                                                            {/* <p>Lifetime Rate Lock</p> */}
                                                        </div>
                                                    )
                                                })
                                        }

                                    </div>
                                    : "No Plans Available"}
                                            

                                    <div style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "0.5rem",
                                        backgroundColor: "#fff",
                                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                                        padding: "1rem",
                                        borderRadius: "10px"
                                    }}>
                                        {bulletPoints?.split('\n').map((point, index) => {
                                            return (
                                                <div key={index} className='flex gap-4'>
                                                    <IoIosCheckmarkCircle color='#2761D0' size={20} />
                                                    <p>{point}</p>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <Button disabled={plans.length === 0}  onClick={() => { handleOpenLightModal() }} >Accept Offer</Button>

                                </>
                            }
                        </div>


                        {/* } */}
                    </div>
                </div>

            </div>
        </>
    )
}








export default DesktopVersionBusinessCard